import {
    FETCH_SAVED_SUMMARY_REQUEST,
    FETCH_SAVED_SUMMARY_SUCCESS,
    FETCH_SAVED_SUMMARY_FAILURE,
  } from "../actions/fetchSavedSummary";

  const initialState = {
    loading: false,
    summary: null,
    error: null,
    singleSummary: null,
  };

  const fetchSavedSummaryReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SAVED_SUMMARY_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_SAVED_SUMMARY_SUCCESS:
        return {
          ...state,
          loading: false,
          summary: action.payload,
          error: null,
        };
      case FETCH_SAVED_SUMMARY_FAILURE:
        return {
          ...state,
          loading: false,
          summary: null,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export default fetchSavedSummaryReducer;