import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Reporting.css";
import { AiFillInfoCircle } from "react-icons/ai";

const Reporting = ({ user, setShowConfirmation, setConfirmationMessage, setSeverity }) => {

  var isMobileScreen = 0;
  if (window.innerWidth < 450) {
    isMobileScreen = 1;
  }
  
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState(null);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // ***************************
  // *** Invite Employee ***
  // ***************************

  const inviteEmployee = async (e) => {
    e.preventDefault();
    if(!validateEmail(email)){
      setSeverity("error")
      setShowConfirmation(true);
      setConfirmationMessage("Please enter valid email address!");
      return
    }
    setLoading(true);
    const url = process.env.REACT_APP_URL + "/manager/sendInviteV2/";
    try {
      //("Sending invite to employee:", email);
      const res = await axios.post(
        url,
        { email, role: 2 },
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );
      //("Invite employee response:", res.data);
      if (res.data.success === true) {
        setShowConfirmation(true);
        setConfirmationMessage("Invitation sent successfully");
      }
    } catch (error) {
      console.error("Error sending invite to employee:", error.message);
      console.error("Response data:", error.response.data);
    } finally {
      setEmail("");
      setLoading(false);
      //("Fetching employees after sending invite to employee...");
      getEmployees();
    }
  };

  const inviteManager = async (e) => {
    e.preventDefault();
    if(!validateEmail(email)){
      setSeverity("error");
      setShowConfirmation(true);
      setConfirmationMessage("Please enter valid email address!");
      return
    }
    setLoading(true);
    const url = process.env.REACT_APP_URL + "/manager/sendInviteV2/";
    try {
      //("Sending invite to manager:", email);
      const res = await axios.post(
        url,
        { email, role: 1 },
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );
      //("Invite manager response:", res.data);
      if (res?.data?.success === true) {
        setShowConfirmation(true);
        setConfirmationMessage("Invitation sent successfully");
      }
    } catch (error) {
      console.error("Error sending invite to manager:", error.message);
    } finally {
      setEmail("");
      setLoading(false);
      //("Fetching employees after sending invite to manager...");
      getEmployees();
    }
  };

  // ***************************
  // *** Get Employees ***
  // ***************************
  const getEmployees = async () => {
    const url = process.env.REACT_APP_URL + "/manager/getTeams";
    try {
      //("Fetching employees...");
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${user["connect.sid"]}`,
        },
      });
      //("Get employees response:", res.data);
      setEmployees(res.data.data);
    } catch (error) {
      console.error("Error fetching employees:", error.message);
    }
  };

  useEffect(() => {
    getEmployees();
  }, [user]);
  return (
    <div className="flex flex-col justify-center my-5 ">
      {loading ? (
        <div className="loader-outer">
          <div className="loader z-20"></div>
        </div>
      ) : (
        <>
          <div className="border-l-8 flex flex-col gap-0 border-yellow-500 pl-5 -mt-10 h-10 justify-center ">
            <span className="font-medium  md:text-lg  ">
              Setup your reporting manager / team members
            </span>
          </div>
          <div className="flex mt-5 md:flex-row flex-col justify-between gap-10 relative">
            <div className="h-1 absolute w-full bg-[#3D66FB] top-[50%] -z-10"></div>

            {/* Left Section  */}
            <div className={`flex flex-col ${isMobileScreen ? '' : 'w-[40%]'} z-10`}>
              <div className="bg-white w-full h-[50vh] rounded-b-xl flex flex-col justify-between overflow-y-auto [&::-webkit-scrollbar]:hidden">
                <table className=" rounded-xl p-2 w-full team">
                  <thead className="bg-yellow-500 top-0 ">
                    <tr className="">
                      <th className="rounded-tl-xl p-2">Team Members</th>
                      <th className="rounded-tr-xl p-2">Status</th>
                    </tr>
                  </thead>

                  <tbody className="overflow-y-scroll">
                    {employees?.flatMap((emp) => (                      
                        emp?.inviteTeam.map((employeeInvite) => (
                          <tr key={employeeInvite?.user?._id} className="font-medium">
                            <th className="p-2">
                              {employeeInvite?.user?.email}
                            </th>
                            {employeeInvite?.isAccept ? (
                              <th className="p-2">Joined</th>
                            ) : (
                              <th className="p-2">Invited</th>
                            )}
                          </tr>
                        ))                      
                    ))}
                  </tbody>
                </table>
                <form
                  className="bg-white bottom-0 rounded-sm flex p-2 gap-2 justify-center items-center mx-auto w-[100%] mb-2"
                  onSubmit={inviteEmployee}
                >
                  <input
                    type="email"
                    className="outline-none border-b-4 border-[#3D66FB] w-9/12 "
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="xyz@yahoo.com"
                    key={loading}
                  />
                  <button type="submit" className="text-[#3D66FB] font-medium">
                    Invite
                  </button>
                </form>
              </div>
              <div className="text-md flex gap-2 items-center mt-1">
                <AiFillInfoCircle className="text-sm" />
                <span>
                  Your Work summaries are{" "}
                  <span className="font-semibold">
                    not shared with your team
                  </span>{" "}
                  members.
                </span>
              </div>
            </div>

            {/* user name display  */}
            <div className="flex justify-center gap-2 items-center  z-10 ">
              <div className="relative h-36 w-36 flex">
                <div className="h-full w-full rounded-full bg-white shadow-md flex items-center justify-center">
                  <div className="font-semibold text-gray-800 text-center ">
                    {user?.displayName}
                  </div>
                </div>
              </div>
            </div>

            {/* Right Section  */}
            <div className={`flex flex-col ${isMobileScreen ? '' : 'w-[40%]'} z-10`}>
              <div className="bg-white w-full h-[50vh] rounded-b-xl flex flex-col overflow-y-auto [&::-webkit-scrollbar]:hidden justify-between">
                <table className="bg-white rounded-xl p-2 w-full mx-auto  ">
                  <thead className="bg-yellow-500 top-0 ">
                    <tr className="">
                      <th className="rounded-tl-xl p-2">Reporting Manager</th>
                      <th className="rounded-tr-xl p-2">Status</th>
                    </tr>
                  </thead>

                  <tbody className="overflow-y-scroll">
                    {employees?.map((manager) => {
                      //("Rendering manager:", manager);
                      return manager?.inviteManger.map((managerInvite) => [
                        <tr key={managerInvite._id} className="font-bold">
                          <td className="p-2">{managerInvite?.user?.email}</td>
                          {managerInvite?.isAccept ? (
                            <td className="p-2">Joined</td>
                          ) : (
                            <td className="p-2">Invited</td>
                          )}
                        </tr>,
                      ]);
                    })}
                  </tbody>
                </table>
                <form
                  className="bg-white bottom-0  rounded-sm flex p-2 gap-2 justify-center items-center mx-auto w-[100%] mb-2"
                  onSubmit={inviteManager}
                >
                  <input
                    type="email"
                    className="outline-none text-blue-[#3D66FB]  border-b-4 border-[#3D66FB] border-blue-[#3D66FB] w-9/12  font-medium"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="xyz@yahoo.com"
                    key={loading}
                  />
                  <button type="submit" className="text-[#3D66FB] font-medium">
                    Invite
                  </button>
                </form>
              </div>
              <div className="text-md flex gap-2 items-center mt-1">
                <AiFillInfoCircle className="text-sm" />
                <span>
                  Your manager can only see your{" "}
                  <span className="font-semibold">saved summaries.</span>{" "}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Reporting;
