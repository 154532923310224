import React from "react";
import Calendar from "../assets/Calendar.png";

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <button
      className="ml-3 outline-none bg-transparent text-2xl d-flex items-center justify-content-center
  "
      onClick={props.onClick}
      ref={ref}
    >
      <img src={Calendar} alt="" />
    </button>
  );
});

export default CustomInput;
