import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import Home from "./pages/Home/Home";
import Login from "./pages/Auth/Login";
import PromoCodeLogin from "./pages/Auth/PromoCodeLogin";
import "./App.css";
import PricingPage from "./pages/Pricing/PricingPage";
import AdminPage from "./pages/Admin/AdminPage";
import AdminProtect from "./ProtectedRoutes/AdminProtect";
import LoggedInProtect from "./ProtectedRoutes/NotLoggedProtect";
import TermsAndCondition from "./pages/TermsAndCondition/TermsAndCondition";
import Privacy from "./pages/privacy/privacy";
import PaymentSuccess from "./pages/PaymentSuccess";
import { useSelector, useDispatch } from "react-redux";
import { useMount } from "react-use";
import { fetchUser } from "./Store/actions/fetchUser";
import React from "react";
import Callback from "./pages/Home/Github/Callback";
import { login } from "./Store/reducers/authLoginReducer";
import ManagerView from "./pages/Manager/ManagerView"
// import TagManager from 'react-gtm-module'
import PromoPricingPage from "./pages/Pricing/PromoPricingPage";
import AcceptInvitation from "./pages/Auth/AcceptInvitation";

const App = React.memo(({ }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);

  // Fetch user and isAuthenticated from the store
  const { user, isLoggedIn } = useSelector((state) => state.authLogin);
  // const tagManagerArgs = {
  //   gtmId: 'GTM-MHXS7R8H',
  // };

  // useEffect(() => {
  //   TagManager.initialize(tagManagerArgs);
  // }, []);

  // useMount(async () => {
  //   // Retrieve user and isLoggedIn values from localStorage
  //   const storedUser = localStorage.getItem("user");
  //   const storedIsLoggedIn = localStorage.getItem("isLoggedIn");

  //   // Check if values exist in localStorage
  //   if (storedUser && storedIsLoggedIn) {
  //     // Parse the storedUser value from string to object
  //     await dispatch(login(JSON.parse(storedUser)));
  //     // await dispatch(fetchUser());
  //   } else {
  //     await dispatch(fetchUser());
  //   }
  //   setLoading(false);

  // });


  useEffect(() => {
    const initializeAuthFromLocal = async () => {
      try {
        // Retrieve user and isLoggedIn values from localStorage
        const storedUser = localStorage.getItem("user");
        const storedIsLoggedIn = localStorage.getItem("isLoggedIn");

        // Check if values exist in localStorage
        if (storedUser && storedIsLoggedIn) {
          await dispatch(login(JSON.parse(storedUser)));
        }
      } catch (error) {
        console.error('Authentication failed:', error);
      } finally {
        setLoading(false);
      }
    };

    initializeAuthFromLocal();
  }, [dispatch]);

  useEffect(() => {
    const initializeAuth = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get('token');

      if (token === "google_login_success") {
        setLoading(true);
        await dispatch(fetchUser());
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'authenticationSuccessEvent',
          'authenticationMethod': 'Google',
          'status': 'Success'
        });
        navigate('/');
        setLoading(false);
      }
    }
    initializeAuth();
  }, []);

  useEffect(() => {
    if (user?.data[0].role === "admin") {
      navigate("/admin")
    }
  }, [user, navigate]);

  return (
    <div className="container">
      {loading && !user ? (
        <div className="loader-outer">
          <div className="loader z-20"></div>
        </div>
      ) : (
        <Routes>
          {/* User route  */}
          <Route path="/manager" element={<ManagerView />} />
          <Route
            path="/promo"
            element={
              !user ? (
                <PromoCodeLogin />

              ) : user?.data[0].role === "admin" ? (
                <Navigate to="/admin" />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route path="/terms-condition" element={<TermsAndCondition />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          {/* not login user  */}
          <Route element={<LoggedInProtect isLoggedIn={isLoggedIn} />}>
            <Route key={user} path="/" element={<Home user={user} />} />
          </Route>
          <Route path="/pricing" element={<PricingPage user={user} />} />

          {/* Only Admin access  */}
          <Route element={<AdminProtect user={user} />}>
            <Route path="/admin" element={<AdminPage user={user} />} />
          </Route>
          <Route
            path="/login"
            element={
              !user ? (
                <Login />
              ) : user?.data[0].role === "admin" ? (
                <Navigate to="/admin" />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route path="/callback" element={<Callback />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />

          {/* not login user  */}
          <Route element={<LoggedInProtect isLoggedIn={isLoggedIn} />}>
            <Route key={user} path="/" element={<Home user={user} />} />
          </Route>
          <Route path="/pricing" element={<PricingPage user={user} />} />
          <Route path="/PromoPricing" element={<PromoPricingPage user={user} />} />
          {/* Only Admin access  */}

          <Route element={<AdminProtect user={user} />}>
            <Route path="/admin" element={<AdminPage user={user} />} />
          </Route>
          <Route
            path="/accept-invitation/:token"
            element={<AcceptInvitation />}
          />
        </Routes>
      )}
    </div>
  );
});

export default App;
