import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const AdminProtect = ({ user }) => {
  if (user?.data[0].role !== "admin") {
    return <Navigate to="/" />;
  }
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default AdminProtect;
