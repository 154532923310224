import React, { useState } from "react";
import axios from "axios";

const EditManagerForm = ({
  editManagerFormView,
  user,
  editManager,
  setShowConfirmation,
  setConfirmationMessage,
  fetchManagers,
}) => {
  const [managerData, setManagerData] = useState(editManager);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      const file = files[0];
      const formData = new FormData();
      formData.append(name, file);
      setManagerData((prevData) => ({
        ...prevData,
        [name]: formData.get(name),
      }));
    } else {
      setManagerData({ ...managerData, [name]: value });
    }
  };

  const editManagerData = async (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    Object.entries(managerData).forEach(([key, value]) => {
      if (key !== "createdAt" && key !== "_v" && key !== "plan" && !!key) {
        if (key === "logo" && !(value instanceof File)) {
          return; // Skip appending the logo field if its value is not binary
        }
        formData.append(key, value);
      }
    });
    const url = process.env.REACT_APP_URL + "/admin/editManager";
    try {
      await axios.put(url, formData, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${user["connect.sid"]}`,
        },
      });
      fetchManagers();
      setConfirmationMessage("Manager edited successfully");
      setShowConfirmation(true);
      editManagerFormView();
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  };
  return (
    <div
      className="min-h-full bg-[#00000030] p-2 md:p-8  fixed min-w-full flex items-center justify-center left-0 top-0"
      onClick={() => editManagerFormView()}
    >
      {loading && (
        <div className="loader-outer">
          <div className="loader z-20"></div>
        </div>
      )}
      <div
        className="flex flex-col justify-around bg-white p-3 items-center rounded-xl shadow-xl gap-5"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="border-l-4 border-yellow-500 pl-4 h-8 flex items-center  w-[90vw] md:w-[70vw]  ">
          <span className="font-medium">Edit Team</span>
        </div>
        <form className="px-2 flex flex-col gap-6" onSubmit={editManagerData}>
          <div className="flex gap-2 justify-end ">
            <label className="font-semibold text-2xl">Customer Name</label>
            <input
              type="text"
              className="border-b-4 border-[#3D66FB] w-50"
              onChange={handleChange}
              value={managerData.name}
              name="name"
            />
          </div>
          <div className="flex gap-2 justify-end ">
            <label className="font-semibold text-2xl">Email</label>
            <input
              type="email"
              className="border-b-4 border-[#3D66FB] w-50"
              onChange={handleChange}
              name="email"
              value={managerData.email}
            />
          </div>
          <div className="flex gap-2 justify-end ">
            <label className="font-semibold text-2xl">Logo</label>
            <input
              type="file"
              className="w-50"
              onChange={handleChange}
              name="logo"
              // value={managerData?.logo}
            />
          </div>
          <div className="flex gap-2 justify-end ">
            <label className="font-semibold text-2xl">Domain</label>
            <input
              type="url"
              className="border-b-4 border-[#3D66FB] w-50"
              onChange={handleChange}
              name="domain"
              value={managerData.domain}
            />
          </div>

          <div className="flex gap-10 items-center justify-end mt-10 ">
            <button
              className=" border-3 border-[#3D66FB] font-medium px-4 py-1 text-black rounded-full "
              onClick={() => editManagerFormView()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#3D66FB] text-white font-medium px-4 py-1.5 rounded-full"
              onClick={editManagerData}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditManagerForm;
