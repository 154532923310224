// JiraAuth.js

import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUserInfo, fetchUserProfile } from "../../../Store/actions/fetchUserProfile";
import FormControlLabel from '@mui/material/FormControlLabel';
import SwitchBtn from "../../../components/SwitchBtn";

const JiraAuth = ({
  user,
  isConnected,
  setIsConnected,
  setSnackbarOpen,
  setJiraEvents,
}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthorizationCallback = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const authorizationCode = urlParams.get("code");
        const JiraState = urlParams.get("state");


        // console.log("Authorization Code:", authorizationCode,urlParams);

        if (authorizationCode && JiraState === "JiraAuthToken") {
          await handleTokenExchange(authorizationCode);
        }
      } catch (error) {
        console.error("Error in handleAuthorizationCallback:", error);
      }
    };

    handleAuthorizationCallback();
  }, []);

  const saveJiraCredentials = async (access_token, refresh_token, expires_in) => {
    try {
      const saveCredentialsEndpoint =
        process.env.REACT_APP_URL + "/jira/save_jira_credentials";

      // Send a POST request to save Jira credentials
      const response = await axios.post(
        saveCredentialsEndpoint,
        { access_token, refresh_token, expires_in },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );
      if (response) {
        connect(true);
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      console.error("Error in saveJiraCredentials:", error);
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'toolConnectionFailedEvent',
        'feature': 'Jira',
        'status': 'Failed'
      });
    }
  };

  const handleTokenExchange = async (authorizationCode) => {
    try {
      const clientId = process.env.REACT_APP_URL == "https://secure.wereport.work" ?
        "EHwZTK2zDcURfEq6cYvVxakDn62OwvEH" :
        "hYUfrEPcoG1LcGnfgpCEmSA3KObd2TuX";
      const clientSecret = process.env.REACT_APP_URL == "https://secure.wereport.work" ?
        "ATOA5b61oxCl4evCi9mHaryJBAUsv2Z5zidHTsy4sku3rQQPCSya73NsBzhGwrWgBlos41369E12" :
        "ATOAzxbAxT8Eo0NhfabJfMVBwZim5hSL_0hMxtnUXP5pexEq01UUwoDU5WM6eD2aflrX77EF7079";
      const redirectUri = process.env.REACT_APP_URL == "https://secure.wereport.work" ?
        "https://app.wereport.work/" :
        "https://dev-app.wereport.work/";
      const tokenEndpoint = "https://auth.atlassian.com/oauth/token";

      const tokenPayload = {
        grant_type: "authorization_code",
        client_id: clientId,
        client_secret: clientSecret,
        code: authorizationCode,
        redirect_uri: redirectUri,
      };

      const tokenResponse = await axios.post(tokenEndpoint, null, {
        params: tokenPayload,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const { access_token, refresh_token, expires_in } = tokenResponse.data;
      // Save Jira token using API
      await saveJiraCredentials(access_token, refresh_token, expires_in);
      navigate("/");
    } catch (error) {
      console.error("Error in handleTokenExchange:", error);
    }
  };

  const connect = async (isConnected) => {
    try {
      const url = process.env.REACT_APP_URL + "/jira/jiraconnect";
      const response = await axios.put(
        url,
        { isConnect: isConnected },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );
      if (response.status == 200 & isConnected) {
        setIsConnected(true);
        setSnackbarOpen(true);
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionSuccessEvent',
          'feature': 'Jira',
          'status': 'Connected',
        });
      } else if (response.status === 200 && !isConnected) {
        setIsConnected(false);
        setSnackbarOpen(true);
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionRemoveEvent',
          'feature': 'Jira',
          'status': 'Removed',
        });
      } else {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionFailedEvent',
          'feature': 'Jira',
          'status': 'Failed'
        });
      }
    } catch (error) {
      // console.error("Fetch error:", error);
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'toolConnectionFailedEvent',
        'feature': 'Jira',
        'status': 'Failed'
      });
    }
  };

  const handleConnect = () => {
    if (isConnected) {
      setJiraEvents([]);
      connect(false);
    } else {
      setJiraEvents([]);
      handleJiraConnect();
    }
  };


  const handleJiraConnect = () => {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'toolConnectionRequestedEvent',
      'feature': 'Jira',
      'status': 'Requested-Connect'
    });

    const clientId = process.env.REACT_APP_URL == "https://secure.wereport.work" ?
      "EHwZTK2zDcURfEq6cYvVxakDn62OwvEH" :
      "hYUfrEPcoG1LcGnfgpCEmSA3KObd2TuX";
    const redirectUri = process.env.REACT_APP_URL == "https://secure.wereport.work" ?
      "https://app.wereport.work/" :
      "https://dev-app.wereport.work/";
    const stateValue = "JiraAuthToken";

    const authorizationUrl = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${clientId}&scope=read%3Ajira-user%20read%3Ajira-work%20read%3Ajira-project%20read%3Ame%20offline_access&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&state=${stateValue}&response_type=code&prompt=consent`;

    window.location.href = authorizationUrl;
  }

  return (
    <div>
      <FormControlLabel
        sx={{
          display: 'block',
        }}
        control={
          <SwitchBtn
            checked={isConnected}
            onChange={handleConnect}
            name="connect"
            sx={{ m: 1 }}
          />
        }
      />
    </div>
  );
};

export default JiraAuth;
