import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VscExclude } from "react-icons/vsc";
import { RiArrowUpDownFill } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import driveImage from "../../../assets/google/drive.png";
import { useSummary } from "../../../context/SummaryDataContext";
import axios from "axios";
import { useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { updateUserInfo, fetchUserProfile } from "../../../Store/actions/fetchUserProfile";
import { useDispatch } from "react-redux";
import FormControlLabel from '@mui/material/FormControlLabel';
import SwitchBtn from "../../../components/SwitchBtn";

const DriveList = ({ selectedDate, user, onSummary, summaryData, updateStatus }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [driveLoading, setDriveLoading] = useState(false);
  const { userProfile } = useSelector((state) => state.userProfile);
  const [driveExclude, setDriveExclude] = useState(userProfile?.data[0].drive);
  const { driveList, setDriveList } = useSummary([]);
  const [DriveActiveTab, setDriveActiveTab] = useState("input");
  const [summary, setSummary] = useState([]);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(
    userProfile?.data[0]?.drive?.isConnect
  ); // Default connected state


  useEffect(() => {
    if (userProfile) {
      setDriveExclude(userProfile?.data[0].drive);
    }
    if (userProfile?.data[0]?.drive?.isConnect !== undefined) {
      setIsConnected(userProfile.data[0].drive.isConnect);
    }
  }, [userProfile]);

  const fetchDriveList = async () => {
    setDriveLoading(true);
    let api_call_completed = false;
    try {
      const response = await fetch(
        process.env.REACT_APP_URL +
        "/gdrive/files?date=" +
        selectedDate +
        "&timezoneOffset=" +
        new Date().getTimezoneOffset(),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setDriveList(data.files);
      } else {
        // console.log("Failed to fetch drive activities:", response.statusText);
      }
    } catch (error) {
      // console.log("Error fetching drive activities:", error);
    } finally {
      setDriveLoading(false);
      api_call_completed = true;
      updateStatus({ isConnected, api_call_completed });
    }

  };

  const connect = async (isConnected) => {
    try {
      const url = process.env.REACT_APP_URL + "/gdrive/connect";
      const response = await axios.put(
        url,
        { isConnect: isConnected },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );

      if (response.status == 200 && isConnected) {
        setIsConnected(true);
        setSnackbarOpen(true);        
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionSuccessEvent',
          'feature': 'drive',
          'status': 'Connected',
        });
      } else if (response.status === 200 && !isConnected) {
        setIsConnected(false);
        setSnackbarOpen(true);
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionRemoveEvent',
          'feature': 'drive',
          'status': 'Removed',
        });
      } else {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionFailedEvent',
          'feature': 'drive',
          'status': 'Failed'
        });
      }
    } catch (error) {
      // console.error("Fetch error:", error);
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'toolConnectionFailedEvent',
        'feature': 'drive',
        'status': 'Failed'
      });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDeleteDrive = (index) => {
    const updatedDriveActivities = [...driveList];
    updatedDriveActivities.splice(index, 1);
    setDriveList(updatedDriveActivities);
  };

  const convertToIST = (backendDate) => {
    const originalDate = new Date(backendDate);
    return originalDate.toLocaleTimeString('en-IN', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  // const updateCheckPoint = async (e) => {
  //   if (!driveExclude) {
  //     return;
  //   }
  //   const name = e.target.name;
  //   const currentValue = driveExclude[name];
  //   const updatedValue = !currentValue;
  //   const updatedEmailExclude = { ...driveExclude, [name]: updatedValue };
  //   setDriveExclude(updatedEmailExclude);

  //   try {
  //     const url = process.env.REACT_APP_URL + "/auth/updDrive";
  //     const response = await axios.post(
  //       url,
  //       { ...updatedEmailExclude },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${user["connect.sid"]}`,
  //         },
  //       }
  //     );
  //   } catch (error) {
  //     console.error("Fetch error:", error);
  //   }
  // };

  useEffect(() => {
    if (isConnected == true) {
      updateStatus({ isConnected, "api_call_completed": false });
      fetchDriveList();
    }

    setSummary();
  }, [selectedDate, isConnected]);

  const handleDriveConnect = async () => {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'toolConnectionRequestedEvent',
      'feature': 'drive',
      'status': 'Requested-Connect'
    });

    const googleOAuthURL = `${process.env.REACT_APP_URL}/auth/google/drive?state=${userProfile?.data[0]?._id}`;
    const newWindow = window.open(googleOAuthURL, "_self", 'noopener,noreferrer,resizable');

    if (newWindow) {
      newWindow.focus();
    } else {
      console.error("Popup blocked or couldn't be opened");
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const driveSuccess = params.get('redi');
    if (driveSuccess === "drive_success") {
      navigate("/")
      connect(true);
    }
  }, [])

  const handleConnectDisconnect = async () => {
    if (isConnected) {
      connect(false);      
    } else {
      setDriveList([]);
      handleDriveConnect();
    }
  };


  return (
    <>
      <div className="rounded-xl mt-10 min-h-300 shadow pb-20 border-zinc-300  min-h-500 bg-lotion max-md:overflow-x-auto md:overflow-hidden scrollbar-width scrollable-container">
        <div className="mb-2 flex gap-2 justify-between items-center text-center">
          <div></div>
          <div className="flex items-center gap-x-2 ml-16 text-black text-opacity-80 text-xl font-bold font-['DM Sans']">
            <img src={driveImage} alt="driveImage" className="h-8 w-8" />
            Google Drive
          </div>
          <FormControlLabel
            sx={{
              display: 'block',
            }}
            control={
              <SwitchBtn
                checked={isConnected}
                onChange={handleConnectDisconnect}
                name="connect"
                sx={{ m: 1 }}
              />
            }
          />
        </div>

        <div
          className={`flex border h-12 buttons shadow-md border-zinc-300 text-black text-opacity-80 text-xl font-['DM Sans'] bg-lotion rounded-full items-center justify-between mx-auto font-semibold mt-2`}
        >
          <button
            className={`${DriveActiveTab === "input" ? "border rounded-full shadow-xl" : ""
              } w-80 h-full`}
            onClick={() => setDriveActiveTab("input")}
          >
            {" "}
            <span className="flex items-center justify-center gap-x-6">
              {" "}
              <span className="text-2xl font-extralight">
                <RiArrowUpDownFill />
              </span>
              Input{" "}
            </span>{" "}
          </button>
          <button
            className={`${DriveActiveTab === "summary"
              ? "border rounded-full shadow-xl"
              : ""
              } w-80 h-full`}
            onClick={() => setDriveActiveTab("summary")}
          >
            {" "}
            <span className="flex items-center justify-center  gap-x-6">
              {" "}
              <span className="text-2xl font-extralight">
                <ImProfile />
              </span>
              Summary{" "}
            </span>
          </button>
          <button
            className={`${DriveActiveTab === "exclusions"
              ? "border rounded-full shadow-xl"
              : ""
              } w-80 h-full`}
            onClick={() => setDriveActiveTab("exclusions")}
          >
            {" "}
            <span className="flex items-center justify-center gap-x-6">
              {" "}
              <VscExclude className="text-2xl" /> Exclusions{" "}
            </span>
          </button>
        </div>

        {DriveActiveTab === "exclusions" && (
          <div className="flex flex-col p-4 gap-2">
          </div>
        )}

        {isConnected && DriveActiveTab === "input" && driveList?.length >= 1 ? (
          driveLoading ? (
            <div className="flex justify-center items-center min-h-300 gap-4">
              <img src={driveImage} alt="driveImage" className="h-8" />
              <p className="mt-3 font-medium">Loading drive activities...</p>
            </div>
          ) : (
            <div className="table-container">
              <table className="min-w-full divide-y-2 rounded-xl buttons  overflow-auto h-20 divide-gray-200 bg-lotion text-md mt-2">
                <thead className="ltr:text-left rtl:text-right">
                  <tr>
                    <th className="px-4 py-2 font-semibold text-blue-700">
                      Event
                    </th>
                    <th className="px-4 py-2 font-semibold text-blue-700">
                      Type
                    </th>
                    <th className="px-4 py-2 font-semibold text-blue-700">
                      File Name
                    </th>
                    <th className="px-4 py-2 font-semibold text-blue-700">
                      Folder Name
                    </th>
                    <th className="px-4 py-2 font-semibold text-blue-700">
                      Last Modified
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900"></th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200">
                  {driveList?.map((file, index) => (
                    <tr key={index}>
                      <td className="pl-6 py-2 text-gray-700 flex-wrap" style={{ textTransform: "capitalize" }}>
                        {Object.keys(file.event)[0]}
                      </td>
                      <td
                        className="flex-wrap pl-6 py-2 font-medium text-gray-900"
                        style={{ textTransform: "capitalize" }}
                      >
                        <a href={file.path} target="_blank" rel="noopener noreferrer">
                          {
                            file?.mimeType.split(".")[
                            file.mimeType.split(".").length - 1
                            ]
                          }
                        </a>
                      </td>
                      <td className="pl-6 py-2 text-gray-700 flex-wrap">
                        {file?.file_name}
                      </td>
                      <td className="pl-6 py-2 text-gray-700 flex-wrap">
                        {file?.folder_name}
                      </td>
                      <td className="pl-6 py-2 text-gray-700 flex-wrap">
                        {convertToIST(file?.eventTime)}
                      </td>
                      <td className="">
                        <i
                          className="fa fa-trash float-right pr-10"
                          onClick={() => handleDeleteDrive(index)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        ) : DriveActiveTab === "input" ? (
          <div className="flex justify-center items-center min-h-300 gap-4">
            <img src={driveImage} alt="driveImage" className="h-8" />
            {driveLoading ? (
              <p className="mt-3 font-medium">Loading drive activities...</p>
            ) : (
              <p className="mt-3 font-medium">Drive activities not found.</p>
            )}
          </div>
        ) : (
          ""
        )}
        {isConnected && DriveActiveTab === "summary" && (
          <p className="w-[70%] m-auto p-3 whitespace-pre-wrap overflow-auto h-[24rem]">
            {driveList?.length > 0 ? <span>{summaryData}</span> : <span></span>}
          </p>
        )}
        {loadingSummary ? (
          <div className=" loader-outer z-99999">
            <div className=" loader z-999999"></div>
          </div>
        ) : (
          ""
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={isConnected ? "success" : "error"}>
          {isConnected
            ? "Hurray! You are connected."
            : "Connect to summarize Drive Events!"}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DriveList;
