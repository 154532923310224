import React from "react";
import { useDispatch } from "react-redux";
import { fetchUserProfile } from "../../../Store/actions/fetchUserProfile";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import axios from "axios";
import FormControlLabel from '@mui/material/FormControlLabel';
import SwitchBtn from "../../../components/SwitchBtn";

export const SignInButton = ({
  user,
  isConnected,
  setIsConnected,
  setSnackbarOpen,
  setoutlookEvents,
}) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();

  const handleLogin = async () => {
    if (isConnected) {
      setoutlookEvents([]);
      connectOutlook(false);
    } else {
      setoutlookEvents([]);
      handleOutlookLogin();
    }
  };

  const handleOutlookLogin = async () => {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'toolConnectionRequestedEvent',
      'feature': 'Outlook',
      'status': 'Requested-Connect'
    });

    try {
      const loginResponse = await instance.loginPopup(loginRequest);
      if (loginResponse && loginResponse.account) {
        handleAccessToken(loginResponse?.accessToken);
      } else {
        console.log('No account found after login.');
      }
    } catch (error) {
      console.log('Login failed:', error);
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'toolConnectionFailedEvent',
        'feature': 'Outlook',
        'status': 'Failed'
      });
    }
  };

  const handleAccessToken = async (response) => {
    const accessToken = response;
    try {
      if (accessToken) {
        const url =
          process.env.REACT_APP_URL + "/outlook/save_outlook_credentials";
        const response = await axios.post(
          url,
          {
            accessToken: accessToken,
          },
          {
            headers: {
              Authorization: `Bearer ${user["connect.sid"]}`,
            },
          }
        );
        if (response.status == 200) {
          connectOutlook(true);
        } else {
          console.error("API call failed");
        }
      }
    } catch (error) {
      console.error("Fetch error:", error);
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'toolConnectionFailedEvent',
        'feature': 'Outlook',
        'status': 'Failed'
      });
    }
  };


  const connectOutlook = async (isConnected) => {
    try {
      const url = process.env.REACT_APP_URL + "/outlook/outlookEmailsconnect";
      const response = await axios.put(
        url,
        { isConnect: isConnected },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );
      if (response.status == 200 && isConnected) {
        setIsConnected(true);
        setSnackbarOpen(true);
        dispatch(fetchUserProfile());
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionSuccessEvent',
          'feature': 'Outlook',
          'status': 'Connected',
        });
      } else if (response.status == 200 && !isConnected) {
        setIsConnected(false);
        setSnackbarOpen(true);
        dispatch(fetchUserProfile());
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionRemoveEvent',
          'feature': 'Outlook',
          'status': 'Removed',
        });
      } else {
        // console.error("API call failed");
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionFailedEvent',
          'feature': 'Outlook',
          'status': 'Failed'
        });
      }
    } catch (error) {
      console.error("Fetch error:", error);
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'toolConnectionFailedEvent',
        'feature': 'Outlook',
        'status': 'Failed'
      });
    }
  };

  return (
    <>
      <FormControlLabel
        sx={{
          display: 'block',
        }}
        control={
          <SwitchBtn
            checked={isConnected}
            onChange={handleLogin}
            name="connect"
            sx={{ m: 1 }}
          />
        }
      />
    </>
  );
};
