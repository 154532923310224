import React, { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

const ADD_CREDITS_URL = `${process.env.REACT_APP_URL}/admin/addCredits`;

const AddCreditForm = ({
  setFormView,
  customer: { userId, name },
  user,
  setShowConfirmation,
  setConfirmationMessage,
  handleCreditAdded,
}) => {
  const [loading, setLoading] = useState(false);
  const [credits, setCredits] = useState(4);
  const dispatch = useDispatch();

  const addCredit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        ADD_CREDITS_URL,
        { userId: userId || userId, credits },
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );

      if (response.status == 200) {
        setConfirmationMessage("Credits added successfully");
        setShowConfirmation(true);
      }
    } catch (error) {
      console.error("Error adding credits:", error);
    } finally {
      setLoading(false);
      setFormView();
    }
  };

  return (
    <div
      className="min-h-full bg-[#00000030] p-2 md:p-8 fixed min-w-full top-0 flex items-center justify-center left-0"
      onClick={() => setFormView()}
    >
      {loading && (
        <div className="loader-outer">
          <div className="loader z-20"></div>
        </div>
      )}
      <div
        className="flex flex-col justify-around w-full md:w-full lg:w-[32vw] h-[120px] bg-white p-4 items-center rounded-xl shadow-xl relative"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="border-l-4 w-2.5 h-[80px] border-yellow-500  pl-2 flex items-center  absolute top-6 left-1 h-fullrelative z-10">
        <p className="font-semibold text-base md:text-lg text-center mt-4 font-fredoka">
            {name}
          </p>
        </div>
        <div className="flex flex-col items-center justify-between w-full mb-4">
          <div className="flex flex-col  items-center">
            <p className="font-semibold text-lg text-center mt-4 font-fredoka ">
              Add Credits
            </p>
            <input
              onChange={(e) => setCredits(parseFloat(e.target.value))}
              type="number"
              name="coins"
              className="pl-2 font-semibold h-[30px] md:h-[40px] text-[25px] md:text-[35px] w-full md:w-24 border-b-2 text-center border-[#3D66FB] outline-none"
            />
          </div>
        </div>
        <div className="flex justify-end items-end gap-1   absolute bottom-2 right-3 font-fredoka">
          <div>
            <button
              className="border-[#3D66FB] border-1 rounded-[20px]  h-[40px] px-2 bg-white text-[#3D66FB] hover:text-blue-700"
              onClick={() => setFormView()}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className="border-[#3D66FB] border-1 rounded-[20px]  h-[40px] px-3 bg-[#3D66FB] text-white hover:bg-blue-700"
              onClick={addCredit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AddCreditForm.propTypes = {
  setFormView: PropTypes.func.isRequired,
  customer: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  user: PropTypes.object.isRequired,
  setShowConfirmation: PropTypes.func.isRequired,
  setConfirmationMessage: PropTypes.func.isRequired,
  handleCreditAdded: PropTypes.func.isRequired,
};

export default AddCreditForm;
