import React, { useEffect, useState } from "react";
import { Button, Container } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import weReportLogo from "../../assets/logo/weReporWork.png";
import CheckImage from "../../assets/check.png";

const AuthPageLayout = () => {
  return (
    <div
      className="flex p-2 gap-2 md:gap-4 flex-col justify-center items-center"
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-delay="300"
      data-aos-easing="ease-in-out"
    >
      <div className="flex flex-col gap-4 font-medium text-center">
        <ListItem text="Daily Summary Automated" />
        <ListItem text="No Manual Work" />
        <ListItem text="Never miss report deadlines" />
      </div>
    </div>
  );
};

const ListItem = ({ text }) => (
  <li className="flex items-center gap-2">
    <img src={CheckImage} alt="checkImage" className="md:h-8 md:w-8 h-4 w-4" />
    <span className="font-semibold text-sm md:text-md">{text}</span>
  </li>
);

const AcceptInvitation = () => {
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const acceptInvitation = async () => {
      try {
        setLoading(true);
        const url = `${process.env.REACT_APP_URL}/manager/acceptInviteV2/${token}`;
        const response = await axios.get(url);
        if (response.status == 200) { 
          console.log("Accepting invitation:", response);
        }
        // console.log("API Response:", response.data);
      } catch (error) {
        console.error("Error accepting invitation:", error.message);
        setError("Error accepting invitation. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    acceptInvitation();
  }, [token]);

  useEffect(() => {
    AOS.init({
      offset: 0,
    });
  }, []);

  return (
    <Container maxWidth="sm" className="mt-16 text-center">
      <Logo />
      <Heading />
      <Description />
      <AuthPageLayout />
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {error && <div>{error}</div>}
          <Button variant="contained" color="primary" className="mt-4" onClick={() => window.location.href = '/login'}>
            Continue with Login
          </Button>
        </>
      )}
    </Container>
  );
};

const Logo = () => (
  <div className="flex justify-center" data-aos="fade-up">
    <img src={weReportLogo} alt="logo" className="h-[8rem] md:h-[12rem] mt-4" />
  </div>
);

const Heading = () => (
  <h5
    className="mt-4 md:text-4xl text-xl font-bold font-fredoka"
    data-aos="fade-left"
    data-aos-duration="800"
    data-aos-delay="400"
    data-aos-easing="ease-in-out"
  >
    Welcome to We Report Work!
  </h5>
);

const Description = () => (
  <p
    className="mt-2 font-fredoka"
    data-aos="fade-right"
    data-aos-duration="800"
    data-aos-delay="500"
    data-aos-easing="ease-in-out"
  >
    You're invited to join our awesome community.
  </p>
);

export default AcceptInvitation;
