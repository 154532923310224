import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchSavedSummary } from "../../Store/actions/fetchSavedSummary";
import { addSummary } from "../../Store/actions/addSummary";
import { Modal } from "@mui/material";

const EditSummaryModal = ({
  selectedTool,
  date,
  summary,
  userId,
  handleClose,
}) => {
  const [editedSummary, setEditedSummary] = useState(summary);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    await dispatch(
      addSummary({
        userId: userId,
        summary: editedSummary,
        date: date,
        summary_type: selectedTool,
      })
    );
    await dispatch(fetchSavedSummary({ selectedDate: date, userId }));
    setLoading(false);
    handleClose(true, editedSummary); // Pass true to indicate successful edit
  };

  const handleCancel = () => {
    handleClose(false, summary); // Pass false to indicate cancel and pass the original summary
  };

  return (
    <Modal open={true} onClose={handleCancel}>
      <div
        className="fixed bg-[#00000080] w-screen left-0 top-0 h-screen flex items-center justify-center z-20"
      >
        <div
          className="flex flex-col bg-white md:w-[70vw] mx-auto p-5 rounded-xl pr-2 w-[90vw]"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="border-l-2 flex flex-col border-yellow-500 md:pl-5 pl-2 mb-3.5 items-start">
            <span className="font-medium text-sm">Edit Summary</span>
          </div>
          <textarea
            className="w-100 min-h-[50vh] border-0 bg-lotion rounded-xl"
            value={editedSummary}
            onChange={(e) => setEditedSummary(e.target.value)}
            rows={4}
            cols={50}
          />
          <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
            <button
              className="btn btn-success mx-2 text-white"
              onClick={handleSave}
              disabled={loading}
            >
              Save
            </button>
            <button
              onClick={handleCancel}
              className="btn btn-danger mx-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditSummaryModal;
