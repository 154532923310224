import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";

const Disclosure = () => {
  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    const hasSeenDisclosure = localStorage.getItem("hasSeenDisclosure");
    if (hasSeenDisclosure) {
      setShowFooter(false);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem("hasSeenDisclosure", "true");
    setShowFooter(false);
  };

  return (
    <>
      {showFooter && (
        <div className="left-0 fixed bottom-0 w-full bg-black text-white p-4 border-b border-gray-800 h-auto md:h-32 ">
          <div className="text-sm font-fredoka">
            <p className="mb-2">
              <span className="text-xl">
                Disclosure: At We Report Work, your trust is important to us.🫶{" "}
              </span>
            </p>
            <p className="mb-4">
              We are dedicated to maintaining the highest standards of data
              privacy and security. In alignment to this commitment, our app
              strictly adheres to the{" "}
              <a
                href="https://developers.google.com/terms/api-services-user-data-policy"
                className="underline font-medium text-[#3D66FB]"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google API Services User Data Policy
              </a>
              , including the Limited Use requirements. <br /> We ensure that
              handling, processing, and storage of such data is conducted in a
              manner that is fully compliant with the specified policies and
              requirements. For more information, we encourage our users to{" "}
              <a
                href="https://developers.google.com/terms/api-services-user-data-policy"
                className="underline font-medium text-[#3D66FB]"
                target="_blank"
                rel="noopener noreferrer"
              >
                review the policy
              </a>
              .
            </p>
            <button
              className="absolute top-2 right-2 text-2xl focus:outline-none"
              onClick={handleClose}
              title="Close"
            >
              <FaTimes />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Disclosure;
