import React, { useState } from "react";
import coin from "../assets/coin.png";
import gify from "../assets/gify.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AiFillInfoCircle } from "react-icons/ai";
import { styled } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../Store/reducers/authLoginReducer";
import "./Header.css"

const StyledDiv = styled("div")({
  padding: "12px",
  // marginBottom: "20px",
});

const HeaderItemContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginBottom: "20px",
});

const Header = ({ mode }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.authLogin);
  const { userProfile } = useSelector((state) => state.userProfile);

  const handleLogout = async () => {
    const url = process.env.REACT_APP_URL + "/auth/logout";

    setLoading(true);
    try {
      await axios.get(url, {
        headers: {
          Authorization: `Bearer ${user["connect.sid"]}`,
        },
      });
      dispatch(logout());
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("user");

      navigate("/login");
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDiv>
      {loading && (
        <div className="loader-outer">
          <div className="loader z-20"></div>
        </div>
      )}

      <HeaderItemContainer>
        {mode === "credits" &&
          <div className="flex flex-col items-end">
            <button
              className={mode === "credits" ? "text-[#3d66fb] font-fredoka font-semibold  no-underline cursor-pointer" :
                "text-[#3d66fb] font-fredoka font-semibold  no-underline cursor-pointer mb-2"}
              onClick={handleLogout}
            >
              Sign out
            </button>
          </div>
        }
        {/* 
        plan logo credits */}
        <div className="flex justify-between h-20 items-center ">
          {/* User Plan  */}
          <div className=" flex flex-row rounded-lg border-2 border-[#3d66fb] p-2 md:h-19 h-14 gap-2  md:gap-5 bg-white text-xs  w-48 items-center  md:justify-between pt-2 pb-0 header_right">
            <img src={gify} alt="giftImg" className="h-6 w-6 md:h-8 md:w-8 pt-0" />

            <div
              className="flex flex-col  items-start h-14   w-full font-[600] py-2  pt-0 justify-center text-size"
              key={user}
            >
              <span className="top_text">
                Your Plan: {userProfile?.data[0]?.isPromoCode ? 'VIP' : userProfile?.data[0]?.currentPlan[0]?.name}
                {userProfile?.data[0]?.currentPlan[0]?.name === "FREE TIER" &&
                  " (LIMITED)"}
              </span>
              <Link
                className="underline cursor-pointer text-[#3d66fb] w-full text-wrap bottom_text"
                to={userProfile?.data[0]?.isPromoCode ? '/PromoPricing' : '/pricing'}
              >
                Explore Plans / Topup
              </Link>
            </div>
          </div>

          {/* Logo */}
          {user?.data[0]?.role === "manager" && user.logo && (
            <div className="text-sm md:text-lg hidden md:flex">
              <img src={user?.logo} className="md:h-12 h-8" alt="logo" />
            </div>
          )}
          {/* Users credits */}
          {showInfo && (
            <p className="text-xs bg-white rounded-xl p-2 absolute right-10 top-16 transition duration-150 delay-100">
              Tokens are inputs/outputs to/from the summarisation model (4 char ~= 1 token)
            </p>
          )}
          {mode === "credits" ?
            <div
              className=" flex flex-row rounded-lg 
          border-2 border-[#3d66fb] p-2  h-14 md:h-16 md:gap-5 gap-3 bg-white md:w-44 w-44 justify-center  header_right"
            >
              <img src={coin} alt="coin" className="md:h-10 md:w-10 h-8 w-8" />
              <div className="flex flex-col  items-start h-14  justify-center  ">
                <div className="flex gap-2">
                  <p>Credits</p>
                  <AiFillInfoCircle
                    onMouseEnter={() => setShowInfo(!showInfo)}
                    onMouseLeave={() => setShowInfo(!showInfo)}
                    className="h-5 w-5 cursor-pointer"
                  />
                </div>
                <p className="font-bold  md:font-lg -mt-5">
                  ${userProfile?.data[0]?.isPromoCode ? 'UNLIMITED' : userProfile?.data[0]?.token[0]?.availableCredits}
                </p>
              </div>
            </div> :
            <button
              className={mode === "credits" ? "text-[#3d66fb] font-fredoka font-semibold  no-underline cursor-pointer" :
                "text-[#3d66fb] font-fredoka font-semibold  no-underline cursor-pointer mb-2"}
              onClick={handleLogout}
            >
              Sign out
            </button>
          }
        </div>
      </HeaderItemContainer>

      <h1 className="d-flex justify-content-between mb-1 ml-2 ">
        <span>Welcome, {user?.displayName}</span>
      </h1>
    </StyledDiv>
  );
};

export default Header;
