import React, { useEffect, useState } from "react";
import { VscExclude } from "react-icons/vsc";
import { RiArrowUpDownFill } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import axios from "axios";
import Outlook from "../../../assets/coming/Outlook.png";
import { useSummary } from "../../../context/SummaryDataContext";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserProfile } from "../../../Store/actions/fetchUserProfile";
import { SignInButton } from "./SignInButton";
import "./Outlook.css";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { loginRequest } from "./authConfig";
import { useMsal } from '@azure/msal-react';


const OutlookList = ({ selectedDate, user, summaryData, updateStatus }) => {

    const dispatch = useDispatch();
    const { instance, accounts } = useMsal();
    const { outlookEvents, setoutlookEvents } = useSummary();
    const { userProfile } = useSelector((state) => state.userProfile);
    const [summary, setSummary] = useState([]);
    const [loadingSummary, setLoadingSummary] = useState(false);
    const [OutlookActiveTab, setOutlookActiveTab] = useState("input");
    const [OutlookExclude, setOutlookExclude] = useState(user?.data[0]?.outlookEmails);
    const [IsLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isConnected, setIsConnected] = useState(
        user?.data[0]?.outlookEmails?.isConnect
    );

    useEffect(() => {
        if (userProfile && userProfile.data[0].outlookEmails) {
            setOutlookExclude(userProfile?.data[0]?.outlookEmails);
            if (userProfile?.data[0]?.outlookEmails?.isConnect !== undefined) {
                setIsConnected(userProfile.data[0].outlookEmails.isConnect);
            }
        }
    }, [selectedDate, user, userProfile]);
    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const fetchOutlookData = async () => {
        setIsLoading(true);
        let api_call_completed = false;
        const url =
            process.env.REACT_APP_URL + "/outlook/emails?date=" + selectedDate;

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${user["connect.sid"]}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setoutlookEvents(data);
                setIsLoading(false);
            }
            if (response.status !== 200) {
                setIsLoading(false);
            }
            if (response.status == 401) {
                handleMsalTokens();
                // connectOutlook(false)
            }
        } catch (error) {
            console.error("Error fetching outlook data:", error);
        } finally {
            setIsLoading(false);
            api_call_completed = true;
            updateStatus({ isConnected, api_call_completed });
        }
    };

    const connectOutlook = async (isConnected) => {
        try {
            const url = process.env.REACT_APP_URL + "/outlook/outlookEmailsconnect";
            const response = await axios.put(
                url,
                { isConnect: isConnected },
                {
                    headers: {
                        Authorization: `Bearer ${user["connect.sid"]}`,
                    },
                }
            );
            if (response.status == 200) {
                setIsConnected(false);
                setSnackbarOpen(true);
                dispatch(fetchUserProfile());
            } else {
                console.error("API call failed");
            }
        } catch (error) {
            console.error("Fetch error:", error);
        }
    };

    const handleAccessToken = async (response) => {
        const accessToken = response;
        try {
            if (accessToken) {
                const url =
                    process.env.REACT_APP_URL + "/outlook/save_outlook_credentials";
                const response = await axios.post(
                    url,
                    {
                        accessToken: accessToken,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${user["connect.sid"]}`,
                        },
                    }
                );
                if (response.status == 200) {
                    fetchOutlookData();
                }
            }
        } catch (error) {
            console.error("Fetch error:", error);
        }
    };

    const handleMsalTokens = async () => {
        await instance.initialize();
        await instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                handleAccessToken(response.accessToken)
            })
            .catch((e) => {
                connectOutlook(false)
            })
    };


    useEffect(() => {
        if (isConnected) {
            updateStatus({ isConnected, "api_call_completed": false });
            fetchOutlookData();
        }
    }, [selectedDate, isConnected]);

    const handleDeleteEvents = (index) => {
        const updatedEvents = [...outlookEvents];
        updatedEvents.splice(index, 1);
        setoutlookEvents(updatedEvents);
    };

    const convertToIST = (backendDate) => {
        const originalDate = new Date(backendDate);
        return originalDate.toLocaleTimeString('en-IN', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };

    const updateCheckPoint = async (e) => {
        if (!OutlookExclude) {
            return;
        }
        let updatedOutlookExclude;
        if (e.target.name === "limitLength") {
            const name = e.target.name;
            const value = e.target.checked ? Number(e.target.value) : 14000;
            updatedOutlookExclude = { ...OutlookExclude, [name]: value };
        } else {
            const name = e.target.name;
            const currentValue = OutlookExclude[name];
            const updatedValue = !currentValue;
            updatedOutlookExclude = { ...OutlookExclude, [name]: updatedValue };
        }
        setOutlookExclude(updatedOutlookExclude);
        try {
            const url = process.env.REACT_APP_URL + "/auth/outlookEmails";
            const response = await axios.post(
                url,
                { ...updatedOutlookExclude },
                {
                    headers: {
                        Authorization: `Bearer ${user["connect.sid"]}`,
                    },
                }
            );
            if (response.status === 200) {
                await dispatch(fetchUserProfile());
                isConnected && fetchOutlookData();
            } else {
                console.error("API call failed");
            }
        } catch (error) {
            console.error("Fetch error:", error);
        }
    };

    const handleTextareaChange = (event, index) => {
        const updatedEvents = [...outlookEvents];
        updatedEvents[index].Content = event.target.value;
        setoutlookEvents(updatedEvents);
    };

    return (
        <>
            <div className="rounded-xl mt-10 min-h-300 shadow border-zinc-300  min-h-500 bg-lotion max-md:overflow-x-auto md:overflow-hidden scrollbar-width scrollable-container">
                <div className="mb-2 flex gap-2 justify-between items-center text-center">
                    <div></div>
                    <div className="flex items-center gap-x-2 ml-16 text-black text-opacity-80 text-xl font-bold font-['DM Sans']">
                        <img src={Outlook} alt="Outlook" className="h-8 w-8" />
                        Outlook
                    </div>

                    <div className="text-[#3d66fb] mr-4">
                        <SignInButton
                            user={user}
                            isConnected={isConnected}
                            setIsConnected={setIsConnected}
                            setSnackbarOpen={setSnackbarOpen}
                            setoutlookEvents={setoutlookEvents}
                            selectedDate={selectedDate}
                        />
                    </div>
                </div>
                <div
                    className={`flex border buttons h-12 shadow-md border-zinc-300 text-black text-opacity-80 text-xl font-['DM Sans'] bg-lotion rounded-full items-center justify-between mx-auto font-semibold mt-2`}
                >
                    <button
                        className={`${OutlookActiveTab === "input"
                            ? "border rounded-full shadow-xl"
                            : ""
                            } w-80 h-full`}
                        onClick={() => setOutlookActiveTab("input")}
                    >
                        {" "}
                        <span className="flex items-center justify-center gap-x-6">
                            {" "}
                            <span className="text-2xl font-extralight">
                                <RiArrowUpDownFill />
                            </span>
                            Input{" "}
                        </span>{" "}
                    </button>
                    <button
                        className={`${OutlookActiveTab === "summary"
                            ? "border rounded-full shadow-xl"
                            : ""
                            } w-80 h-full`}
                        onClick={() => setOutlookActiveTab("summary")}
                    >
                        {" "}
                        <span className="flex items-center justify-center  gap-x-6">
                            {" "}
                            <span className="text-2xl font-extralight">
                                <ImProfile />
                            </span>
                            Summary{" "}
                        </span>
                    </button>
                    <button
                        className={`${OutlookActiveTab === "exclusions"
                            ? "border rounded-full shadow-xl"
                            : ""
                            } w-80 h-full`}
                        onClick={() => setOutlookActiveTab("exclusions")}
                    >
                        {" "}
                        <span className="flex items-center justify-center gap-x-6">
                            {" "}
                            <VscExclude className="text-2xl" /> Exclusions{" "}
                        </span>
                    </button>
                </div>

                {OutlookActiveTab === "exclusions" && (
                    <div className="flex flex-col p-4 gap-2">
                        <div className="flex gap-2">
                            <input
                                type="checkbox"
                                name="limitLength"
                                id="limitChar"
                                checked={OutlookExclude?.limitLength === 500 ? true : false}
                                onChange={updateCheckPoint}
                                value={500}
                            />
                            <label htmlFor="limitChar">
                                Limit Items to 500 Char
                            </label>
                        </div>
                        <div className="flex gap-2">
                            <input
                                type="checkbox"
                                name="limitLength"
                                checked={OutlookExclude?.limitLength === 1000 ? true : false}
                                onChange={updateCheckPoint}
                                value={1000}
                            />
                            <label htmlFor="">Limit Items to 1000 Char</label>
                        </div>
                        <div className="flex gap-2">
                            <input
                                type="checkbox"
                                checked={OutlookExclude?.excludeUnsubscribe}
                                name="excludeUnsubscribe"
                                id="excludeUnsubscribe"
                                onChange={updateCheckPoint}
                            />
                            <label htmlFor="excludeUnsubscribe">
                                Exclude Unsubscribe Emails
                            </label>
                        </div>
                    </div>
                )}

                {isConnected &&
                    outlookEvents?.length >= 1 &&
                    OutlookActiveTab === "input" ? (
                    IsLoading ? (
                        <div className="flex justify-center items-center min-h-300 gap-4">
                            <img src={Outlook} alt="Outlook" className="h-8" />
                            <p className="mt-3 font-medium">Loading Outlook emails...</p>
                        </div>
                    ) : (
                        <div className="table-container">
                            <table className="min-w-full divide-y-2 rounded-xl  overflow-auto h-20 divide-gray-200 bg-lotion text-md mt-2 border-b border-gray-200">
                                <thead className="ltr:text-left rtl:text-right">
                                    <tr>
                                        <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                                            To
                                        </th>
                                        <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                                            Thread Name
                                        </th>
                                        <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                                            Message Content
                                        </th>
                                        <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                                            Sent Time
                                        </th>
                                        <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900"></th>
                                    </tr>
                                </thead>

                                <tbody className="divide-y divide-gray-200 ml-20">
                                    {outlookEvents?.map((event, index) => (
                                        <tr key={index} className="">
                                            <td className="pl-4 py-2 text-gray-700">
                                                <div className="w-full min-h-[5vh] h-20 max-h-[30vh] overflow-hidden max-w-full ">
                                                    <textarea
                                                        name=""
                                                        rows="1"
                                                        cols="1"
                                                        id=""
                                                        value={event?.To}
                                                        onChange={(event) =>
                                                            handleTextareaChange(
                                                                event,
                                                                index
                                                            )
                                                        }
                                                        className="w-[15vw] h-full bg-lotion p-0.5 min-h-[5vh] max-h-[30vh] resize-none "
                                                    >
                                                        {event?.To}
                                                    </textarea>
                                                </div>
                                            </td>
                                            <td className="flex-wrap pl-6 py-2 font-medium text-gray-900  max-w-full ">
                                                {event?.Subject}
                                            </td>
                                            <td className="pl-4 py-2 text-gray-700">
                                                <div className="w-full min-h-[5vh] h-20 max-h-[30vh] overflow-hidden max-w-full ">
                                                    <textarea
                                                        name=""
                                                        rows="1"
                                                        cols="1"
                                                        id=""
                                                        value={event?.Content}
                                                        onChange={(event) =>
                                                            handleTextareaChange(
                                                                event,
                                                                index
                                                            )
                                                        }
                                                        className="w-[30vw] h-full bg-lotion p-2 min-h-[5vh] max-h-[30vh] resize-none "
                                                    >
                                                        {event?.Content}
                                                    </textarea>
                                                </div>
                                            </td>
                                            <td className="flex-wrap pl-6 py-2 font-medium text-gray-900  max-w-full ">
                                                {convertToIST(event?.sentDateTime)}
                                            </td>
                                            <td>
                                                <i
                                                    className="fa fa-trash"
                                                    onClick={() =>
                                                        handleDeleteEvents(index)
                                                    }
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )
                ) : OutlookActiveTab === "input" ? (
                    <div className="flex justify-center items-center min-h-300 gap-4">
                        <img src={Outlook} alt="Outlook" className="h-8" />

                        {IsLoading ? (
                            <p className="mt-3 font-medium">
                                Loading Outlook emails...
                            </p>
                        ) : (
                            <p className="mt-3 font-medium">
                                Outlook emails not found.
                            </p>
                        )}
                    </div>
                ) : (
                    ""
                )}
                {isConnected && OutlookActiveTab === "summary" && (
                    <p className="w-[70%] m-auto p-3 whitespace-pre-wrap overflow-auto h-[24rem]">
                        {outlookEvents?.length > 0 ? (
                            <span>{summaryData}</span>
                        ) : (
                            <span></span>
                        )}
                    </p>
                )}
                {loadingSummary ? (
                    <div className=" loader-outer z-99999">
                        <div className=" loader z-999999"></div>
                    </div>
                ) : (
                    ""
                )}

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <Alert severity={isConnected ? "success" : "error"}>
                        {isConnected
                            ? "Hurray! You are connected."
                            : "Connect to summarize Outlook emails!"}
                    </Alert>
                </Snackbar>
            </div>
        </>
    );
};

export default OutlookList;
