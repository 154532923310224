import React, { useState, createContext, useContext } from "react";

const SummaryContext = createContext();

export function useSummary() {
  return useContext(SummaryContext); 
}

export function SummaryProvider({ children }) {
  const [Emails, setEmails] = useState([]);
  const [calenderEvents, setCalenderEvents] = useState([]);
  const [driveList, setDriveList] = useState([]);
  const [outlookEvents,setoutlookEvents]=useState([])
  const [teamEvents,setteamEvents]=useState([])
  const [slackEvents, setSlackEvents] = useState([]);
  const [gitHubEvents, setgitHubEvents] = useState([]);
  const [ JiraEvents, setJiraEvents ] = useState([]);

  const value = {
    calenderEvents,
    setCalenderEvents,
    Emails,
    setEmails,
    driveList,
    setDriveList,
    outlookEvents,
    setoutlookEvents,
    teamEvents,
    setteamEvents,
    slackEvents,
    setSlackEvents,
    gitHubEvents,
    setgitHubEvents,
    JiraEvents,
    setJiraEvents
  };

  return (
    <SummaryContext.Provider value={value}>{children}</SummaryContext.Provider>
  );
}
