// src/Store/Store.js

import { configureStore } from "@reduxjs/toolkit";
import authLoginReducer from "./reducers/authLoginReducer";
import templatesReducer from "./reducers/templatesReducer"; // Import the templatesReducer
import addTemplateReducer from "./reducers/addTemplateReducer"; // Import the templatesReducer
import fetchSavedSummaryReducer from "./reducers/fetchSavedSummaryReducer"; // Import the templatesReducer
import addSummaryReducer from "./reducers/addSummaryReducer";
import fetchUserProfileReducer from "./reducers/fetchUserProfileReducer";

const store = configureStore({
  reducer: {
    authLogin: authLoginReducer,
    templates: templatesReducer, 
    addTemplates: addTemplateReducer, // Include the templatesReducer in the reducer object
    savedSummary: fetchSavedSummaryReducer,
    addSummary: addSummaryReducer,
    userProfile: fetchUserProfileReducer
    // Include the templatesReducer in the reducer object
  },
});
 
export default store;