import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { SummaryProvider } from "./context/SummaryDataContext";
import { Provider } from "react-redux";
import store from "./Store/Store";
import App from "./App";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './pages/Home/Outlook/authConfig';
import { ThemeProvider } from '@mui/material/styles';
import theme from "../src/components/theme";

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SummaryProvider>
        <BrowserRouter>
          <Provider store={store}>
            <MsalProvider instance={msalInstance}>
              <App />
            </MsalProvider>
          </Provider>
        </BrowserRouter>
      </SummaryProvider>
    </ThemeProvider>
  // </React.StrictMode>
); 