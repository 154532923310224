import axios from "axios";
import store from "../Store";
export const FETCH_USER_PROFILE_REQUEST = "FETCH_USER_PROFILE_REQUEST";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_FAILURE = "FETCH_USER_PROFILE_FAILURE";

export const fetchUserProfileRequest = () => {
  return {
    type: FETCH_USER_PROFILE_REQUEST,
  };
};

export const fetchUserProfileSuccess = (data) => {
  return {
    type: FETCH_USER_PROFILE_SUCCESS,
    payload: data.data.user,
  };
};

export const fetchUserProfileFailure = (error) => {
  return {
    type: FETCH_USER_PROFILE_FAILURE,
    payload: error,
  };
};

export const fetchUserProfile = () => {
  return async (dispatch) => {
    dispatch(fetchUserProfileRequest());
    const url = process.env.REACT_APP_URL + "/auth/user";
    try {
      const res = await axios.get(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${store.getState().authLogin.user["connect.sid"]}`,
        },
      });
      const user = res;

      if (user?.data?.user)
        localStorage.setItem("user", JSON.stringify({ 'connect.sid': store.getState().authLogin.user["connect.sid"], ...user.data.user }));

      dispatch(fetchUserProfileSuccess({ "connect.sid": store.getState().authLogin.user["connect.sid"], ...user }));
    } catch (error) {
      const errorMsg = error.message;
      dispatch(fetchUserProfileFailure(errorMsg));
    }
  };
};

export const updateUserInfo = (user) => {
  return async (dispatch) => {
    dispatch(fetchUserProfileSuccess(user));
  };
};
