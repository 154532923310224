import React from "react";
import {
  img5,
  Teams,
  img3,
  img4,
  img6,
  comingImage,
  firebaseImage,
  img7,
  img8,
} from "../assets/coming/index";

const ComingSoon = () => {
  return (
    <div className="bg-white rounded-full shadow-md flex items-center justify-around mb-5 md:mx-3 py-2 mt-5 px-5">
      <img src={comingImage} alt="coming" className="md:h-16 h-12" />
      <img src={img3} alt="icon" className="md:h-10 h-8" />
      <img src={firebaseImage} alt="icon" className="md:h-16 h-10" />
      <img src={img4} alt="icon" className="md:h-10 h-8 hidden md:flex" />
      <img src={Teams} alt="icon" className="md:h-10 h-8 hidden md:flex" />
      <img src={img5} alt="icon" className="md:h-10 h-8 hidden md:flex" />
      <img src={img6} alt="icon" className="md:h-16 h-10 hidden md:flex" />
      <img src={img7} alt="icon" className="md:h-10 h-8 hidden md:flex" />
      <img src={img8} alt="icon" className="md:h-10 h-8 hidden md:flex" />
      <div className="font-semibold md:text-xl ">& more</div>
    </div>
  );
};

export default ComingSoon;
