import axios from "axios";
import store from "../Store";
export const ADD_TEMPLATES_REQUEST = "ADD_TEMPLATES_REQUEST";
export const ADD_TEMPLATES_SUCCESS = "ADD_TEMPLATES_SUCCESS";
export const ADD_TEMPLATES_FAILURE = "ADD_TEMPLATES_FAILURE";

export const addTemplateRequest = () => {
  return {
    type: ADD_TEMPLATES_REQUEST,
  };
};

export const addTemplatesSuccess = (templates) => {
  return {
    type: ADD_TEMPLATES_SUCCESS,
    payload: templates,
  };
};

export const addTemplatesFailure = (error) => {
  return {
    type: ADD_TEMPLATES_FAILURE,
    payload: error,
  };
};

export const addTemplates = (data) => {
  return async (dispatch) => {
    dispatch(addTemplateRequest());
    const url = process.env.REACT_APP_URL + "/templates/template";
    try {
      const res = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${
            store.getState().authLogin.user["connect.sid"]
          }`,
        },
      });
      const templates = res.data.data;
      dispatch(addTemplatesSuccess(templates));
    } catch (error) {
      const errorMsg = error.message;
      dispatch(addTemplatesFailure(errorMsg));
    }
  };
};
