import React, { useEffect, useState } from "react";
import TemplateForm from "./TemplateForm";
import {useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Link } from "react-router-dom";
import "./Template.css";
import { updateTemplates } from "../../Store/actions/updateTemplate";

const Template = ({
  setTemplateView,
  setPrompt,
  setConfirmationMessage,
  setSeverity,
  setShowConfirmation,
  prompt,
  handleSummarizeClick,
}) => {
  const dispatch = useDispatch();
  const [templateForm, setTemplateForm] = useState(false);
  const [showTemplate, setShowTemplate] = useState("");
  const { userTemplate } = useSelector((state) => state.templates);
  const [isLoading, setIsLoading] = useState(false);

  const setFormView = (templateForm) => {
    setTemplateForm(templateForm);
  };

  const { user } = useSelector((state) => state.authLogin);
  const { userProfile } = useSelector((state) => state.userProfile);
  const plan = userProfile?.data[0]?.currentPlan[0] || {};
  const [selectedOption, setSelectedOption] = useState(userTemplate[0]?._id);

  useEffect(() => {
    if (userTemplate) {
      const selectedTemplate = userTemplate.find(
        (temp) => temp.description === prompt
      );
      setShowTemplate(
        selectedTemplate
          ? selectedTemplate?.description
          : userTemplate[0]?.description
      );
      setSelectedOption(
        selectedTemplate ? selectedTemplate?._id : userTemplate[0]?._id
      );
    }
  }, [userTemplate, prompt]);

  const handleOptionClick = (data) => {
    setSelectedOption(data._id);
    setPrompt(data?.description);
    setShowTemplate(data?.description);
  };

  const handleSaveTemplate = async () => {
    setIsLoading(true);
    if (plan.templates !== "Fixed Templates") {
      // Update your template logic here using Mui components if needed
     await dispatch(updateTemplates({ _id: selectedOption, description: showTemplate })); 
     setConfirmationMessage("Template Updated Successfully");
    } else {
      setConfirmationMessage("Template selected Successfully");
    }
    setIsLoading(false);
    setSeverity("success");
    setShowConfirmation(true);
    setPrompt(showTemplate);
    setTemplateView();
  };

  const handleSeletedTemplate = (template) => {
    handleOptionClick(template);
  };

  var isMobileScreen = 0;
  if (window.innerWidth < 450) {
    isMobileScreen = 1;
  }

  return (
    <Modal
      open={true}
      onClose={() => setTemplateView()}
      className="flex items-center justify-center"
    >
      <div className="bg-white rounded-xl md:px-10 md:py-10 lg:w-[70vw] w-[80vw] min-h-[50vh] mx-auto">
        <div className="border-l-8 flex flex-col border-yellow-500 md:pl-4 pl-2 mb-3.5 items-start">
          <span className="font-semibold md:text-lg">Templates</span>
          <span className=" font-medium text-sm ">
            Select the template to change your summary output.
          </span>
        </div>

        <div className={`${isMobileScreen ? '' : 'flex'} justify-between px-3 gap-4 main_body`}>
          <div className="flex flex-col gap-2 font-semibold w-full main_body">
            {plan.templates === "Fixed Templates" && (
              <>
                {userTemplate?.map((template) => (
                  <div
                    key={template._id}
                    className="flex align-items-center gap-2 cursor-pointer"
                    onClick={() => handleSeletedTemplate(template)}
                  >
                    <div
                      className={`h-5 w-5 rounded-full border-2 border-black`}
                    >
                      {selectedOption === template._id && (
                        <p className="bg-black h-3 w-3 rounded-full mt-[2px] mx-auto transition-all duration-100 delay-100"></p>
                      )}
                    </div>
                    <Button>{template?.name}</Button>
                  </div>
                ))}
              </>
            )}

            {plan.templates !== "Fixed Templates" && (
              <>
                {userTemplate?.map((template) => (
                  <div
                    key={template._id}
                    className="flex align-items-center gap-2 cursor-pointer"
                    onClick={() => handleSeletedTemplate(template)}
                  >
                    <div
                      className={`h-5 w-5  rounded-full border-2 border-black   `}
                    >
                      {selectedOption === template._id && (
                        <p className="bg-black h-3 w-3 rounded-full mt-[2px] mx-auto transition-all duration-100 delay-100"></p>
                      )}
                    </div>
                    <Button>{template?.name}</Button>
                  </div>
                ))}
                {/* {plan.templates === "Editable Templates" && (
                  <Button
                    className="bg-[#3d66fb] rounded-xl px-2 py-1 text-white font-semibold"
                    onClick={() => setTemplateForm(!templateForm)}
                  >
                    Add New
                  </Button>
                )} */}
              </>
            )}
          </div>

          <div className="flex flex-col w-full content_body">
            <div className="flex justify-between items-center p-1">
              <div className="ml-auto">
                {userProfile?.data[0]?.currentPlan[0]?.name ===
                  "FREE TIER" && (
                    <Link
                      className="cursor-pointer text-[#3d66fb] md:-mt-2 text-wrap no-underline"
                      to="/pricing"
                    >
                      [Upgrade to EDIT]
                    </Link>
                  )}
              </div>
            </div>

            {plan.templates !== "Fixed Templates" ? (
              <TextareaAutosize
                className="bg-cultured text_area  border border-black p-2 md:p-2 rounded-xl md:min-w-[35vw] bottom-12 left-2 font-thin min-h-[40vh] font-codepro"
                defaultValue={showTemplate}
                onChange={(e) => setShowTemplate(e.target.value)}
              />
            ) : (
              <div className="bg-cultured p-4 md:p-8 rounded-xl md:min-w-[40vw] text-left font-thin min-h-[40vh] cursor-not-allowed">
                {showTemplate}
              </div>
            )}

            <div className="md:gap-5 gap-2 justify-end mt-5 hidden md:flex relative left-2 ">
              <Button
                variant="outlined"
                style={{ borderColor: "#3d66fb", color: "#3d66fb" }}
                className="rounded-xl w-2/12 py-2"
                onClick={() => setTemplateView()}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                style={{ backgroundColor: "#3d66fb", color: "white" }}
                className="rounded-md w-2/12 py-2"
                onClick={() => {
                  handleSaveTemplate();
                  // handleSummarizeClick();
                }}
                disabled={isLoading}
              >
                Save
              </Button>
            </div>
          </div>
        </div>

        <div className="flex py-3 px-3 md:gap-5 gap-2 justify-end md:hidden save_button">
          <Button
            variant="outlined"
            style={{ borderColor: "#3d66fb", color: "#3d66fb" }}
            className="md:w-[13%] w-4/12 py-2 rounded-3xl px-2"
            onClick={() => setTemplateView()}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            style={{ backgroundColor: "#3d66fb", color: "white" }}
            className="md:w-[13%] w-4/12 py-2 rounded-3xl px-2"
            onClick={handleSaveTemplate}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Template;
