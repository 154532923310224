import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VscExclude } from "react-icons/vsc";
import { RiArrowUpDownFill } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import axios from "axios";
import calenderImage from "../../../assets/google/calender.png";
import { useSummary } from "../../../context/SummaryDataContext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import FormControlLabel from '@mui/material/FormControlLabel';
import SwitchBtn from "../../../components/SwitchBtn";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserProfile } from "../../../Store/actions/fetchUserProfile";


const CalenderList = ({
  selectedDate,
  user,
  getCalenderEvents,
  onSummary,
  summaryData,
  updateStatus
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [calenderExclude, setCalenderExclude] = useState(
    user?.data[0].calender
  );
  const { calenderEvents, setCalenderEvents } = useSummary();
  const { userProfile } = useSelector((state) => state.userProfile);
  const [CalenderActiveTab, setCalenderActiveTab] = useState("input");
  const [summary, setSummary] = useState([]);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [isConnected, setIsConnected] = useState(
    userProfile?.data[0]?.calender?.isConnect
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showConnectAlert, setShowConnectAlert] = useState(false);

  useEffect(() => {
    if (userProfile) {
      setCalenderExclude(userProfile?.data[0].calender);
    }
    if (userProfile?.data[0]?.calender?.isConnect !== undefined) {
      setIsConnected(userProfile.data[0].calender.isConnect);
    }
  }, [userProfile]);


  const connect = async (isConnected) => {
    try {
      const url = process.env.REACT_APP_URL + "/calendar/connect";
      const response = await axios.put(
        url,
        { isConnect: isConnected },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );

      if (response.status == 200 && isConnected) {
        setIsConnected(true);
        setSnackbarOpen(true);
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionSuccessEvent',
          'feature': 'calender',
          'status': 'Connected',
        });
      } else if (response.status === 200 && !isConnected) {
        setIsConnected(false);
        setSnackbarOpen(true);
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionRemoveEvent',
          'feature': 'calender',
          'status': 'Removed',
        });
      } else {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionFailedEvent',
          'feature': 'calender',
          'status': 'Failed'
        });
      }
    } catch (error) {
      // console.error("Fetch error:", error);
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'toolConnectionFailedEvent',
        'feature': 'calender',
        'status': 'Failed'
      });
    }
  };

  const fetchCalendarEvents = async () => {
    setLoadingEvents(true);
    let api_call_completed = false;
    const url =
      process.env.REACT_APP_URL +
      "/calendar/events?date=" +
      selectedDate +
      "&timezoneOffset=" +
      new Date().getTimezoneOffset();
    // console.log("Request URL:", url);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user["connect.sid"]}`,
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        setCalenderEvents(data.calendarEvents);
      } else {
        // console.log("Failed to fetch calendar events:", response.statusText);
      }
    } catch (error) {
      // console.log("Error fetching calendar events:", error);
    } finally {
      setLoadingEvents(false);
      api_call_completed = true;
      updateStatus({ isConnected, api_call_completed });
    }
    setLoadingEvents(false);
  };
  const handleDeleteEvents = (index) => {
    const updatedCalendarEvents = [...calenderEvents];
    updatedCalendarEvents.splice(index, 1);
    setCalenderEvents(updatedCalendarEvents);
  };

  const convertToIST = (backendDate) => {
    const originalDate = new Date(backendDate);
    // const istDate = new Date(originalDate.getTime() + 5.5 * 60 * 60 * 1000);
    return originalDate.toLocaleTimeString('en-IN', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };


  const updateCheckPoint = async (e) => {
    if (!calenderExclude) {
      return;
    }
    let updatedCalenderExclude;
    if (e.target.name === "limitLength") {
      const name = e.target.name;
      const value = e.target.checked ? Number(e.target.value) : 14000;
      updatedCalenderExclude = { ...calenderExclude, [name]: value };
    } else {
      const name = e.target.name;
      const currentValue = calenderExclude[name];
      const updatedValue = !currentValue;
      updatedCalenderExclude = { ...calenderExclude, [name]: updatedValue };
    }
    setCalenderExclude(updatedCalenderExclude);
    try {
      const url = process.env.REACT_APP_URL + "/auth/updCalender";
      const response = await axios.post(
        url,
        { ...updatedCalenderExclude },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );
      if (response.status === 200) {
        await dispatch(fetchUserProfile());
        isConnected && fetchCalendarEvents();
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  const handleTextareaChange = (event, index) => {
    const updatedCalendarEvents = [...calenderEvents];
    updatedCalendarEvents[index].description = event.target.value;
    setCalenderEvents(updatedCalendarEvents);
  };

  const handleSummary = () => {
    setCalenderActiveTab("summary");
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
    // Reset the showConnectAlert state when Snackbar is closed
    setShowConnectAlert(false);
  };

  useEffect(() => {
    if (isConnected == true) {
      updateStatus({ isConnected, "api_call_completed": false });
      fetchCalendarEvents();
    }

    setSummary();
  }, [selectedDate, isConnected]);

  const handleCalenderConnect = async () => {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'toolConnectionRequestedEvent',
      'feature': 'calender',
      'status': 'Requested-Connect'
    });
    
    const googleOAuthURL = `${process.env.REACT_APP_URL}/auth/google/calendar?state=${userProfile?.data[0]?._id}`
    const newWindow = window.open(googleOAuthURL, "_self", 'noopener,noreferrer,resizable');

    if (newWindow) {
      newWindow.focus();
    } else {
      console.error("Popup blocked or couldn't be opened");
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const calenderSuccess = params.get('redi');
    if (calenderSuccess === "calender_success") {
      navigate("/")
      connect(true);
    }
  }, [])

  const handleConnectDisconnect = async () => {
    if (isConnected) {
      connect(false);
    } else {
      setCalenderEvents([]);
      handleCalenderConnect();
    }
  };

  return (
    <>
      <div className="rounded-xl mt-10 min-h-300 shadow border-zinc-300 pb-20 min-h-500 bg-lotion max-md:overflow-x-auto md:overflow-hidden scrollbar-width scrollable-container">
        <div className="mb-2 flex gap-2 justify-between items-center text-center">
          <div></div>
          <div className="flex items-center gap-x-2 ml-16 text-black text-opacity-80 text-xl font-bold font-['DM Sans']">
            <img src={calenderImage} alt="calenderImage" className="h-8 w-8" />
            Calender
          </div>
          <FormControlLabel
            sx={{
              display: 'block',
            }}
            control={
              <SwitchBtn
                checked={isConnected}
                onChange={handleConnectDisconnect}
                name="connect"
                sx={{ m: 1 }}
              />
            }
          />
        </div>
        <div
          className={`flex border h-12 shadow-md buttons border-zinc-300 text-black text-opacity-80 text-xl font-['DM Sans'] bg-lotion rounded-full items-center justify-between mx-auto font-semibold mt-2`}
        >
          <button
            className={`${CalenderActiveTab === "input"
              ? "border rounded-full shadow-xl"
              : ""
              } w-80 h-full`}
            onClick={() => setCalenderActiveTab("input")}
          >
            {" "}
            <span className="flex items-center justify-center gap-x-6">
              {" "}
              <span className="text-2xl font-extralight">
                <RiArrowUpDownFill />
              </span>
              Input{" "}
            </span>{" "}
          </button>
          <button
            className={`${CalenderActiveTab === "summary"
              ? "border rounded-full shadow-xl"
              : ""
              } w-80 h-full`}
            onClick={handleSummary}
          >
            {" "}
            <span className="flex items-center justify-center  gap-x-6">
              {" "}
              <span className="text-2xl font-extralight">
                <ImProfile />
              </span>
              Summary{" "}
            </span>
          </button>
          <button
            className={`${CalenderActiveTab === "exclusions"
              ? "border rounded-full shadow-xl"
              : ""
              } w-80 h-full`}
            onClick={() => setCalenderActiveTab("exclusions")}
          >
            {" "}
            <span className="flex items-center justify-center gap-x-6">
              {" "}
              <VscExclude className="text-2xl" /> Exclusions{" "}
            </span>
          </button>
        </div>
        {CalenderActiveTab === "exclusions" && (
          <div className="flex flex-col p-4 gap-2">
            <div className="flex gap-2">
              <input
                type="checkbox"
                name="limitLength"
                checked={calenderExclude?.limitLength === 500 ? true : false}
                onChange={updateCheckPoint}
                value={500}
              />
              <label htmlFor="">Limit Items to 500 Char</label>
            </div>
            <div className="flex gap-2">
              <input
                type="checkbox"
                name="limitLength"
                checked={calenderExclude?.limitLength === 1000 ? true : false}
                onChange={updateCheckPoint}
                value={1000}
              />
              <label htmlFor="">Limit Items to 1000 Char</label>
            </div>
            <div className="flex gap-2">
              <input
                type="checkbox"
                checked={calenderExclude?.excludeDailyActivity}
                name="excludeDailyActivity"
                id="excludeDailyActivity"
                onChange={updateCheckPoint}
              />
              <label htmlFor="excludeDailyActivity">Exclude Daily Activities Events</label>
            </div>
          </div>
        )}
        {isConnected &&
          CalenderActiveTab === "input" &&
          calenderEvents?.length >= 1 ? (
          loadingEvents ? (
            <div className="flex justify-center items-center min-h-300 gap-4">
              <img src={calenderImage} alt="calenderImage" className="h-8" />
              <p className="mt-3 font-medium">Loading calender events...</p>
            </div>
          ) : (
            <div className="table-container">
              <table className="min-w-full divide-y-2  overflow-auto h-20 rounded-xl divide-gray-200 bg-lotion text-md mt-2 border-b border-gray-200">
                <thead className="ltr:text-left rtl:text-right">
                  <tr>
                    <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                      Event Title
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                      Event Description
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                      Start Time
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                      End Time
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900"></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 ml-20">
                  {calenderEvents?.map((event, index) => (
                    <tr key={index} className="">
                      <td className="flex-wrap pl-6 py-2 font-medium text-gray-900  max-w-full ">
                        {event?.id}
                      </td>
                      <td className="pl-4 py-2 text-gray-700">
                        <div className="w-full min-h-[5vh]  h-20 max-h-[30vh] overflow-hidden max-w-full ">
                          <textarea
                            name=""
                            rows="1"
                            cols="1"
                            id=""
                            value={event.description}
                            onChange={(event) =>
                              handleTextareaChange(event, index)
                            }
                            className="w-[50vw] h-full mt-3   bg-lotion p-2 min-h-[5vh] max-h-[30vh] resize-none "
                          >
                            {event?.description}
                          </textarea>
                        </div>
                      </td>
                      <td className="flex-wrap pl-6 py-2 font-medium text-gray-900  max-w-full ">
                        {convertToIST(event?.startTime.dateTime)}
                      </td>
                      <td className="flex-wrap pl-6 py-2 font-medium text-gray-900  max-w-full ">
                        {convertToIST(event?.endTime.dateTime)}
                      </td>
                      <td>
                        <i
                          className="fa fa-trash"
                          onClick={() => handleDeleteEvents(index)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        ) : CalenderActiveTab === "input" ? (
          <div className="flex justify-center items-center min-h-300 gap-4">
            <img src={calenderImage} alt="calenderImage" className="h-8" />
            {loadingEvents ? (
              <p className="mt-3 font-medium">Loading calender events...</p>
            ) : (
              <p className="mt-3 font-medium">Calender events not found.</p>
            )}
          </div>
        ) : (
          ""
        )}
        {CalenderActiveTab === "summary" && (
          <>
            {loadingSummary ? (
              <div className=" loader-outer z-99999">
                <div className=" loader z-999999"></div>
              </div>
            ) : (
              ""
            )}
            <div>
              {isConnected ? (
                // <p className="w-[60rem] m-auto pt-10 whitespace-pre-wrap">
                <p className="w-[70%] m-auto p-3 whitespace-pre-wrap overflow-auto h-[24rem]">
                  {calenderEvents?.length > 0 ? (
                    <span>{summaryData}</span>
                  ) : (
                    <span></span>
                  )}
                </p>
              ) : (
                showConnectAlert && (
                  <Alert
                    severity="error"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      margin: "16px",
                      zIndex: 9999,
                    }}
                  >
                    Hey! Connect your tool to summarize.
                  </Alert>
                )
              )}
            </div>
          </>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={isConnected ? "success" : "error"}>
          {isConnected
            ? "Hurray! You are connected."
            : "Connect to summarize Calendar Events!"}
        </Alert>
      </Snackbar>
    </>
  );
};
export default CalenderList;
