import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateUserInfo, fetchUserProfile } from "../../../Store/actions/fetchUserProfile";
import FormControlLabel from '@mui/material/FormControlLabel';
import SwitchBtn from "../../../components/SwitchBtn";

const SlackLogin = ({
  user,
  isConnected,
  setIsConnected,
  setSnackbarOpen,
  setSlackEvents,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const slackConfig = {
    clientId: "6198520838690.6195621378453",
    clientSecret: "ead09ed6cc393640d0ee1be157ac82b5",
    redirectUri: process.env.REACT_APP_CLIENT_URL,
    scopes: [
      "channels:history",
      "channels:read",
      "groups:history",
      "mpim:history",
      "im:history",
      "groups:read",
      "im:read",
      "mpim:read",
      "users:read",
      "users:read.email",
      "usergroups:read",
      "users.profile:read",
    ],
    state: "SlackAuthToken"
  };
  const connect = async (isConnected) => {
    try {
      const url = process.env.REACT_APP_URL + "/slack/slackconnect";
      const response = await axios.put(
        url,
        { isConnect: isConnected },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );

      if (response.status == 200 && isConnected) {
        setIsConnected(true);
        setSnackbarOpen(true);
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionSuccessEvent',
          'feature': 'slack',
          'status': 'Connected',
        });
      } else if (response.status === 200 && !isConnected) {
        setIsConnected(false);
        setSnackbarOpen(true);
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionRemoveEvent',
          'feature': 'slack',
          'status': 'Removed',
        });
      } else {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionFailedEvent',
          'feature': 'slack',
          'status': 'Failed'
        });
      }
    } catch (error) {
      // console.error("Fetch error:", error);
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'toolConnectionFailedEvent',
        'feature': 'slack',
        'status': 'Failed'
      });
    }
  };


  const handleConnectDisconnect = async () => {
    if (isConnected) {
      setSlackEvents([]);
      connect(false);
    } else {
      setSlackEvents([]);
      handleSlackConnect();
    }
  };


  const handleSlackConnect = () => {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'toolConnectionRequestedEvent',
      'feature': 'slack',
      'status': 'Requested-Connect'
    });

    const slackOAuthUrl = `https://slack.com/oauth/authorize?client_id=${slackConfig.clientId
      }&scope=${slackConfig.scopes.join(
        " "
      )}&state=${slackConfig.state}&redirect_uri=${slackConfig.redirectUri
      }&response_type=code`;

    window.location.href = slackOAuthUrl;
  }

  const saveSlackAccessToken = async (accessToken, slackUserId) => {
    const url = process.env.REACT_APP_URL + "/slack/save_slack_credentials";

    try {
      const response = await axios.post(
        url,
        { accessToken, slackUserId },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );

      if (response.status === 200) {
        connect(true);
      }
    } catch (error) {
      console.error("Error saving Slack access token:", error);
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'toolConnectionFailedEvent',
        'feature': 'slack',
        'status': 'Failed'
      });
    }
  };

  useEffect(() => {
    const handleOAuthCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const state = urlParams.get("state");


      if (code && state == slackConfig.state) {
        try {
          const qs = require("qs");
          let data = qs.stringify({
            client_id: slackConfig.clientId,
            client_secret: slackConfig.clientSecret,
            code: code,
            redirect_uri: slackConfig.redirectUri,
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://slack.com/api/oauth.access",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: data,
          };

          const tokenResponse = await axios.request(config);
          const tokenData = tokenResponse.data;

          if (tokenData.ok) {
            const accessToken = tokenData.access_token;
            const slackUserId = tokenData.user_id;
            await saveSlackAccessToken(accessToken, slackUserId);
            navigate("/");
          } else {
            console.error(
              "Error exchanging code for access token:",
              tokenData.error
            );
          }
        } catch (error) {
          console.error("Error exchanging code for access token:", error);
        }
      }
    };

    handleOAuthCallback();
  }, []);

  return (
    <div>
      <FormControlLabel
        sx={{
          display: 'block',
        }}
        control={
          <SwitchBtn
            checked={isConnected}
            onChange={handleConnectDisconnect}
            name="connect"
            sx={{ m: 1 }}
          />
        }
      />
    </div>
  );
};

export default SlackLogin;
