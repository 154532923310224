import React from 'react';
import { Box } from '@mui/material';

function Circle({ number }) {
  const circleStyle = {
    width: 50,
    height: 50,
    borderRadius: '50%',
    backgroundColor: '#f9f9f9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 'bold',    
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)'    
  };

  return (
    <div style={circleStyle}>
      {number}
    </div>
  );
}

function Line() {
  const lineStyle = {
    width: 150,
    height: 2,
    backgroundColor: '#DDDDDD',
    marginTop: 25

  };

  return <div style={lineStyle}></div>;
}

function CircleDiagram() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        padding: "15px",
        position: 'relative',
      }}
    >
      <Circle number={1} />
      <Line />
      <Circle number={2} />
      <Line />
      <Circle number={3} />
    </Box>
  );
}

export default CircleDiagram;