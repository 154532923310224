import React, { useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
const PaymentSuccess = () => {
  useEffect(() => {
    <Navigate to="/" />;
  }, []);
  return (
    <div className="flex flex-col justify-center items-center">
      <p>Thank You.</p>
      <Link
        to="/"
        className="text-semibold font-xl rounded-xl  py-1 bg-blue-500 cursor-pointer text-white px-4"
      >
        Go Back to Home.
      </Link>
    </div>
  );
};

export default PaymentSuccess;
