import React, { useState, useEffect } from "react";
import { BsCheckLg } from "react-icons/bs";
import { IoRemoveOutline } from "react-icons/io5";
import Header from "../../components/Header";
import Feedback from "../../components/Feedback";
import weReportLogo from "../../assets/logo/weReporWork.png";
import Checkout from "./Checkout";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useMount } from "react-use";
import { fetchUserProfile } from "../../Store/actions/fetchUserProfile";
import { fetchUser } from "../../Store/actions/fetchUser";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const PricingPage = () => {

    var isMobileScreen = 0;
    if (window.innerWidth < 450) {
        isMobileScreen = 1;
    }

    const [amount, setAmount] = useState(null);
    const [isOn, setIsOn] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isOnTeams, setIsOnTeams] = useState(false);
    const { user } = useSelector((state) => state.authLogin);
    const { userProfile, loading } = useSelector((state) => state.userProfile);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleSwitch = () => {
        setIsOn(!isOn);
    };

    const toggleSwitchTeams = () => {
        setIsOnTeams(!isOnTeams);
    };

    const plans = {
        forever: [
            {
                isEnable: 1,
                label: "Core Features",
            },
            {
                isEnable: 1,
                label: "$1 OpenAI Credits",
            },
            {
                isEnable: 1,
                label: "Unlimited Summaries",
            },
            {
                isEnable: 0,
                label: "Access via Google Profile",
            },
            {
                isEnable: 0,
                label: "No Support",
            },
            {
                isEnable: 0,
                label: "5 Templates",
            },
            {
                isEnable: 0,
                label: "Fixed Templates",
            },
            {
                isEnable: 0,
                label: "1 User",
            },
            {
                isEnable: 0,
                label: "No Branding",
            },
            {
                isEnable: 0,
                label: "No Saved Summaries",
            },
            {
                isEnable: 0,
                label: "Manual Email",
            },
            {
                isEnable: 0,
                label: "Manual Summary",
            },
            {
                isEnable: 0,
                label: "Ads (Coming Soon)",
            },
            {
                isEnable: 0,
                label: "Basic Integrations",
            },
            {
                isEnable: 0,
                label: "Top-up Charge (Coming Soon)",
            },
        ],
        individual: [
            {
                isEnable: 1,
                label: "Core Features",
            },
            {
                isEnable: 1,
                label: "$1 OpenAI Credits",
            },
            {
                isEnable: 1,
                label: "Unlimited Summaries",
            },
            {
                isEnable: 1,
                label: "Access via Google Profile",
            },
            {
                isEnable: 1,
                label: "Chat, Email Support",
            },
            {
                isEnable: 1,
                label: "Unlimited Templates",
            },
            {
                isEnable: 1,
                label: "Editable Templates",
            },
            {
                isEnable: 0,
                label: "1 User",
            },
            {
                isEnable: 0,
                label: "No Branding",
            },
            {
                isEnable: 1,
                label: "Save Summaries",
            },
            {
                isEnable: 1,
                label: "Email Alerts (Coming Soon)",
            },
            {
                isEnable: 1,
                label: "Automation (Coming Soon)",
            },
            {
                isEnable: 1,
                label: "No Advertisements",
            },
            {
                isEnable: 1,
                label: "All Integrations",
            },
            {
                isEnable: 1,
                label: "No Credit Top-up Charges",
            },
        ],
        teams: [
            {
                isEnable: 1,
                label: "Core Features",
            },
            {
                isEnable: 1,
                label: "$9 OpenAI Credits",
            },
            {
                isEnable: 1,
                label: "Unlimited Summaries",
            },
            {
                isEnable: 1,
                label: "Access via Work Gmail",
            },
            {
                isEnable: 1,
                label: "Chat, Email, Call Support",
            },
            {
                isEnable: 1,
                label: "Unlimited Templates",
            },
            {
                isEnable: 1,
                label: "Editable Templates",
            },
            {
                isEnable: 1,
                label: "Unlimited Users",
            },
            {
                isEnable: 1,
                label: "Custom Branding",
            },
            {
                isEnable: 1,
                label: "Save Summaries",
            },
            {
                isEnable: 1,
                label: "Email Alerts (Coming Soon)",
            },
            {
                isEnable: 1,
                label: "Automation (Coming Soon)",
            },
            {
                isEnable: 1,
                label: "No Advertisements",
            },
            {
                isEnable: 1,
                label: "All Integrations",
            },
            {
                isEnable: 1,
                label: "No Credit Top-up Charges",
            },
        ],
    };

    const validatePositiveNumber = (value) => {
        const regex = /^[+]?\d*\.?\d{0,2}$/;
        return regex.test(value);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };


    const handleChange = (e) => {
        const value = e.target.value;
        if (validatePositiveNumber(value) && value >= 0) {
            setAmount(value);
            setSnackbarOpen(false);
            setErrorMessage("")
        } else {
            setSnackbarOpen(true);
            setErrorMessage("Please enter a positive number with up to two decimal places.")
        }
    };

    // Credit Buy
    const _id = user?.data[0]?._id;
    const handleCheckout = async () => {
        if (amount === "") {
            setSnackbarOpen(true);
            setErrorMessage("Please enter a positive number with up to two decimal places.")
            return;
        }
        if (amount == 0) {
            setSnackbarOpen(true);
            setErrorMessage("Please enter a positive number more than 0")
            return;
        }
        const name = "CREDITS";
        const url = process.env.REACT_APP_URL + "/pay/payment/";
        await axios
            .post(
                url,
                { amount, name, userId: _id },
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${user["connect.sid"]}`,
                    },
                }
            )
            .then((res) => {
                if (res?.data.url) {
                    window.location.href = res?.data.url;
                }
            })
            .catch((err) => {
                // console.log(err)
            });
    };

    // useMount(() => {
    //     // dispatch(fetchUser())
    //     dispatch(fetchUserProfile());
    // });

    useEffect(()=>{
        dispatch(fetchUserProfile())
    },[]);

    return (
        <>
            {/* Logo Branding  */}
            {loading && (
                <div className="loader-outer z-999">
                    <div className="loader z-999"></div>
                </div>
            )}

            <div
                className="flex justify-center items-center gap-3.5 mt-3.5  cursor-pointer"
                onClick={() => {
                    if (user?.data[0]?.role === "admin") {
                        navigate("/admin");
                    } else {
                        navigate("/");
                    }
                }}
            >
                <img src={weReportLogo} alt="logo" className="h-10 w-10" />
                <span className="md:text-3xl text-xl font-bold">
                    We Report Work
                </span>
            </div>
            <Link
                to="/"
                className="d-flex align-items-center text-[#3d66fb] font-fredoka font-semibold relative top-9 no-underline mb-4"
            >
                <span>
                    <svg
                        style={{ width: "20px" }}
                        className="d-flex align-items-center"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="ArrowBackSharpIcon"
                    >
                        <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z"></path>
                    </svg>
                </span>
                <span className="text-[#3d66fb]">Back</span>
            </Link>
            <Header user={user} />

            <div className="border-l-8 flex flex-col gap-0 border-yellow-500 pl-5">
                <span className="font-semibold text-xl md:text-2xl  ">
                    Your Plan
                </span>
                <span className=" font-medium ">Change your plan</span>
            </div>
            <div className={`flex bg-whte flex-col md:flex-row  pb-20 gap-1 ${isMobileScreen ? 'mt-4' : 'mt-24'}`}>
                {/* Free Tier  */}
                <div className="flex flex-col  w-full mb-24 bg-white rounded-l-lg md:rounded-r-none rounded-r-lg  ">
                    <div className="flex flex-col gap-2  w-full text-center p-10  ">
                        <p className="uppercase font-semibold text-xl">
                            Free tier
                        </p>
                        <p className="text-gray-500">
                            {" "}
                            <span className="text-xl font-semibold text-black">
                                $0
                            </span>{" "}
                            forever{" "}
                        </p>
                        {/* Features */}

                        <div className="flex flex-col md:items-start items-center mb-10">
                            {plans.forever.map((feature, index) => (
                                <div className="flex gap-2" key={index}>
                                    {feature.isEnable ? (
                                        <BsCheckLg className="text-blue-500" />
                                    ) : (
                                        <span className="pt-1">
                                            <IoRemoveOutline className="text-blue-500" />
                                        </span>
                                    )}
                                    <p className="text-gray-600">
                                        {feature.label}
                                    </p>
                                </div>
                            ))}
                        </div>

                        {userProfile?.data[0]?.currentPlan[0]?.name ===
                            "FREE TIER" && (
                                <button className="text-blue-600 border-2 border-blue-600 rounded-xl mx-auto md:w-7/12 w-6/12  shadow-md py-1 ">
                                    Current
                                </button>
                                // ) : (
                                //     <>
                                //         <button
                                //             className="text-white bg-blue-600 border-2 border-blue-600 rounded-xl mx-auto md:w-7/12 w-6/12 shadow-md py-1 "
                                //             disabled={
                                //                 userProfile?.data[0]?.currentPlan[0]
                                //                     ?.name !== "FREE TIER"
                                //             }
                                //             style={
                                //                 userProfile?.data[0]?.currentPlan[0]
                                //                     ?.name !== "FREE TIER"
                                //                     ? {
                                //                           opacity: "0.5",
                                //                           cursor: "not-allowed",
                                //                       }
                                //                     : {}
                                //             }
                                //         >
                                //             Switch to Free Tier
                                //         </button>
                                //     </>
                            )}
                    </div>
                </div>

                {/* Individual  */}
                <div className="flex flex-col w-full -mt-20 md:shadow-xl  rounded-xl overflow-hidden mb-10 ">
                    <p className="bg-blue-600 text-white font-semibold py-4 text-center mt-2 rounded-t-xl">
                        Most Popular
                    </p>
                    <div className="flex flex-col gap-2  text-center p-10 bg-white -mt-4 ">
                        <p className="uppercase font-semibold text-xl">
                            Individual
                        </p>
                        <p className="text-gray-500">
                            {" "}
                            <span className="text-xl font-semibold text-black">
                                {isOn ? "$99" : "$9"}
                            </span>{" "}
                            {isOn ? "" : "/month"}
                        </p>
                        {/* Features */}

                        <div className="flex flex-col md:items-start items-center">
                            {plans.individual.map((feature, index) => (
                                <div key={index} className="flex gap-2">
                                    {feature.isEnable ? (
                                        <BsCheckLg className="text-blue-500" />
                                    ) : (
                                        <span className="pt-1">
                                            <IoRemoveOutline className="text-blue-500" />
                                        </span>
                                    )}
                                    <p className="text-gray-600">
                                        {feature.label}
                                    </p>
                                </div>
                            ))}
                        </div>

                        {/* Toggle btn 
            
            */}
                        <label className="flex items-center cursor-pointer justify-center my-5 gap-3">
                            <p className={`mt-3 ${!isOn && "font-semibold"}`}>
                                {" "}
                                Pay <br /> Monthly
                            </p>
                            <div className="relative">
                                <input
                                    type="checkbox"
                                    className="hidden"
                                    checked={isOn}
                                    onChange={toggleSwitch}
                                />
                                <div
                                    className={`toggle__line w-10 h-4  rounded-full shadow-inner transition-transform ${isOn ? "bg-blue-500" : "bg-gray-400"
                                        }`}
                                ></div>
                                <div
                                    className={`toggle__dot absolute w-6 h-6 bg-blue-600 rounded-full shadow -left-1 -top-1 transition-transform ${isOn
                                        ? "translate-x-6 bg-blue-500"
                                        : "bg-gray-500"
                                        }`}
                                ></div>
                            </div>
                            <p className={`mt-3 ${isOn && "font-semibold"}`}>
                                Pay <br /> Forever
                            </p>
                        </label>

                        {userProfile?.data[0]?.currentPlan[0]?.name === "Individual (Monthly)" ? (
                            isOn ? (
                                <Checkout
                                    value={"Buy Now"}
                                    amount={99}
                                    name={"INDIVIDUAL-YEAR"}
                                />
                            ) : (
                                <button className="text-blue-600 border-2 border-blue-600 rounded-xl mx-auto md:w-7/12 w-6/12 shadow-md py-1">
                                    Current
                                </button>
                            )
                        ) : userProfile?.data[0]?.currentPlan[0]?.name === "Individual (Forever)" ? (
                            <button className="text-blue-600 border-2 border-blue-600 rounded-xl mx-auto md:w-7/12 w-6/12 shadow-md py-1">
                                Current
                            </button>
                        ) : (
                            <>
                                {isOn ? (
                                    <Checkout
                                        value={"Buy Now"}
                                        amount={99}
                                        name={"INDIVIDUAL-YEAR"}
                                    />
                                ) : (
                                    <Checkout
                                        value={"Buy Now"}
                                        amount={9}
                                        name={"INDIVIDUAL-MONTH"}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>

                {/* Teams Plan  */}

                <div className="flex flex-col w-full bg-white md:rounded-r-lg  md:rounded-l-none rounded-l-lg md:mb-24 ">
                    <div className="flex flex-col gap-2 w-full text-center p-10 ">
                        <p className="uppercase font-semibold text-xl">Teams</p>
                        <p className="text-gray-500">
                            {" "}
                            <span className="text-xl font-semibold text-black">
                                {isOnTeams ? '$499' : '$49'}
                            </span>{" "}
                            {isOnTeams ? '' : '/month'}
                        </p>
                        {/* Features */}

                        <div className="flex flex-col md:items-start items-center">
                            {plans.teams.map((feature, index) => (
                                <div key={index} className="flex gap-2">
                                    {feature.isEnable ? (
                                        <BsCheckLg className="text-blue-500" />
                                    ) : (
                                        <span className="pt-1">
                                            <IoRemoveOutline className="text-blue-500" />
                                        </span>
                                    )}
                                    <p className="text-gray-600">
                                        {feature.label}
                                    </p>
                                </div>
                            ))}
                        </div>
                        {userProfile?.data[0]?.currentPlan[0]?.name ===
                            "Teams (Forever)" || userProfile?.data[0]?.currentPlan[0]?.name ===
                            "Teams (Monthly)" ? (
                            <button className="text-blue-600 border-2 border-blue-600 rounded-xl mx-auto md:w-7/12 w-6/12  shadow-md py-1 ">
                                Current
                            </button>
                        ) : (
                            <>
                                <label className="flex items-center cursor-pointer justify-center my-5 gap-3">
                                    <p
                                        className={`mt-3 ${!isOnTeams && "font-semibold"
                                            }`}
                                    >
                                        {" "}
                                        Pay <br /> Monthly
                                    </p>
                                    <div className="relative">
                                        <input
                                            type="checkbox"
                                            className="hidden"
                                            checked={isOnTeams}
                                            onChange={toggleSwitchTeams}
                                        />
                                        <div
                                            className={`toggle__line w-10 h-4  rounded-full shadow-inner transition-transform ${isOnTeams
                                                ? "bg-blue-500"
                                                : "bg-gray-400"
                                                }`}
                                        ></div>
                                        <div
                                            className={`toggle__dot absolute w-6 h-6 bg-blue-600 rounded-full shadow -left-1 -top-1 transition-transform ${isOnTeams
                                                ? "translate-x-6 bg-blue-500"
                                                : "bg-gray-500"
                                                }`}
                                        ></div>
                                    </div>
                                    <p
                                        className={`mt-3 ${isOnTeams && "font-semibold"
                                            }`}
                                    >
                                        Pay <br /> Forever
                                    </p>
                                </label>
                                <a
                                    href="https://docs.google.com/forms/d/e/1FAIpQLSexumSNNtGpcGNGdyuVRZ-H-f4imko0HZm_XUQ2jglYnEM6Dg/viewform"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-white bg-blue-600 border-2 border-blue-600 rounded-xl mx-auto md:w-7/12 w-6/12 shadow-md py-1  no-underline"
                                >
                                    Contact Us
                                </a>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* // Extra Credit  */}

            <div className="border-l-4 border-yellow-500 pl-4">
                <p className="font-medium text-lg">Purchase Credits</p>
            </div>

            <div className="flex justify-around bg-white p-3 items-center rounded-xl shadow-xl mb-10">
                <div className="text-center flex flex-col">
                    <span>Current Credits</span>
                    <span className="font-bold text-3xl">
                        ${userProfile?.data[0]?.token[0]?.availableCredits}
                    </span>
                </div>
                <div className="flex flex-col items-center">
                    <span className="ml-4">Add Credit</span>
                    <div className="flex font-bold  text-3xl gap-2">
                        <span>$</span>
                        <input
                            className="font-bold text-3xl ouline-none bg-transparent border-b-4  text-center border-blue-500 w-24 "
                            onChange={handleChange}
                            type="number"
                            value={amount}
                            step=".01"
                            min="0"
                        />
                    </div>
                </div>
                <div className="">
                    <button
                        className={`text-white bg-blue-700 border-2 border-blue-700 rounded-xl mx-auto shadow-md ${isMobileScreen ? 'p-2' : 'py-1.5 px-5'}`}
                        onClick={handleCheckout}
                    >
                        Pay Now
                    </button>
                </div>
            </div>

            {/* Invite  */}
            {/* <div className="flex justify-around bg-white p-3 items-center rounded-xl shadow-xl gap-10 mb-10 min-h-24 max-h-24">
        <p className="text-2xl mt-1">
          Get <span className="font-semibold text-3xl">$5</span> by inviting{" "}
          <span className="font-semibold text-3xl">5</span> friends!
        </p>
        <div className="">
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg shadow-md h-10 w-full  md:px-10 px-4">
            Invite
          </button>
        </div>
      </div> */}
            <Feedback />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default PricingPage;
