import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import ReactPaginate from "react-paginate";
import { FaCheck } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { MdOutlineDeleteForever } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "../../components/datepicker";
import moment from "moment";
import AddCreditForm from "./AddCreditForm";
import AddManagerForm from "./AddManagerForm";
import EditManagerForm from "./EditManager";
import { logout } from "../../Store/reducers/authLoginReducer";
import weReportLogo from "../../assets/logo/weReporWork.png";
import coinImage from "../../assets/coin.png";
import userImage from "../../assets/userGolden.png";

const AdminPage = () => {
  // State Variables for AdminPage Component
  const [admin, setAdmin] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [manager, setManager] = useState(null);
  // list filtering
  const [customerFilter, setCustomerFilter] = useState("");
  const [managerFilter, setManagerFilter] = useState("");
  // Pagination for Customers and Manager
  const [startCutomerItem, setStartCustomerItem] = useState(0);
  const [startManagerItem, setStartManagerItem] = useState(0);
  const [currentManagerPage, setCurrentManagerPage] = useState([]);
  const [managerPageCount, setManagerPageCount] = useState(0);

  const navigate = useNavigate();
  // Date states
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedDateType, setSelectedDateType] = useState(1); // 1 - Today, 2 - Yesterday, 3 - Custom
  const [startDate, setStartDate] = useState(new Date());
  const [IsLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState("");
  const [addCreditComp, setAddCreditComp] = useState(false);
  const [managerForm, setManagerForm] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmaionMessage, setConfirmationMessage] = useState("Successful");
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState("Fill all fields");
  const dispatch = useDispatch();
  // const { setIsLoggedIn, user, setUser } = useAuth();
  const { user } = useSelector((state) => state.authLogin);
  const itemsPerPage = 10;
  const [filteredCustomers, setFilteredCustomers] = useState(null);
  const [currentCustomerPage, setCurrentCustomerPage] = useState([]);
  const [customerPageCount, setCustomerPageCount] = useState(0);

  // ***************************
  // *** Customer  Pagination ***
  // ***************************
  useEffect(() => {
    if (customers) {
      const filteredData = customers.filter((customer) => {
        return (
          customerFilter.trim() === "" ||
          customer.name
            .toLowerCase()
            .includes(customerFilter.trim().toLowerCase())
        );
      });
      setFilteredCustomers(filteredData);
      setStartCustomerItem(0);
    }
  }, [customerFilter, customers]);

  useEffect(() => {
    if (filteredCustomers) {
      const endItem = startCutomerItem + itemsPerPage;
      setCurrentCustomerPage(
        filteredCustomers?.slice(startCutomerItem, endItem)
      );
      setCustomerPageCount(Math.ceil(filteredCustomers?.length / itemsPerPage));
    }
  }, [filteredCustomers, startCutomerItem, itemsPerPage]);

  const handleCustomerPage = (e) => {
    const newPage = e.selected * itemsPerPage;
    setStartCustomerItem(newPage);
  };

  // ***************************
  // *** Manager Pagination ***
  // ***************************
  useEffect(() => {
    if (manager) {
      const filteredData = manager.filter((manager) => {
        return (
          managerFilter.trim() === "" ||
          manager.name
            .toLowerCase()
            .includes(managerFilter.trim().toLowerCase())
        );
      });
      setCurrentManagerPage(
        filteredData.slice(startManagerItem, startManagerItem + itemsPerPage)
      );
      setManagerPageCount(Math.ceil(filteredData.length / itemsPerPage));
    }
  }, [itemsPerPage, startManagerItem, manager, managerFilter]);

  const handleManagerPage = (e) => {
    const newPage = e.selected * itemsPerPage;
    setStartManagerItem(newPage);
  };

  // ***************************
  // *** Date Manipulation ***
  // ***************************
  const setYesterdayDate = () => {
    setSelectedDate(moment().subtract(1, "days").format("YYYY-MM-DD"));
    setSelectedDateType(2);
  };
  const setTodayDate = () => {
    setSelectedDate(moment().format("YYYY-MM-DD"));
    setSelectedDateType(1);
  };

  const setCustomDate = (date) => {
    setSelectedDate(moment(date).format("YYYY-MM-DD"));
    setStartDate(date);
    setSelectedDateType(3);
  };

  // ***************************
  // *** Log out ***
  // ***************************
  const handleLogout = async () => {
    const url = process.env.REACT_APP_URL + "/auth/logout";

    setIsLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${user["connect.sid"]}`,
        },
      });

      if (response.status == 200) {
        dispatch(logout());
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("user");
        setIsLoading(false);
        navigate("/login");
      }
    } catch (error) {
      // console.log(error);
    }
  };

  // ***************************
  // *** Fetch Customers ***
  // ***************************
  const fetchCustomers = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_URL + "/admin/users",
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );

      if (response.status == 200) { 
        setCustomers(response.data.data);
        setFilteredCustomers(response.data.data);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  // ***************************
  // *** Fetch managers ***
  // ***************************
  const fetchManagers = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_URL + "/admin/getManagers",
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );
      if (response.status == 200) { 
        setManager(response.data.data);
      }
      // console.log(response.data.data);
    } catch (error) {
      // console.log(error);
    }
  };

  // ***************************
  // *** Fetch revenue data ***
  // ***************************
  const fetchRevenue = async () => {
    const url =
      process.env.REACT_APP_URL +
      "/admin/revenue?date=" +
      selectedDate +
      "&timezoneOffset=" +
      new Date().getTimezoneOffset();
    setIsLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${user["connect.sid"]}`,
        },
      });

      if (response.status == 200) {  
        setAdmin(response.data);
      }
    } catch (error) {
      // console.log(error);
    } finally{
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRevenue();
    fetchCustomers();
    fetchManagers();
  }, [selectedDate]);

  const [editManager, setEditManager] = useState(null);
  const [editFormManagerView, setEditFormManagerView] = useState(false);
  const [logoEditMode, setLogoEditMode] = useState(false);
  const [updatedLogoURL, setUpdatedLogoURL] = useState("");
  const [activeManagerIndex, setActiveManagerIndex] = useState(null);
// console.log('This is manager data',manager)
  const handleLogoEdit = async (manager, index, submit = false) => {
    setLogoEditMode(true);
    // Additional logic to handle the logo edit
    setActiveManagerIndex(index);
    if (submit) {
      const url = process.env.REACT_APP_URL + "/admin/editManager";
      try {
        await axios.put(
          url,
          {
            ...manager,
            logo: updatedLogoURL, 
          },
          {
            headers: {
              Authorization: `Bearer ${user["connect.sid"]}`,
            },
          }
        );
        fetchManagers();
        setConfirmationMessage("Manager edited successfully");
        setShowConfirmation(true);
        editManagerFormView();
      } catch (error) {
        // console.log(error);
      }
    } else {
      setUpdatedLogoURL(manager?.logo);
    }
  };

  // ***************************
  // *** DELETE MANAGER ***
  // ***************************
  const deleteManager = async (_id) => {
    setIsLoading(true);
    const url = process.env.REACT_APP_URL + "/admin/deleteManager";
    try {
      await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${user["connect.sid"]}`,
        },
        data: {
          _id: _id,
        },
      });
      setConfirmationMessage("Logo updated successfully");
      setShowConfirmation(true);
    } catch (error) {
      // console.log(error);
    } finally {
      fetchManagers();
      setIsLoading(false);
      setLogoEditMode(false);
    }
  };

  const editManagerForm = (manager) => {
    setEditManager(manager);
    setEditFormManagerView(true);
  };

  const editManagerFormView = (editFormManagerView) => {
    setEditFormManagerView(editFormManagerView);
  };

  const handleUserClick = (customer) => {
    setCustomer(customer);
    setAddCreditComp(!addCreditComp);
  };

  const setFormView = (addCreditComp) => {
    setAddCreditComp(addCreditComp);
  };

  const setManagerFormView = (managerForm) => {
    setManagerForm(managerForm);
  };

  const filterCustomers = (filterText) => {
    // console.log("filtering customers with:", filterText);
    const filteredData = customers.filter((customer) => {
      return (
        filterText.trim() === "" ||
        customer.name.toLowerCase().includes(filterText.trim().toLowerCase())
      );
    });
    setFilteredCustomers(filteredData);
    setStartCustomerItem(0);
  };

  const filterManagers = (filterText) => {
    const filteredData = manager.filter((manager) => {
      return (
        filterText.trim() === "" ||
        manager.name.toLowerCase().includes(filterText.trim().toLowerCase())
      );
    });
    setCurrentManagerPage(filteredData);
  };

const handleCreditAdded = async (credits, id) => {
  // Call the API endpoint to fetch updated customer data
  const apiUrl = process.env.REACT_APP_URL + "/admin/getCustomers";
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${user["connect.sid"]}`,
      },
    });
    const fetchedCustomerData = response.data.customers;
    // console.log('this is customer datda', fetchedCustomerData);
    const updatedCustomer = { ...fetchedCustomerData, credits: credits };
    setCustomer(updatedCustomer);

    // Update the state with the updated customer data
    setCustomers((prevCustomers) =>
      prevCustomers.map((prevCustomer) =>
        prevCustomer.userId === updatedCustomer.userId
          ? { ...prevCustomer, credits: updatedCustomer.credits }
          : prevCustomer
      )
    );

    const updatedManagers = manager.map((m) =>
      m.userId === updatedCustomer.userId
        ? { ...m, credits: m.credits + credits }
        : m
    );

    setManager(updatedManagers);

    // console.log("this is the updated customer", updatedCustomer);
  } catch (error) {
    console.error("Error fetching updated customer data: ", error);
  }
};


  return (
    <div className="">
      <Snackbar
        open={showConfirmation}
        autoHideDuration={4000}
        onClose={() => setShowConfirmation(false)}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit" // Update the color prop to "inherit"
            onClick={() => setShowConfirmation(false)}
          >
            <CloseIcon fontSize="small" color="white" />
          </IconButton>
        }
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={() => setShowConfirmation(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {confirmaionMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={showWarning}
        autoHideDuration={4000}
        onClose={() => setShowWarning(false)}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit" // Update the color prop to "inherit"
            onClick={() => setShowWarning(false)}
          >
            <CloseIcon fontSize="small" color="white" />
          </IconButton>
        }
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={() => setShowWarning(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {warningMessage}
        </Alert>
      </Snackbar>
      {/* Logo Branding  */}
      {IsLoading ? (
        <div className="loader-outer">
          <div className="loader z-20"></div>
        </div>
      ) : (
        ""
      )}

      {addCreditComp && (
        <AddCreditForm
  setFormView={setFormView}
  customer={customer}
  user={user}
  setShowConfirmation={setShowConfirmation}
  setConfirmationMessage={setConfirmationMessage}
  handleCreditAdded={handleCreditAdded} // Pass the function
/>
      )}
      {managerForm && (
        <AddManagerForm
          setManagerFormView={setManagerFormView}
          user={user}
          editManagerFormView={editManagerFormView}
          fetchManagers={fetchManagers}
          setShowConfirmation={setShowConfirmation}
          setConfirmationMessage={setConfirmationMessage}
          setShowWarning={setShowWarning}
          setWarningMessage={setWarningMessage}
        />
      )}
      {editFormManagerView && (
        <EditManagerForm
          user={user}
          editManager={editManager}
          editManagerFormView={editManagerFormView}
          setShowConfirmation={setShowConfirmation}
          setConfirmationMessage={setConfirmationMessage}
          fetchManagers={fetchManagers}
        />
      )}
      <div
        className="flex justify-center items-center gap-3.5 mt-3.5 mb-10 cursor-pointer"
        onClick={() => {
          if (user?.data[0]?.role === "admin") {
            navigate("/admin");
          } else {
            navigate("/");
          }
        }}
      >
        <img src={weReportLogo} alt="logo" className="h-10 w-10" />
        <span className="md:text-3xl text-xl font-bold font-fredoka">
          We Report Work
        </span>
      </div>
      <button
        className="text-[#3D66FB] underline cursor-pointer mb-2 relative float-right"
        onClick={handleLogout}
      >
        Sign out
      </button>
      <div className="flex flex-col justify-start w-full min-h-screen pb-10">
        <h1 className="d-flex justify-content-between">
          <span className="font-semibold">Welcome, {user?.displayName}</span>
        </h1>

        <div className="flex justify-between items-center">
          <div className="border-l-4 border-yellow-500 pl-4 h-8 flex items-center my-4 ">
            <span className="font-medium">Take a look at your business </span>
          </div>
          <div className="d-flex align-items-center mt-lg-0 ">
            <button
              onClick={setYesterdayDate}
              className={`btn py-0 me-2 text-[#3D66FB]  btn-sm flex inline-block overflow-hidden whitespace-nowrap ${
                selectedDateType === 2 ? "btn-warning" : "text-primary" 
              }`}
              style={{ height: "30px" }}
            >
              {selectedDateType === 2 ? (
                <FaCheck className="inline" style={{ marginRight: "5px" }} />
              ) : (
                ""
              )}
              Yesterday
            </button>
            <button
              onClick={setTodayDate}
              className={`btn text-[#3D66FB] inline-block overflow-hidden whitespace-nowrap ${
                selectedDateType === 1 ? "btn-warning" : "text-primary"
              } py-0 btn-sm`}
              style={{ height: "30px" }}
            >
              {selectedDateType === 1 ? (
                <FaCheck className="inline" style={{ marginRight: "5px" }} />
              ) : (
                ""
              )}
              Today
            </button>
            <button
              className="btn-transparent btn py-0"
              style={{ height: "45px" }}
            >
              <DatePicker
                selected={startDate}
                onChange={(date) => setCustomDate(date)}
                customInput={<CustomInput />}
                placeholderText="Select Date"
                maxDate={new Date()}
              />
            </button>
          </div>
        </div>
        {/* user balances */}
        <span className="flex justify-between w-full flex-wrap gap-2 mx-auto">
          {/* Revenue  */}
          <div className="flex flex-col items-center border-[#3D66FB] border-2 px-3 py-1 rounded-lg w-40">
            <span
              style={{ textDecoration: "underline" }}
              className="font-semibold uppercase"
            >
              Overall
            </span>
            <span className="flex items-center gap-2">
              <img src={coinImage} alt="" className="md:h-8 md:w-8 h-6 w-6" />
              <span className="font-semibold text-2xl">
                ${admin?.overallRevenue}
              </span>
            </span>
          </div>

          {/* Free */}

          <div className="flex flex-col items-center border-[#3D66FB] border-2 px-3 py-1 rounded-lg w-40">
            <span className="font-semibold uppercase">Free</span>
            <span className="flex items-center gap-2">
              <img src={userImage} alt="" className="md:h-8 md:w-8 h-6 w-6" />
              <span className="font-semibold text-2xl">{admin?.freeuser}</span>
            </span>
          </div>

          {/* Individual */}
          <div className="flex flex-col items-center border-[#3D66FB] border-2 px-3 py-1 rounded-lg w-40">
            <span className="font-semibold uppercase">Individual</span>
            <span className="flex items-center gap-2">
              <img src={userImage} alt="" className="md:h-8 md:w-8 h-6 w-6" />
              <span className="font-semibold text-2xl">
                {admin?.individualMonthlyuser + admin?.individualForeveruser}
              </span>
            </span>
          </div>

          {/* Teams */}
          <div className="flex flex-col items-center border-[#3D66FB] border-2 px-3 py-1 rounded-lg w-40">
            <span className="font-semibold uppercase">teams</span>
            <span className="flex items-center gap-2">
              <img src={userImage} alt="" className="md:h-8 md:w-8 h-6 w-6" />
              <span className="font-semibold text-2xl">{admin?.teamsMontlyUser + admin?.teamsForeverUser}</span>
            </span>
          </div>
        </span>

        <div className="flex justify-between mt-10">
          <div className="border-l-4 border-yellow-500 pl-4 h-8 my-4 flex items-center w-full">
            <span className="font-medium">All Customers</span>
          </div>

          {/* Search  */}

          <div className="w-full mb-2 mt-10">
            <input
              type="text"
              placeholder="Search here..."
              className="outline-none  border-b-4 bg-transparent text-black border-[#3D66FB] w-32   float-right"
              onChange={(e) => {
                setCustomerFilter(e.target.value);
                // console.log("text changes");
                filterCustomers(e.target.value);
              }}
            />
          </div>
        </div>

        <table className="bg-white rounded-2xl ">
          <thead className="bg-yellow-500  overflow-hidden ">
            <tr className="font-medium pt-2 rounded-t-lg">
              <th className="py-2 px-3 rounded-tl-lg"> </th>
              <th className="py-2 px-1">Name</th>
              <th className="py-2 px-1">Plan</th>
              <th className="py-2 px-1">Email</th>
              <th className="py-2 px-1 ">Last Login</th>
              <th className="py-2 px-1">Revenue</th>
              <th className="py-2 px-1 rounded-tr-xl ">Summaries</th>
            </tr>
          </thead>
          <tbody className=" ">
            {currentCustomerPage?.length === 0 ? (
              <tr>
                <td colSpan="6">No Records Found</td>
              </tr>
            ) : (
              currentCustomerPage
                ?.filter((user) => {
                  return customerFilter.toLowerCase() === ""
                    ? user
                    : user.name
                        .toLowerCase()
                        .includes(customerFilter.toLowerCase());
                })
                ?.map((customer, index) => (
                  <tr
                    className="font-medium pt-2 cursor-pointer "
                    key={customer.email}
                    onClick={() =>
                      customer?.userId
                        ? handleUserClick(customer)
                        : window.alert("User not signup yet to add credit")
                    }
                  >
                    <td className="py-2 px-3">{index + 1}.</td>
                    <td className="py-2 px-1">{customer.name}</td>
                    <td className="py-2 px-1">{customer.plan}</td>
                    <td className="py-2 px-1">{customer.email}</td>
                    <td className="py-2 px-1">
                      {customer.lastLogin?.split("T")[0]}
                    </td>
                    <td className="py-2 px-1">{customer?.revenue}</td>
                    <td className="py-2 px-1">{customer?.summaries}</td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
        <span className="mt-3">
        {filteredCustomers?.length > 10 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel=" > "
            onPageChange={handleCustomerPage}
            pageRangeDisplayed={3}
            pageCount={customerPageCount}
            previousLabel=" < "
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-nav"
            nextLinkClassName="page-nav"
            activeLinkClassName="active"
          />
        )}
</span>
        {/* Configure Team plans  */}

        <div className="flex flex-col items-between mt-5">
          <div className="border-l-4 border-yellow-500 pl-4 h-8 my-4 flex items-center w-full ">
            <span className="font-medium">Configure team Plans</span>
          </div>
          <div className="flex justify-between items-center -mt-4">
            <div
              className="flex items-center gap-2 w-full font-semibold  cursor-pointer"
              onClick={() => setManagerForm(!managerForm)}
            >
              <div className="flex items-center bg-yellow-500 rounded-full text-xl text-white px-2">
                {" "}
                +
              </div>{" "}
              Add Team
            </div>
            <div className="w-full ">
              <input
                type="text"
                placeholder="Search here..."
                className="outline-none  border-b-4 bg-transparent text-black border-[#3D66FB] w-32   float-right"
                onChange={(e) => {
                  setManagerFilter(e.target.value);
                  filterManagers(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <table className="bg-white rounded-xl mt-2 table-auto">
          <thead className="bg-yellow-500  overflow-hidden ">
            <tr className="font-medium pt-2 rounded-t-lg">
              <th className="py-2 px-3 rounded-tl-xl"> </th>
              <th className="py-2 px-1">Name</th>
              <th className="py-2 px-1">Plan</th>
              <th className="py-2 px-1">Email</th>
              <th className="py-2 px-1">Credit</th>
              <th className="py-2 px-1">Logo</th>
              <th className="py-2 px-1">Domain</th>
              <th className="py-2 px-1"></th>
              <th className="py-2 px-1 rounded-tr-xl"></th>
            </tr>
          </thead>
          <tbody className="  ">
            {filteredCustomers?.length === 0 ? (
              <tr>
                <td colSpan="6">No Records Found</td>
              </tr>
            ) : (
              currentManagerPage
                ?.filter((user) => {
                  return managerFilter.toLowerCase() === ""
                    ? user
                    : user.name
                        .toLowerCase()
                        .includes(managerFilter.toLowerCase());
                })
                ?.map((manager, index) => (
                  <tr
                    className="font-medium pt-2 rounded-t-lg"
                    key={manager.email}
                  >
                    <td className="py-2 px-3 rounded-tl-xl">{index + 1} </td>
                    <td
                      className="py-2 px-1 cursor-pointer"
                      onClick={() =>
                        manager?.userId
                          ? handleUserClick(manager)
                          : window.alert("User not signup yet to add credit")
                      }
                    >
                      {manager?.name}
                    </td>
                    <td className="py-2 px-1">{manager?.plan}</td>
                    <td className="py-2 px-1">{manager?.email}</td>
                    <td >{manager?.creditsByAdmin}</td>
                    <td
                      className="py-2 px-1 "
                      onClick={() => handleLogoEdit(manager, index)}
                    >
                      {logoEditMode && index === activeManagerIndex ? ( 
                        <input
                          type="text"
                          defaultValue={manager?.logo}
                          value={updatedLogoURL}
                          onChange={(e) => setUpdatedLogoURL(e.target.value)}
                          onBlur={() => {
                            handleLogoEdit(manager, index, true);
                            setLogoEditMode(false);
                          }}
                          className="red-underline"
                        />
                      ) : (
                        manager.logo && (
                          <img src={manager?.logo} alt="logo" className="h-6" />
                        )
                      )}
                    </td>
                    <td className="py-2 px-1">{manager?.domain}</td>
                    <td
                      className="py-2 px-1"
                      onClick={() => editManagerForm(manager)}
                    >
                      <BiEditAlt className="h-8 cursor-pointer -mr-2" />
                    </td>
                    <td className="py-2 px-1 rounded-tr-xl">
                      <MdOutlineDeleteForever
                        className="h-8 cursor-pointer"
                        onClick={() =>
                          deleteManager(manager._id || manager?.userId)
                        }
                      />
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
        <span className="mt-3">
        {manager?.length > 10 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel=" > "
            onPageChange={handleManagerPage}
            pageRangeDisplayed={3}
            pageCount={managerPageCount}
            previousLabel=" < "
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-nav"
            nextLinkClassName="page-nav"
            activeLinkClassName="active"
          />
        )}
</span>
        {/* demo purpose */}
      </div>
    </div>
  );
};

export default AdminPage;
