import React, { useState, useRef } from "react";
import axios from "axios";
import { MdUploadFile } from "react-icons/md";

const AddManagerForm = ({
  setManagerFormView,
  user,
  fetchManagers,
  setShowConfirmation,
  setConfirmationMessage,
  setShowWarning,
  setWarningMessage,
}) => {
  const [managerData, setManagerData] = useState({
    name: "",
    email: "",
    logo: "",
    domain: "",
  });
  const [inputErrors, setInputErrors] = useState({
    email: "",
    domain: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    let isValid = true;

    // Validate email input
    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      isValid = emailRegex.test(value);
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        email: isValid ? "" : "Invalid email address",
      }));
    }

    // Validate domain input
    if (name === "domain") {
      const domainRegex =
        /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-z]{2,})(:[0-9]{2,})?$/;
      isValid = domainRegex.test(value);
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        domain: isValid ? "" : "Invalid domain format",
      }));
    }

    if (type === "file") {
      const file = files[0];
      const formData = new FormData();
      formData.append(name, file);
      setManagerData((prevData) => ({
        ...prevData,
        [name]: formData.get(name),
      }));
    } else {
      if (isValid) {
        setManagerData({ ...managerData, [name]: value });
      }
    }
  };

  const addManager = async (e) => {
    e.preventDefault();
    if (!managerData.name || !managerData.email || !managerData.domain) {
      setWarningMessage("Please fill all the fields");
      setShowWarning(true);
      return;
    }

    setLoading(true);
    const url = process.env.REACT_APP_URL + "/admin/addManager";
    try {
      const formData = new FormData();
      Object.entries(managerData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const res = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user["connect.sid"]}`,
        },
      });
      // console.log(res);
      setConfirmationMessage("Manager added successfully");
      setShowConfirmation(true);
      setManagerFormView();
      await fetchManagers();
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  };

  const inputRef = useRef(null);
  const handleUpload = () => {
    inputRef.current.click();
  };

  return (
    <div
      className="min-h-full bg-[#00000030] p-2 md:p-8 fixed min-w-full flex items-center justify-center left-0 top-0"
      onClick={() => setManagerFormView()}
    >
      {loading && (
        <div className="loader-outer">
          <div className="loader z-20"></div>
        </div>
      )}
      <div
        className="flex flex-col justify-evenly w-[30rem] bg-white p-3 items-center rounded-xl shadow-xl gap-3"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="border-l-4  border-yellow-500 ml-32 w-[90vw] md:w-[30vw] ">
          <span className="text-black text-opacity-80 text-[28px] font-semibold font-fredoka pl-2 ">
            Add Team
          </span>
        </div>
        <form
          className="flex justify-around px-2 flex-col items-center min-h-[20rem] min-w-[20rem] gap-6"
          onSubmit={addManager}
        >
          <div className="flex flex-col gap-1 ">
            <label className="font-semibold text-lg  font-fredoka ">
              Customer Name
            </label>
            <input
              type="text"
              className="border-b-2 w-[25rem]  border-[#3D66FB]"
              onChange={handleChange}
              name="name"
            />
          </div>
          <div className="flex flex-col rela gap-2 ">
            <label className="font-semibold text-lg font-fredoka ">Email</label>
            <input
              type="email"
              className={`border-b-2 w-[25rem] border-[#3D66FB] ${inputErrors.email ? "border-red-500" : ""
                }`}
              onChange={handleChange}
              name="email"
            />
            {inputErrors.email && (
              <span className="text-red-500 text-center ml-2 font-semibold text-lg font-fredoka ">
                {inputErrors.email}
              </span>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <label className="font-semibold text-lg font-fredoka ">
              Domain
            </label>
            <input
              type="url"
              className={`border-b-2 w-[25rem] border-[#3D66FB] ${inputErrors.domain
                  ? "border-red-500 font-semibold text-lg font-fredoka "
                  : ""
                }`}
              onChange={handleChange}
              name="domain"
            />
            {inputErrors.domain && (
              <span className="text-red-500 text-center ml-2 font-semibold text-lg font-fredoka ">
                {inputErrors.domain}
              </span>
            )}
          </div>
          <div className="flex items-center  w-[25rem] gap-x-20 ">
            {/* Remove the label and input field */}
            {/* <label className="font-semibold text-lg font-fredoka ">Logo</label> */}
            {/* <input type="file" className="hidden" onChange={handleChange} name="logo" ref={inputRef} /> */}
            {/* Keep only the "Upload Logo" button */}
            <div className="flex items-center gap-4">
              <input
                type="file"
                className="hidden"
                onChange={handleChange}
                name="logo"
                ref={inputRef}
              />
              <button
                className="flex items-center gap-x-2 bg-[#3D66FB] text-white font-semibold text-lg font-fredoka  "
                onClick={handleUpload}
                type="button"
              >
                <MdUploadFile />
                Upload yourLogo
              </button>
            </div>
          </div>

        </form>
        <div className="flex gap-2 justify-end w-full ">
          <button
            className="border-[#3D66FB] border-1 rounded-[20px] font-semibold text-lg font-fredoka  h-[40px] px-2 bg-white text-[#3D66FB] hover:text-blue-700"
            onClick={() => setManagerFormView()}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="border-[#3D66FB] border-1 rounded-[20px] font-semibold text-lg font-fredoka   h-[40px] px-3 bg-[#3D66FB] text-white hover:bg-blue-700"
            onClick={addManager}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddManagerForm;
