import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import GoogleImage from "../../assets/google/google.png";
import AuthPageLayout from "./AuthPageLayout";
import weReportLogo from "../../assets/logo/weReporWork.png";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMount } from "react-use";
import { login, logout } from "../../Store/reducers/authLoginReducer";
import { useNavigate } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Box from "@mui/material/Box";
import PromoCode from "../../assets/logo/PromoCode.png";
import { makeStyles } from "@material-ui/core/styles";

// Define custom styles
const useStyles = makeStyles((theme) => ({
  noHover: {
    "&:hover": {
      backgroundColor: "transparent",
      border: "none",
    },
  },
}));

const PromoCodeLogin = () => {
  const [promoCode, setPromoCode] = useState("");
  const [errorMessage, setErrorMessage] = useState({ severity: "", message: "" });
  const [showGoogleButton, setShowGoogleButton] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [snackbarOpen, setSnackbarOpen] = useState(false);


  const validatePromoCode = async (promoCode) => {
    const url = process.env.REACT_APP_URL + "/auth/validate-promo-code"

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ promoCode }),
      });

      if (response.ok) {
        const data = await response.json();
        setShowGoogleButton(true);
        setErrorMessage({ severity: "success", message: "Hurray! Promocode is successfully verified" })
        setSnackbarOpen(true); // Open the Snackbar
        setTimeout(() => {
          setSnackbarOpen(false); // Close the Snackbar after a delay (adjust as needed)
        }, 3000); // 3000 milliseconds = 3 seconds

        return data.success;
      } else {
        setErrorMessage({ severity: "error", message: "Invalid promo code. Please try new one." })
        setSnackbarOpen(true); // Open the Snackbar
        setShowGoogleButton(false);
      }
    } catch (error) {
      setErrorMessage({ severity: "error", message: "Error validating promo code" })
      setSnackbarOpen(true); // Open the Snackbar
      console.error("Error validating promo code:", error);
      return false;
    }
  };

  const handleGoogleLogin = (promoCode) => {
    const googleOAuthURL = `${process.env.REACT_APP_URL}/auth/google?isPromoCode=${encodeURIComponent(promoCode)}`;
    const newWindow = window.open(googleOAuthURL, "_self", 'noopener,noreferrer,resizable');

    if (!newWindow) {
      alert("Popup blocked. Please enable popups for this site and try again.");
    }

  };
  const handlePromoCodeLogin = async () => {
    await validatePromoCode(promoCode);
  };

  useMount(async () => {
    const storedUser = localStorage.getItem("user");
    const storedIsLoggedIn =
      storedUser !== null && storedUser !== undefined && storedUser !== "";

    if (storedUser && storedIsLoggedIn) {
      try {
        const user = JSON.parse(storedUser);

        if (user) {
          await dispatch(login(user));
          navigate("/");
        }
      } catch (err) {
        dispatch(logout());
      }
    }
  });

  return (
    <>
      <div className="flex justify-center items-center gap-3.5 pb-14">
        <img src={weReportLogo} alt="logo" className="h-15 w-16" />
        <span
          className="md:text-5xl text-xl font-bold font-fredoka"
          style={{ fontWeight: "700 !important" }}
        >
          We Report Work
        </span>
      </div>

      <div className="flex items-center justify-center">
        <div className="flex md:flex-row flex-col gap-20 md:gap-32  rounded-lg mx-auto md:p-10 p-2  justify-between lg:w-[90%] mt-5  ">
          {/* AuthPageLayout component */}
          <AuthPageLayout />

          {/* Login card  */}
          <div className="flex  flex-col gap-1 bg-[#FFFFFF80] p-8 rounded-md w-full md:w-[45%] border border-info border-3 h-100 border-secondary">
            <p className="md:text-2xl text-xl font-semibold">Get Started</p>
            <p className="text-sm font-medium mb-5">
              Apply your promo code & signup with your <span className="text-[#3d66fb] ">Google</span> account to redeem.
            </p>

            {showGoogleButton ? (
              <button
                onClick={() => handleGoogleLogin(promoCode)}
                className="flex flex-row justify-center gap-2 bg-white shadow-sm rounded-lg p-1 items-center text-sm mb-2 py-2"
              >
                <img src={GoogleImage} alt="GoogleImage" className="h-4 w-4" />
                <span>Success! Google Login</span>
              </button>
            ) :
              (
                <div className="flex flex-col gap-2">
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 1,
                        width: "100%",
                        borderBottom: " 2px solid rgba(0, 158, 248, 0.81)",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                    className={classes.noHover}
                  >
                    <TextField
                      id="standard-basic"
                      label="Promo Code"
                      variant="standard"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                    />
                  </Box>
                  <button
                    onClick={handlePromoCodeLogin}
                    className="flex flex-row justify-center gap-2 bg-white shadow-sm rounded-lg p-1 items-center text-sm mb-2 py-2"
                  >
                    <img src={PromoCode} alt="PromoCode" className="h-4 w-4" />
                    <span>Validate your Promo code</span>
                  </button>
                </div>
              )}

            <div className="flex mx-auto  gap-2 sm:text-sm text-xs text-black">
              <Link
                to="https://wereport.work/privacy.html"
                className="cursor-pointer text-black no-underline"
              >
                Privacy Policy
              </Link>
              <span className=" text-black">|</span>
              <Link
                to="https://wereport.work/terms.html"
                className="cursor-pointer text-black no-underline"
              >
                Terms & Condition
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={errorMessage.severity}>
          {errorMessage.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PromoCodeLogin;
