import React from "react";
import CheckImage from "../../assets/check.png";

const AuthPageLayout = () => {
  return (
    <div className="flex p-2 gap-2 md:gap-6 flex-col justify-center  ">
      <div className="flex flex-col gap-6  font-medium">
        <li className="flex items-center gap-2 ">
          <img
            src={CheckImage}
            alt="checkImage"
            className="md:h-10 md:w-10 w-6 h-6"
          />{" "}
          <span className="font-semibold  text-lg md:text-xl  ">
            Daily Summary Automated
          </span>
        </li>
        <li className="flex items-center gap-2">
          <img
            src={CheckImage}
            alt="checkImage"
            className="md:h-10 md:w-10 w-6 h-6"
          />{" "}
          <span className="font-semibold text-lg md:text-x">
            No Manual Work
          </span>
        </li>
        <li className="flex items-center gap-2">
          <img
            src={CheckImage}
            alt="checkImage"
            className="md:h-10 md:w-10 w-6 h-6"
          />{" "}
          <span className="font-semibold text-lg md:text-xl">
            Never miss reporting deadlines{" "}
          </span>
        </li>
      </div>
    </div>
  );
};

export default AuthPageLayout;
