import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import MuiButton from "../../components/MuiButton";

const Checkout = ({ value, name, amount }) => {
  // const { user } = useAuth();
  const { user } = useSelector((state) => state.authLogin);
  const [loading, setLoading] = useState(false);
  const _id = user?.data[0]?._id;
  const handleCheckout = async () => {
    const url = process.env.REACT_APP_URL + "/pay/payment/";
    setLoading(true);
    await axios
      .post(
        url,
        { amount, name, userId: _id },
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
        // console.log( "hehe" )
      )
      .then((res) => {
        if (res.data.url) {
          window.location.href = res.data.url;
        }
      })
      .catch((err) => {
        // console.log(err)
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <MuiButton
        className="text-white bg-blue-600 border-2 border-blue-600 rounded-xl mx-auto md:w-7/12 w-6/12 shadow-md py-1"
        onClick={handleCheckout}
        loading={loading}
      >
        {value}{" "}
      </MuiButton>
    </>
  );
};

export default Checkout;
