import React from "react";
import GoogleImage from "../../assets/google/google.png";
import AuthPageLayout from "./AuthPageLayout";
import weReportLogo from "../../assets/logo/weReporWork.png";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMount } from "react-use";
import { login, logout } from "../../Store/reducers/authLoginReducer";
import { useNavigate } from "react-router-dom";
import Disclosure from "./Disclosure";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ***************************
  // **** LOGIN WITH GOOGLE ****
  // ***************************

  const handleGoogleLogin = () => {    
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'authenticationRequestedEvent',
      'authenticationMethod': 'Google'
    });        

    const googleOAuthURL = process.env.REACT_APP_URL + "/auth/google/callback";
    const newWindow = window.open(googleOAuthURL, "_self", 'noopener,noreferrer,resizable');

    if (!newWindow) {
      alert("Popup blocked. Please enable popups for this site and try again.");
    }
  };

  useMount(async () => {
    // Retrieve user and isLoggedIn values from localStorage
    const storedUser = localStorage.getItem("user");
    const storedIsLoggedIn =
      storedUser !== null && storedUser !== undefined && storedUser !== "";
    // Check if values exist in localStorage
    if (storedUser && storedIsLoggedIn) {
      try {
        // Parse the storedUser value from string to object
        const user = JSON.parse(storedUser);
        if (user) {
          // Dispatch actions to set user and isLoggedIn in the store
          await dispatch(login(user));
          navigate("/");
        }
      } catch (err) {
        dispatch(logout());
      }
    }
  });

  return (
    <>
      {/* Logo Branding */}
      <div className="flex justify-center items-center gap-3.5 pb-14 ">
        <img src={weReportLogo} alt="logo" className="h-15 w-16" />
        <span
          className="md:text-5xl text-xl font-bold font-fredoka"
          style={{ fontWeight: "999 !important" }}
        >
          We Report Work
        </span>
      </div>
      <div className="flex items-center justify-center">
        <div className="flex md:flex-row flex-col gap-20 md:gap-32  rounded-lg mx-auto md:p-10 p-2  justify-between lg:w-[90%] mt-5  ">
          <AuthPageLayout />

          {/* Login card  */}

          <div className="flex flex-col gap-1 bg-[#FFFFFF80] p-8 rounded-md w-full md:w-[28.625rem] black h-[17.8125rem] border-secondary">
            <p className="md:text-2xl text-xl font-semibold">Get Started</p>
            <p className="text-sm font-medium mb-5 font-DM Sans">
              Signup or Login with your Google Account to start summarizing your day.
            </p>
            <button
              onClick={handleGoogleLogin}
              className="flex flex-row justify-center gap-2 bg-white shadow-sm rounded-lg p-1 items-center text-sm mb-2 py-2"
            >
              <img src={GoogleImage} alt="GoogleImage" className="h-4 w-4" />
              <span>Use Google Account</span>
            </button>

            <div className="flex mx-auto  gap-2 sm:text-sm text-xs text-black">
              <Link
                to="https://wereport.work/privacy.html"
                className="cursor-pointer text-black no-underline"
              >
                Privacy Policy
              </Link>
              <span className=" text-[#1A73E8]">|</span>
              <Link
                to="https://wereport.work/terms.html"
                className="cursor-pointer text-black no-underline"
              >
                Terms & Condition
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Include the Disclosure component */}
      <Disclosure />
    </>
  );
};

export default Login;
