import React from "react";
import { useDispatch } from "react-redux";
import { fetchUserProfile } from "../../../Store/actions/fetchUserProfile";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../Outlook/authConfig";
import axios from "axios";
import FormControlLabel from '@mui/material/FormControlLabel';
import SwitchBtn from "../../../components/SwitchBtn";

export const TeamsSignInButton = ({
    user,
    isConnected,
    setIsConnected,
    setSnackbarOpen,
    setteamEvents,
}) => {
    const { instance } = useMsal();
    const dispatch = useDispatch();

    const handleLogin = () => {
        if (isConnected) {
            setteamEvents([]);
            connectTeams(false);
        } else {
            setteamEvents([]);
            handleTeamLogin();
        }
    };

    const handleTeamLogin = async () => {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': 'toolConnectionRequestedEvent',
            'feature': 'Teams',
            'status': 'Requested-Connect'
        });

        try {
            const loginResponse = await instance.loginPopup(loginRequest);
            // Check if login was successful
            if (loginResponse && loginResponse.account) {
                handleAccessToken(loginResponse?.accessToken);
            } else {
                console.log('No account found after login.');
            }
        } catch (error) {
            console.log('Login failed:', error);
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'toolConnectionFailedEvent',
                'feature': 'Teams',
                'status': 'Failed'
            });
        }
    };

    const handleAccessToken = async (response) => {
        const accessToken = response;
        try {
            if (accessToken) {
                const url =
                    process.env.REACT_APP_URL +
                    "/msteam/save_msteams_credentials";
                const response = await axios.post(
                    url,
                    {
                        accessToken: accessToken,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${user["connect.sid"]}`,
                        },
                    }
                );
                if (response.status == 200) {
                    connectTeams(true);
                } else {
                    console.error("API call failed");
                }
            }

        } catch (error) {
            console.error("Fetch error:", error);
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'toolConnectionFailedEvent',
                'feature': 'Teams',
                'status': 'Failed'
            });
        }
    };

    const connectTeams = async (isConnected) => {
        try {
            const url = process.env.REACT_APP_URL + "/msteam/msteamconnect";
            const response = await axios.put(
                url,
                { isConnect: isConnected },
                {
                    headers: {
                        Authorization: `Bearer ${user["connect.sid"]}`,
                    },
                }
            );
            if (response.status == 200 && isConnected) {
                setIsConnected(true);
                setSnackbarOpen(true);
                dispatch(fetchUserProfile());
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'toolConnectionSuccessEvent',
                    'feature': 'Teams',
                    'status': 'Connected',
                });
            } else if (response.status == 200 && !isConnected) {
                setIsConnected(false);
                setSnackbarOpen(true);
                dispatch(fetchUserProfile());
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'toolConnectionRemoveEvent',
                    'feature': 'Teams',
                    'status': 'Removed',
                });
            } else {
                // console.error("API call failed");
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'toolConnectionFailedEvent',
                    'feature': 'Teams',
                    'status': 'Failed'
                });
            }
        } catch (error) {
            // console.error("Fetch error:", error);
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'toolConnectionFailedEvent',
                'feature': 'Teams',
                'status': 'Failed'
            });
        }
    };


    return (
        <FormControlLabel
            sx={{
                display: 'block',
            }}
            control={
                <SwitchBtn
                    checked={isConnected}
                    onChange={handleLogin}
                    name="connect"
                    sx={{ m: 1 }}
                />
            }
        />
    );
};
