import React, { useEffect, useState } from "react";
import Feedback from "../../components/Feedback";
import userView from "../../assets/user.png";
import teamView from "../../assets/team-view-icon.png";
import axios from "axios";
import { useSelector } from "react-redux";
import { useMount } from "react-use";
import moment from "moment";
import { FaCheck } from "react-icons/fa";
import DatePicker from "react-datepicker";
import CustomInput from "../../components/datepicker";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import "./Reporting.css";
import { Box } from "@mui/material";
import { useMediaQuery } from "@mui/material";


const ManagerView = ({ setManagerView }) => {
  const [employees, setEmployees] = useState(null);
  const [userSummary, setUserSummary] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDateType, setSelectedDateType] = useState(1);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [FormatselectedDate, setFormatSelectedDate] = useState(
    moment().format("Do [of] MMM, YYYY")
  );

  const { user } = useSelector((state) => state.authLogin);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const setYesterdayDate = () => {
    setSelectedDate(moment().subtract(1, "days").format("YYYY-MM-DD"));
    setSelectedDateType(2);
    setFormatSelectedDate(
      moment().subtract(1, "days").format("Do [of] MMM, YYYY")
    );
  };
  const setTodayDate = () => {
    setSelectedDate(moment().format("YYYY-MM-DD"));
    setSelectedDateType(1);
    setFormatSelectedDate(moment().format("Do [of] MMM, YYYY"));
  };

  const setCustomDate = (date) => {
    setSelectedDate(moment(date).format("YYYY-MM-DD"));
    setStartDate(date);
    setSelectedDateType(3);
    setFormatSelectedDate(moment(date).format("Do [of] MMM, YYYY"));
  };

  useEffect(() => {
    if (selectedEmployee !== "") {
      getEmployeeSummary(selectedEmployee)
    }
  }, [selectedDate]);

  const getEmployeeSummary = async (userId) => {
    const url =
      process.env.REACT_APP_URL +
      "/openai/getSummaryByUser?userId=" +
      userId +
      "&date=" +
      selectedDate;
    try {
      setLoading(true);
      const res = await axios.get(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user["connect.sid"]}`,
        },
      });
      setUserSummary(res.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDropdownChange = async (event) => {
    const selectedValue = event.target.value;
    setSelectedEmployee(selectedValue);
    if (selectedValue !== "") {
      await getEmployeeSummary(selectedValue);
    }
  };

  // const getEmployees = async () => {
  //   const url = process.env.REACT_APP_URL + "/manager/getEmployeesV2";
  //   try {
  //     const res = await axios.get(url, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${user["connect.sid"]}`,
  //       },
  //     });

  //     // console.log("API Response:", res.data);

  //     const employeeData = res.data.data;
  //     // console.log("Employee Data:", employeeData);

  //     setEmployees(employeeData);
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // };

  const getEmployees = async () => {
    const url = process.env.REACT_APP_URL + "/manager/getTeams";
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${user["connect.sid"]}`,
        },
      });
      setEmployees(res.data.data);
    } catch (error) {
      console.error("Error fetching employees:", error.message);
    }
  };

  useMount(() => {
    getEmployees();
  });

  return (
    <div>
      <div className="flex flex-wrap justify-between ">
        <div className="border-l-8 flex flex-col gap-0 border-yellow-500 pl-5 mt- justify-center">
          <span className="font-semibold text-xl md:text-2xl  ">
            Your team member's work summary of {FormatselectedDate}
          </span>
          <span className="text-sm">
            Ask all your team members to save their summary. You can only
            view the saved summmaries.
          </span>
        </div>
        <div className="flex items-center mt-3 ">
          <div className="">
            <button
              onClick={setYesterdayDate}
              className={`btn py-0 me-2 btn-sm flex   rounded-lg text-black ${selectedDateType === 2 ? "btn-warning" : "text-primary"
                }`}
              style={{ height: "30px" }}
            >
              {selectedDateType === 2 ? (
                <FaCheck
                  className="inline"
                  style={{ marginRight: "5px" }}
                />
              ) : (
                ""
              )}
              Yesterday
            </button>
          </div>
          <button
            onClick={setTodayDate}
            className={`btn  ${selectedDateType === 1 ? "btn-warning" : "text-primary"
              } py-0 btn-sm text-black`}
            style={{ height: "30px" }}
          >
            {selectedDateType === 1 ? (
              <FaCheck className="inline" style={{ marginRight: "5px" }} />
            ) : (
              ""
            )}
            Today
          </button>
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setCustomDate(date);

            }}
            customInput={<CustomInput />}
            placeholderText="Select Date"
            maxDate={new Date()}
          />
        </div>
      </div>
      <div className="min-h-[70vh] bg-lotion rounded-xl mt-4 border p-4">
        <div className="flex items-center md:flex-row">
          <FormControl sx={{ m: 1, minWidth: [200, 250] }}>
            <InputLabel id="select-employee-label" sx={{ color: "#3D66FB" }}>
              Select Employee
            </InputLabel>
            <Select
              labelId="select-employee-label"
              id="select-employee"
              value={selectedEmployee}
              label="Select Employee"
              onChange={handleDropdownChange}
              sx={{
                "&.MuiInput-underline:before": { borderBottomColor: "#3D66FB" },
              }}
            >
              <MenuItem value="" disabled>
                Select Employee
              </MenuItem>
              {employees?.flatMap((employee) => (
                employee?.inviteTeam
                  .filter((employeeInvite) => employeeInvite.isAccept === true)
                  .map((employeeInvite) => (
                    <MenuItem key={employeeInvite.user._id} value={employeeInvite.user._id}>
                      {employeeInvite.user.email || "No Name"}
                    </MenuItem>
                  ))
              ))}
            </Select>
            <FormHelperText sx={{ color: "#3D66FB" }}>
              Choose an employee from the list
            </FormHelperText>
          </FormControl>
        </div>

        <div className="my-4 w-full h-full text-center">
          {userSummary ? (
            <div className="min-h-100">
              <textarea
                className="w-full md:min-h-[60vh] border-0 bg-lotion rounded-xl"
                name="postContent"
                value={
                  userSummary?.map((summary) => summary?.summary).join("\n") ||
                  "No Summary Found"
                }
                disabled
              />
            </div>
          ) : (
            <div className="min-h-100">
              <textarea
                className="w-full md:min-h-[60vh] border-0 bg-lotion rounded-xl"
                name="postContent"
                value={"No Summary Found"}
                disabled
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex md:flex-row justify-between">
        <div
          className="border-[3px] border-[#3d66fb] rounded-full p-2 items-center mt-2 summary text-center flex  justify-center  cursor-pointer mb-10 text-sm"
        >
          <Box
            gap={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyItems: "center",
              padding: isMobile ? "0 5px 0 5px" : "0 20px 0 20px",
              borderRight: "3px solid #3d66fb",
            }}
            onClick={() => setManagerView()}
          >
            {!isMobile && <img
              src={userView}
              alt="icon"
              className="md:h-8 md:w-8 h-6 w-6"
            />
            }

            <span className="font-semibold text-sm">
              Individual View
            </span>
          </Box>
          <Box
            gap={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyItems: "center",
              padding: isMobile ? "0 5px 0 5px" : "0 30px 0 20px",
            }}
          >
            {!isMobile && <img
              src={teamView}
              alt="icon"
              className="md:h-8 md:w-8 h-6 w-6"
            />
            }
            <span className="font-semibold text-sm  text-decoration-none no-underline" style={{ color: "#3d66fb" }}>
              Team View
            </span>
          </Box>
        </div>

        {/* </NavLink> */}
        <Feedback />
      </div>
    </div>
  );
};

export default ManagerView;
