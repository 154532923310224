import React, { useEffect, useState } from "react";
import { VscExclude } from "react-icons/vsc";
import { RiArrowUpDownFill } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import slack from "../../../assets/coming/slack.png";
import { useSummary } from "../../../context/SummaryDataContext";
import { useSelector, useDispatch } from "react-redux";
import SlackLogin from "./SlackLogin";
import axios from "axios";
import "./Slack.css"
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { fetchUserProfile } from "../../../Store/actions/fetchUserProfile";


const SlackList = ({ selectedDate, user, summaryData, updateStatus }) => {

  const dispatch = useDispatch();
  const [slackExclude, setSlackExclude] = useState(user?.data[0].slack);
  const { slackEvents, setSlackEvents } = useSummary();
  const { userProfile } = useSelector((state) => state.userProfile);
  const [SlackActiveTab, setSlackActiveTab] = useState("input");
  const [summary, setSummary] = useState([]);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(user?.data[0]?.slack?.isConnect);

  useEffect(() => {
    setSlackExclude(userProfile?.data[0]?.slack);
    if (userProfile?.data[0]?.slack?.isConnect !== undefined) {
      setIsConnected(userProfile.data[0].slack.isConnect);
    }
  }, [userProfile, user]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const getSlackMessages = async (date) => {
    let api_call_completed = false;
    const url = process.env.REACT_APP_URL + `/slack/messages?date=${date}`;
    setIsLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${user["connect.sid"]}`,
        },
      });
      if (response.status === 200) {
        const data = response.data;
        setIsLoading(false);
        // Process the Slack messages data as needed
        setSlackEvents(data?.data);
      } if (response.status !== 200) {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.error("Error fetching Slack messages:", error);
    } finally {
      setIsLoading(false)
      api_call_completed = true;
      updateStatus({ isConnected, api_call_completed });
    }
  };

  useEffect(() => {
    if (isConnected == true) {
      updateStatus({ isConnected, "api_call_completed": false });
      getSlackMessages(selectedDate);
    }
    setSummary()

  }, [selectedDate, user, isConnected]);

  const handleDeleteEvents = (index) => {
    const updatedEvents = [...slackEvents];
    updatedEvents.splice(index, 1);
    setSlackEvents(updatedEvents);
  };


  const updateCheckPoint = async (e) => {
    if (!slackExclude) {
      return;
    }
    let updatedSlackExclude;
    if (e.target.name === "limitLength") {
      const name = e.target.name;
      const value = e.target.checked ? Number(e.target.value) : 14000;
      updatedSlackExclude = { ...slackExclude, [name]: value };
    } else {
      const name = e.target.name;
      const currentValue = slackExclude[name];
      const updatedValue = !currentValue;
      updatedSlackExclude = { ...slackExclude, [name]: updatedValue };
    }
    setSlackExclude(updatedSlackExclude);
    try {
      const url = process.env.REACT_APP_URL + "/auth/slack";
      const response = await axios.post(
        url,
        { ...updatedSlackExclude },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );
      if (response.status === 200) {
        await dispatch(fetchUserProfile());
        isConnected && getSlackMessages(selectedDate);
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  const handleTextareaChange = (event, index) => {
    const updatedEvents = [...slackEvents];
    updatedEvents[index].message = event.target.value;
    setSlackEvents(updatedEvents);
  };

  const convertToIST = (backendDate) => {
    const originalDate = new Date(backendDate);
    const istDate = new Date(originalDate.getTime() + 5.5 * 60 * 60 * 1000);
    return istDate.toLocaleTimeString('en-IN', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  return (
    <>
      <div className="rounded-xl mt-10 min-h-300 shadow border-zinc-300  min-h-500 bg-lotion max-md:overflow-x-auto md:overflow-hidden scrollbar-width scrollable-container">
        <div className="mb-2 flex gap-2 justify-between items-center text-center">
          <div></div>
          <div className="flex items-center gap-x-2 ml-16 text-black text-opacity-80 text-xl font-bold font-['DM Sans']">
            <img src={slack} alt="slack" className="h-8 w-8 " />
            Slack
          </div>
          <SlackLogin user={user} isConnected={isConnected} setIsConnected={setIsConnected} setSnackbarOpen={setSnackbarOpen} setSlackEvents={setSlackEvents} />
        </div>
        <div
          className={`flex border buttons h-12 shadow-md border-zinc-300 text-black text-opacity-80 text-xl font-['DM Sans'] bg-lotion rounded-full items-center justify-between mx-auto font-semibold mt-2`}
        >
          <button
            className={`${SlackActiveTab === "input" ? "border rounded-full shadow-xl" : ""
              } w-80 h-full`}
            onClick={() => setSlackActiveTab("input")}
          >
            {" "}
            <span className="flex items-center justify-center gap-x-6">
              {" "}
              <span className="text-2xl font-extralight">
                <RiArrowUpDownFill />
              </span>
              Input{" "}
            </span>{" "}
          </button>
          <button
            className={`${SlackActiveTab === "summary"
              ? "border rounded-full shadow-xl"
              : ""
              } w-80 h-full`}
            onClick={() => {
              setSlackActiveTab("summary");
            }}
          >
            {" "}
            <span className="flex items-center justify-center  gap-x-6">
              {" "}
              <span className="text-2xl font-extralight">
                <ImProfile />
              </span>
              Summary{" "}
            </span>
          </button>
          <button
            className={`${SlackActiveTab === "exclusions"
              ? "border rounded-full shadow-xl"
              : ""
              } w-80 h-full`}
            onClick={() => setSlackActiveTab("exclusions")}
          >
            {" "}
            <span className="flex items-center justify-center gap-x-6">
              {" "}
              <VscExclude className="text-2xl" /> Exclusions{" "}
            </span>
          </button>
        </div>

        {SlackActiveTab === "exclusions" && (
          <div className="flex flex-col p-4 gap-2">
            <div className="flex gap-2">
              <input
                type="checkbox"
                name="limitLength"
                id="limitChar"
                checked={slackExclude?.limitLength === 500 ? true : false}
                onChange={updateCheckPoint}
                value={500}
              />
              <label htmlFor="">Limit Items to 500 Char</label>
            </div>
            <div className="flex gap-2">
              <input
                type="checkbox"
                name="limitLength"
                checked={slackExclude?.limitLength === 1000 ? true : false}
                onChange={updateCheckPoint}
                value={1000}
              />
              <label htmlFor="">Limit Items to 1000 Char</label>
            </div>
            <div className="flex gap-2">
              <input
                type="checkbox"
                checked={slackExclude?.excludeSelf}
                name="excludeSelf"
                id="excludeSelf"
                onChange={updateCheckPoint}
              />
              <label htmlFor="excludeSelf">Exclude Self Messages</label>
            </div>
          </div>
        )}

        {SlackActiveTab === "input" && (
          <div className="flex flex-col gap-2  overflow-auto h-[24rem]">
            {isConnected && slackEvents?.length >= 1 && SlackActiveTab === "input" ? (
              IsLoading ? (
                <div className="flex justify-center items-center min-h-300 gap-4">
                  <img src={slack} alt="slack" className="h-8" />
                  <p className="mt-3 font-medium">Loading Slack messages...</p>
                </div>
              ) : (
                <table className="min-w-full  divide-y-2 rounded-xl  divide-gray-200 bg-lotion text-md mt-1">
                  <thead className="ltr:text-left rtl:text-right sticky top-0 z-10 bg-lotion">
                    <tr>
                      <th className=" whitespace-nowrap px-4 py-2 font-semibold text-[#3d66fb] w-[20%]">
                        Channel Type
                      </th>
                      <th className=" px-4 py-2  font-semibold text-[#3d66fb] w-[20%]">
                        Channel Name
                      </th>
                      <th className="px-6 py-4 font-semibold text-[#3d66fb] ml-2 w-[20%]">
                        Message
                      </th>
                      <th className="px-6 py-4 font-semibold text-[#3d66fb] ml-2">
                        Message Time
                      </th>
                      <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900"></th>
                    </tr>
                  </thead>

                  <tbody className="divide-y h-36 overflow-y-scroll border divide-gray-200 ml-20">
                    {slackEvents?.map((event, index) => (
                      <tr key={index} className="">
                        <td className="flex-wrapp pl-6 py-2 font-medium text-gray-900  ">
                          {event?.channelType}
                        </td>
                        <td className="pl-4 py-2   text-gray-700">
                          <div className="w-[14rem] min-h-[5vh] h-20 max-h-[30vh] overflow-hidden max-w-full ">
                            <textarea
                              name=""
                              rows="1"
                              cols="1"
                              id=""
                              value={event?.channelName}
                              className="w-[20rem] h-full bg-lotion p-2 min-h-[5vh] max-h-[30vh] resize-none "
                            >
                              {event?.channelName}
                            </textarea>
                          </div>
                        </td>
                        <td className="pl-4 py-2 text-gray-700">
                          <div className=" min-h-[5vh] h-20 max-h-[30vh] overflow-hidden max-w-full ">
                            <textarea
                              name=""
                              rows="1"
                              cols="1"
                              id=""
                              value={event?.message}
                              onChange={(event) =>
                                handleTextareaChange(event, index)
                              }
                              className="w-[20rem] h-full bg-lotion p-2 min-h-[5vh] max-h-[30vh] resize-none overflow-y-auto scrollbar-hidden"
                            >
                              {event?.message}
                            </textarea>
                          </div>
                        </td>
                        <td className="pl-4 py-2 text-gray-700">
                          <div className=" min-h-[5vh] h-20 max-h-[30vh] overflow-hidden max-w-full ">
                            <textarea
                              name=""
                              rows="1"
                              cols="1"
                              id=""
                              value={convertToIST(event?.time)}
                              className="w-[14rem] h-full bg-lotion p-2 min-h-[5vh] max-h-[30vh] resize-none overflow-y-auto scrollbar-hidden"
                            >

                            </textarea>
                          </div>
                        </td>
                        <td>
                          <i
                            className="fa fa-trash"
                            onClick={() => handleDeleteEvents(index)}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )
            ) : SlackActiveTab === "input" ? (
              <div className="flex justify-center items-center min-h-300 gap-4">
                <img src={slack} alt="Teams" className="h-8" />

                {IsLoading ? (
                  <p className="mt-3 font-medium">Loading Slack messages...</p>
                ) : (
                  <p className="mt-3 font-medium">Slack messages not found.</p>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        )}


        {isConnected && SlackActiveTab === "summary" && (
          <p className="w-[70%] m-auto p-3 whitespace-pre-wrap overflow-auto h-[24rem]">
            {slackEvents?.length > 0 ? <span>{summaryData}</span> : <span></span>}
          </p>
        )}
        {loadingSummary ? (
          <div className=" loader-outer z-99999">
            <div className=" loader z-999999"></div>
          </div>
        ) : (
          ""
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity={isConnected ? "success" : "error"}>
            {isConnected
              ? "Hurray! You are connected."
              : "Connect to summarize Slack Messages!"}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default SlackList;
