import React, { useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    Typography,
    Box,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";

const FeedbackAccordian = ({ setShowConfirmation, setConfirmationMessage, setSeverity }) => {
    const { user } = useSelector((state) => state.authLogin);
    const [isLoading, setIsLoading] = useState(false);
    const [isAnyCheckboxSelected, setIsAnyCheckboxSelected] = useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const initialCheckedItems = {
        item1: false,
        item2: false,
        item3: false,
        item4: false,
        item5: false,
        item6: false,
    };
    const [checkedItems, setCheckedItems] = useState(initialCheckedItems);

    const checkboxLabels = {
        item1: "Automatically send summaries to slack, hangout, teams, email / other tools.",
        item2: "Ability to respond to messages from We Report Work (Reply Email, Messages, Update JIRA Tasks).",
        item3: "Dedicated Time Tracking & Updation.",
        item4: "Personal Productivity Insights.",
        item5: "Automatic Attendance / Screen Capture.",
        item6: "Make AI update my JIRA, Trello, Tasks Automatically based on my daily data.",
    };

    const handleChange = (event) => {
        const updatedCheckedItems = {
            ...checkedItems,
            [event.target.name]: event.target.checked,
        };
        setCheckedItems(updatedCheckedItems);
        setIsAnyCheckboxSelected(Object.values(updatedCheckedItems).includes(true));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const checkedLabels = Object.keys(checkedItems)
            .filter(key => checkedItems[key])
            .map(key => checkboxLabels[key]);
        console.log('Checked labels:', checkedLabels);
        handleFeedbackAPI(checkedLabels);
    };

    const handleFeedbackAPI = async (checkedLabels) => {
        const url = process.env.REACT_APP_URL + "/feedbacks/feedback";
        const body = {
            email: user?.data[0]?.email,
            feedback: checkedLabels
        };

        setIsLoading(true);
        try {
            const response = await axios.post(url, body, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${user["connect.sid"]}`,
                },
            });
            if (response.data.success === true) {
                setSeverity("success")
                setShowConfirmation(true);
                setConfirmationMessage(response.data.msg);
                setCheckedItems(initialCheckedItems);
                setIsAnyCheckboxSelected(false);
            } else {
                setSeverity("error")
                setShowConfirmation(true);
                setConfirmationMessage(response.data.msg);
            };
        } catch (error) {
            const errorMessage = error.response?.data?.msg || "Something went wrong. Please try again later.";
            setSeverity("error")
            setShowConfirmation(true);
            setConfirmationMessage(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ marginBottom: 10 }}>
            <Accordion
                slotProps={{ transition: { timeout: 400 } }}
                sx={{
                    borderRadius: '15px',
                    overflow: 'hidden',
                    '&:first-of-type': {
                        borderTopLeftRadius: '15px',
                        borderTopRightRadius: '15px',
                    },
                    '&:last-of-type': {
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                    },
                    '&:before': {
                        display: 'none',
                    },
                }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <div className="border-l-8 flex flex-col gap-0 border-yellow-500 pl-5 -mt-15 h-10 justify-center ">
                        <Typography sx={{ fontWeight: 600, fontSize: 20 }}>Help us build the future of We Report Work - Vote now!</Typography>
                        {!isMobile && <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>
                            Share your feature preferences and ideas to help us improve We Report Work. Your feedback will directly influence our next updates.
                        </Typography>
                        }
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ fontWeight: 600, fontSize: 15 }}>
                        Which features would you like to see next in We Report Work ?
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <FormControl component="fieldset">
                            <FormGroup>
                                {Object.keys(checkboxLabels).map((key) => (
                                    <FormControlLabel
                                        key={key}
                                        control={
                                            <Checkbox
                                                checked={checkedItems[key]}
                                                onChange={handleChange}
                                                name={key}
                                            />
                                        }
                                        label={checkboxLabels[key]}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 3 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={!isAnyCheckboxSelected || isLoading}
                                sx={{
                                    width: '100px',
                                    backgroundColor: !isAnyCheckboxSelected ? 'gray' : 'black',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: !isAnyCheckboxSelected ? 'gray' : 'black',
                                    },
                                }}>
                                Submit
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 3 }} gap={1}>
                            <InfoIcon fontSize='small' />
                            <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>For other recommendations, kindly email us on hello@wereport.work (We’re always listening, just shoot an email!)</Typography>
                        </Box>
                    </form>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default FeedbackAccordian;