import React, { useEffect, useState } from "react";
import { VscExclude } from "react-icons/vsc";
import { RiArrowUpDownFill } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import axios from "axios";
import Teams from "../../../assets/coming/Teams.png";
import { useSummary } from "../../../context/SummaryDataContext";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserProfile } from "../../../Store/actions/fetchUserProfile";
import { TeamsSignInButton } from "./TeamsSignInButton";
import "./Teams.css"
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { loginRequest } from "../Outlook/authConfig";
import { useMsal } from '@azure/msal-react';

const TeamsList = ({ selectedDate, user, getteamEvents, summaryData, updateStatus }) => {

  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const { teamEvents, setteamEvents } = useSummary();
  const { userProfile } = useSelector((state) => state.userProfile);
  const [TeamsActiveTab, setTeamsActiveTab] = useState("input");
  const [summary, setSummary] = useState([]);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [teamExclude, setTeamExclude] = useState(user?.data[0]?.msteams);
  const [IsLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(user?.data[0]?.msteams?.isConnect);

  useEffect(() => {
    if (userProfile && userProfile.data[0].msteams) {
      // Only update the state on component mount
      setTeamExclude(userProfile?.data[0]?.msteams);
    }
    if (userProfile?.data[0]?.msteams?.isConnect !== undefined) {
      setIsConnected(userProfile.data[0].msteams.isConnect);
    }
    setSummary()
  }, [selectedDate, userProfile]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleDeleteEvents = (index) => {
    const updatedTeamsEvents = [...teamEvents];
    updatedTeamsEvents.splice(index, 1);
    setteamEvents(updatedTeamsEvents);
  };

  const convertToIST = (backendDate) => {
    const originalDate = new Date(backendDate);
    const istDate = new Date(originalDate.getTime() + 5.5 * 60 * 60 * 1000);
    return istDate.toLocaleTimeString('en-IN', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };


  const updateCheckPoint = async (e) => {
    if (!teamExclude) {
      return;
    }
    let updatedTeamsExclude;
    if (e.target.name === "limitLength") {
      const name = e.target.name;
      const value = e.target.checked ? Number(e.target.value) : 14000;
      updatedTeamsExclude = { ...teamExclude, [name]: value };
    } else {
      const name = e.target.name;
      const currentValue = teamExclude[name];
      const updatedValue = !currentValue;
      updatedTeamsExclude = { ...teamExclude, [name]: updatedValue };
    }
    setTeamExclude(updatedTeamsExclude);
    try {
      const url = process.env.REACT_APP_URL + "/auth/msteams";
      const response = await axios.post(
        url,
        { ...updatedTeamsExclude },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );
      if (response.status === 200) {
        await dispatch(fetchUserProfile());
        isConnected && fetchTeamsData();
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleTextareaChange = (event, index) => {
    const updatedTeamsEvents = [...teamEvents];
    updatedTeamsEvents[index].description = event.target.value;
    setteamEvents(updatedTeamsEvents);
  };

  // fetching teams data
  const fetchTeamsData = async () => {
    setIsLoading(true);
    let api_call_completed = false;
    const url =
      process.env.REACT_APP_URL + "/msteam/events?date=" + selectedDate;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user["connect.sid"]}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setteamEvents(data.events);
        setIsLoading(false);
      }
      if (response.status == 401) {
        handleMsalTokens();
        // connectTeams(false)       
      }
      if (response.status !== 200) {
        setIsLoading(false)
      }
    } catch (error) {
      console.error("Error fetching team data:", error);
    } finally {
      setIsLoading(false)
      api_call_completed = true;
      updateStatus({ isConnected, api_call_completed });
    }
  };

  const connectTeams = async (isConnected) => {
    try {
      const url = process.env.REACT_APP_URL + "/msteam/msteamconnect";
      const response = await axios.put(
        url,
        { isConnect: isConnected },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );
      if (response.status == 200 & !isConnected) {
        setIsConnected(false);
        setSnackbarOpen(true);
        dispatch(fetchUserProfile());
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      // Handle fetch error here
      console.error("Fetch error:", error);
    }
  };

  const handleAccessToken = async (response) => {
    const accessToken = response;
    try {
      if (accessToken) {
        const url =
          process.env.REACT_APP_URL +
          "/msteam/save_msteams_credentials";
        const response = await axios.post(
          url,
          {
            accessToken: accessToken,
          },
          {
            headers: {
              Authorization: `Bearer ${user["connect.sid"]}`,
            },
          }
        );
        if (response.status == 200) {
          fetchTeamsData();
        }
      }

    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleMsalTokens = async () => {
    await instance.initialize();
    await instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        handleAccessToken(response.accessToken)
      })
      .catch((e) => {
        connectTeams(false)
      })
  };

  useEffect(() => {
    if (isConnected) {
      updateStatus({ isConnected, "api_call_completed": false });
      fetchTeamsData();
    }
  }, [selectedDate, isConnected]);

  return (
    <>
      <div className="rounded-xl mt-10 min-h-300 shadow border-zinc-300  min-h-500 bg-lotion max-md:overflow-x-auto md:overflow-hidden scrollbar-width scrollable-container">
        <div className="mb-2 flex gap-2 justify-between items-center text-center">
          <div></div>
          <div className="flex items-center gap-x-2 ml-16 text-black text-opacity-80 text-xl font-bold font-['DM Sans']">
            <img src={Teams} alt="Teams" className="h-8 w-8" />
            Teams
          </div>
          <TeamsSignInButton user={user} isConnected={isConnected} setIsConnected={setIsConnected} setSnackbarOpen={setSnackbarOpen} setteamEvents={setteamEvents}
            selectedDate={selectedDate}
          />
        </div>
        <div
          className={`flex border h-12 buttons shadow-md border-zinc-300 text-black text-opacity-80 text-xl font-['DM Sans'] bg-lotion rounded-full items-center justify-between mx-auto font-semibold mt-2`}
        >
          <button
            className={`${TeamsActiveTab === "input" ? "border rounded-full shadow-xl" : ""
              } w-80 h-full`}
            onClick={() => setTeamsActiveTab("input")}
          >
            {" "}
            <span className="flex items-center justify-center gap-x-6">
              {" "}
              <span className="text-2xl font-extralight">
                <RiArrowUpDownFill />
              </span>
              Input{" "}
            </span>{" "}
          </button>
          <button
            className={`${TeamsActiveTab === "summary"
              ? "border rounded-full shadow-xl"
              : ""
              } w-80 h-full`}
            onClick={() => {
              setTeamsActiveTab("summary");
            }}
          >
            {" "}
            <span className="flex items-center justify-center  gap-x-6">
              {" "}
              <span className="text-2xl font-extralight">
                <ImProfile />
              </span>
              Summary{" "}
            </span>
          </button>
          <button
            className={`${TeamsActiveTab === "exclusions"
              ? "border rounded-full shadow-xl"
              : ""
              } w-80 h-full`}
            onClick={() => setTeamsActiveTab("exclusions")}
          >
            {" "}
            <span className="flex items-center justify-center gap-x-6">
              {" "}
              <VscExclude className="text-2xl" /> Exclusions{" "}
            </span>
          </button>
        </div>

        {TeamsActiveTab === "exclusions" && (
          <div className="flex flex-col p-4 gap-2">
            <div className="flex gap-2">
              <input
                type="checkbox"
                name="limitLength"
                checked={teamExclude?.limitLength === 500 ? true : false}
                onChange={updateCheckPoint}
                value={500}
              />
              <label htmlFor="limitChar">Limit Items to 500 Char</label>
            </div>
            <div className="flex gap-2">
              <input
                type="checkbox"
                name="limitLength"
                checked={teamExclude?.limitLength === 1000 ? true : false}
                onChange={updateCheckPoint}
                value={1000}
              />
              <label htmlFor="">Limit Items to 1000 Char</label>
            </div>
            <div className="flex gap-2">
              <input
                type="checkbox"
                checked={teamExclude?.excludeDailyActivity}
                name="excludeDailyActivity"
                onChange={updateCheckPoint}
              />
              <label htmlFor="excludeDailyActivity">Exclude Daily Activities Events</label>
            </div>
          </div>
        )}

        {isConnected && teamEvents?.length >= 1 && TeamsActiveTab === "input" ? (
          IsLoading ? (
            <div className="flex justify-center items-center min-h-300 gap-4">
              <img src={Teams} alt="Teams" className="h-8" />
              <p className="mt-3 font-medium">Loading Teams events...</p>
            </div>
          ) : (
            <div className="table-container">
              <table className="min-w-full divide-y-2  overflow-auto h-20 rounded-xl divide-gray-200 bg-lotion text-md mt-2 border-b border-gray-200">
                <thead className="ltr:text-left rtl:text-right">
                  <tr>
                    <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                      Event Title
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                      Event Description
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                      Start Time
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                      End Time
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900"></th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 ml-20">
                  {teamEvents?.map((event, index) => (
                    <tr key={index} className="">
                      <td className="flex-wrap pl-6 py-2 font-medium text-gray-900  max-w-full ">
                        {event?.Title}
                      </td>
                      <td className="pl-4 py-2 text-gray-700">
                        <div className="w-full min-h-[5vh] h-20 max-h-[30vh] overflow-hidden max-w-full ">
                          <textarea
                            name=""
                            rows="1"
                            cols="1"
                            id=""
                            value={event?.Description}
                            onChange={(event) => handleTextareaChange(event, index)}
                            className="w-[50vw] h-full bg-lotion p-2 min-h-[5vh] max-h-[30vh] resize-none "
                          >
                            {event?.Description}
                          </textarea>
                        </div>
                      </td>
                      <td className="flex-wrap pl-6 py-2 font-medium text-gray-900  max-w-full ">
                        {convertToIST(event?.startDateTime)}
                      </td>
                      <td className="flex-wrap pl-6 py-2 font-medium text-gray-900  max-w-full ">
                        {convertToIST(event?.endDateTime)}
                      </td>
                      <td>
                        <i
                          className="fa fa-trash"
                          onClick={() => handleDeleteEvents(index)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        ) : TeamsActiveTab === "input" ? (
          <div className="flex justify-center items-center min-h-300 gap-4">
            <img src={Teams} alt="Teams" className="h-8" />
            {IsLoading ? (
              <p className="mt-3 font-medium">Loading Teams events...</p>
            ) : (
              <p className="mt-3 font-medium">Teams events not found.</p>
            )}
          </div>
        ) : (
          ""
        )}
        {isConnected && TeamsActiveTab === "summary" && (
          <p className="w-[70%] m-auto p-3 whitespace-pre-wrap overflow-auto h-[24rem]">
            {teamEvents?.length > 0 ? <span>{summaryData}</span> : <span></span>}
          </p>
        )}
        {loadingSummary ? (
          <div className=" loader-outer z-99999">
            <div className=" loader z-999999"></div>
          </div>
        ) : (
          ""
        )}

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity={isConnected ? "success" : "error"}>
            {isConnected
              ? "Hurray! You are connected."
              : "Connect to summarize Teams Events!"}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default TeamsList;
