import axios from "axios";
import store from "../Store";
import { fetchTemplates } from "./fetchTemplates";


export const UPDATE_TEMPLATE_REQUEST = "UPDATE_TEMPLATE_REQUEST";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_FAILURE = "UPDATE_TEMPLATE_FAILURE";


export const updateTemplateRequest = () => {
  return {
    type: UPDATE_TEMPLATE_REQUEST,
  };
};

export const updateTemplatesSuccess = (templates) => {
  return {
    type: UPDATE_TEMPLATE_SUCCESS,
    payload: templates,
  };
};

export const updateTemplatesFailure = (error) => {
  return {
    type: UPDATE_TEMPLATE_FAILURE,
    payload: error,
  };
};

export const updateTemplates = (data) => {
  return async (dispatch) => {
    dispatch(updateTemplateRequest());
    const url = process.env.REACT_APP_URL + "/templates/template";
    try {
      const res = await axios.patch(url, data, {
        headers: {
          Authorization: `Bearer ${
            store.getState().authLogin.user["connect.sid"]
          }`,
        },
      });
      const templates = res.data.data;
      dispatch(updateTemplatesSuccess(templates));
      dispatch(fetchTemplates())
    } catch (error) {
      const errorMsg = error.message;
      dispatch(updateTemplatesFailure(errorMsg));
    }
  };
};
