import React from "react";
import feedbackImage from "../assets/feedback.png";

const Feedback = () => {
  return (
    <a
      href="https://docs.google.com/forms/d/e/1FAIpQLScICpE1IbuIY_x2RJRDaAxL1WKdi725461PZsn9xiJ2RGltvA/viewform"
      target="_blank"
      rel="noreferrer"
      className="no-underline text-black"
    >
      <div className="border-[3px] border-[#3d66fb] rounded-full p-2 items-center mt-2 text-center flex gap-2 w-[168px] md:w-[200px] mx-auto justify-center cursor-pointer">
        <img
          src={feedbackImage}
          alt="icon"
          className="md:h-10 md:w-10 h-6 w-6"
        />
        <span className="font-semibold text-sm h-10 flex items-center">
          Have Feedback?
        </span>
      </div>
    </a>
  );
};

export default Feedback;
