import {
    ADD_SUMMARY_REQUEST,
    ADD_SUMMARY_SUCCESS,
    ADD_SUMMARY_FAILURE,
  } from "../actions/addSummary";

  const initialState = {
    loading: false,
    summary: [],
    error: null,
  };

  const addSummaryReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_SUMMARY_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case ADD_SUMMARY_SUCCESS:
        return {
          ...state,
          loading: false,
          templates: action.payload,
          error: null,
        };
      case ADD_SUMMARY_FAILURE:
        return {
          ...state,
          loading: false,
          templates: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export default addSummaryReducer;