import { createAsyncThunk } from "@reduxjs/toolkit";
import { login } from "../reducers/authLoginReducer";

// Define your async thunk
export const fetchUser = createAsyncThunk(
  "auth/fetchUser",
  async (_, { dispatch }) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const isLoggedIn = user !== null && user !== undefined && user !== "";

    const response = await fetch(
      process.env.REACT_APP_URL + "/auth/login/success",
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      }
    );

    if (response.status === 200) {
      const resObject = await response.json();
      localStorage.setItem("user", JSON.stringify(resObject.user));
      localStorage.setItem("isLoggedIn", true);
      dispatch(login(resObject.user));
    } else {
      if (user && isLoggedIn) {
        dispatch(login(user));
      }
      throw new Error("Authentication has failed!");
    }
  }
);
