import React, { useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import Header from "../../components/Header";
import Feedback from "../../components/Feedback";
import weReportLogo from "../../assets/logo/weReporWork.png";
// import Checkout from "./Checkout";
// import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useMount } from "react-use";
import { fetchUserProfile } from "../../Store/actions/fetchUserProfile";
// import { fetchUser } from "../../Store/actions/fetchUser";
import { useNavigate } from "react-router-dom";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import { Link } from "react-router-dom";

const PromoPricingPage = () => {
  const [amount, setAmount] = useState(0);
  const [isOn, setIsOn] = useState(true);
  const { user } = useSelector((state) => state.authLogin);
  const { userProfile, loading } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleSwitch = () => {
    setIsOn(!isOn);
  };
  const plans = {
    forever: [
      "Core Features",
      "$1 Credit",
      "10 Summaries / week",
      "Integration with Google Workspace",
      "Community Support",
      "Ad Supported",
      "Limited Template",
      "Single User",
    ],
    individual: [
      "All Features",
      "$20 Credit",
      "Unlimited Summaries",
      "Integration with All Tools",
      "Regular Support",
      "No Advertisements",
      "Editable Templates",
      "Single User",
    ],
    VIP: [
      "All Features",
      "$Unlimited Credit",
      "Unlimited Summaries",
      "Integration with All Tools",
      "Premium Support",
      "No Advertisements",
      "All + Custom Templates",
      "Unlimited User",
      "Custom Branding",
    ],
  };

  // Credit Buy
  // const _id = user?.data[0]?._id;
  // const handleCheckout = async () => {
  //   const name = "CREDITS";
  //   const url = process.env.REACT_APP_URL + "/pay/payment/";
  //   await axios
  //     .post(
  //       url,
  //       { amount, name, userId: _id },
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${user["connect.sid"]}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (res?.data.url) {
  //         window.location.href = res?.data.url;
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  useMount(() => {
    // dispatch(fetchUser())
    dispatch(fetchUserProfile());
  });

  return (
    <>
      {/* Logo Branding  */}
      {loading && (
        <div className="loader-outer z-999">
          <div className="loader z-999"></div>
        </div>
      )}

      <div
        className="flex justify-center items-center gap-3.5 mt-3.5  cursor-pointer"
        onClick={() => {
          if (user?.data[0]?.role === "admin") {
            navigate("/admin");
          } else {
            navigate("/");
          }
        }}
      >

        <img src={weReportLogo} alt="logo" className="h-10 w-10" />
        <span className="md:text-3xl text-xl font-bold">We Report Work</span>
      </div>
      <Link to='/' className="text-[#3d66fb] font-fredoka font-semibold  relative top-9 no-underline mb-4">
        <ArrowBackSharpIcon /> Back
      </Link>
      <Header user={user} />
      <div className="flex bg-whte flex-col md:flex-row pb-20 gap-1 mt-2">


        <div className="flex flex-col w-[40%] m-auto bg-white md:rounded-r-lg   md:rounded-l-none rounded-l-lg md:mb-24 ">
          {/* <div className="border border-black md:rounded-r-lg  md:rounded-l-none rounded-l-lg ">most </div>4 */}
          <p className="bg-blue-600 text-white font-semibold py-4 text-center mt-2 rounded-t-xl md:text-3xl text-xl font-fredoka">
            VIP
          </p>
          <div className="flex flex-col gap-2 w-full text-center p-10 ">
            {/* <p className="uppercase font-semibold text-xl">VIP</p>  */}
            <p className="text-gray-500 mt-[-2rem]">
              {" "}
              <span className="text-xl   font-semibold md:text-3xl  font-fredoka text-black">UNLIMITED FEATURES</span>{" "}

            </p>
            {/* Features */}

            <div className="flex flex-col md:items-start items-center">
              {plans.VIP.map((feature, index) => (
                <div key={index} className="flex gap-2">
                  <BsCheckLg className="text-blue-500" />
                  <p className="text-gray-600">{feature}</p>
                </div>
              ))}
            </div>
            {userProfile?.data[0]?.currentPlan[0]?.name === "TEAMS" ? (
              {/* <button className="text-blue-600 border-2 border-blue-600 rounded-xl mx-auto md:w-7/12 w-6/12  shadow-md py-1 ">
                Current
              </button> */}
            ) : (
              <>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSffKXOHmG5J5wWyGLoPss1y4cHIe50gf2UTvqvhyKOytdlO5g/viewform?vc=0&c=0&w=1&flr=0&pli=1"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white bg-blue-600 border-2 border-blue-600 rounded-xl mx-auto md:w-7/12 w-6/12 shadow-md py-1  no-underline"
                >
                  Contact Us
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      <Feedback />
    </>
  );
};

export default PromoPricingPage;
