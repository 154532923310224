import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VscExclude } from "react-icons/vsc";
import { RiArrowUpDownFill } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import github from "../../../assets/coming/github.png";
import { useSummary } from "../../../context/SummaryDataContext";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import GitHubLogin from "./GitHubLogin";
import "./Github.css";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { updateUserInfo, fetchUserProfile } from "../../../Store/actions/fetchUserProfile";
import FormControlLabel from '@mui/material/FormControlLabel';
import SwitchBtn from "../../../components/SwitchBtn";


const GithubList = ({ selectedDate, user, summaryData, updateStatus }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [githubExclude, setgithubExclude] = useState(user?.data[0].github);
  const { gitHubEvents, setgitHubEvents } = useSummary();
  const { userProfile } = useSelector((state) => state.userProfile);
  const [githubActiveTabs, setgithubActiveTabs] = useState("input");
  const [isConnected, setIsConnected] = useState(
    userProfile?.data[0]?.github?.isConnect
  );
  const [summary, setSummary] = useState([]);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showConnectAlert, setShowConnectAlert] = useState(false);

  useEffect(() => {
    if (userProfile) {
      setgithubExclude(userProfile?.data[0].github);
    }
    if (userProfile?.data[0]?.github?.isConnect !== undefined) {
      setIsConnected(userProfile.data[0].github.isConnect);
    }
  }, [userProfile]);

  const GITHUB_CLIENT_ID = process.env.REACT_APP_URL == "https://secure.wereport.work" ? "e81fce49b8b3cf208e55" : "Ov23lirFzPFqu0vQVrmm"
  const REDIRECT_URI = `${process.env.REACT_APP_URL}/github/callback`;

  const handleDeleteEvents = (index) => {
    const updatedEvents = [...gitHubEvents];
    updatedEvents.splice(index, 1);
    setgitHubEvents(updatedEvents);
  };

  const convertToIST = (backendDate) => {
    const originalDate = new Date(backendDate);
    const istDate = new Date(originalDate);
    return istDate.toLocaleTimeString('en-IN', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const updateCheckPoint = async (e) => {
    if (!githubExclude) {
      return;
    }
    let updatedGithubExclude;
    if (e.target.name === "limitLength") {
      const name = e.target.name;
      const value = e.target.checked ? Number(e.target.value) : 14000;
      updatedGithubExclude = { ...githubExclude, [name]: value };
    } else {
      const name = e.target.name;
      const currentValue = githubExclude[name];
      const updatedValue = !currentValue;
      updatedGithubExclude = { ...githubExclude, [name]: updatedValue };
    }
    setgithubExclude(updatedGithubExclude);

    try {
      const url = process.env.REACT_APP_URL + "/auth/github";
      const response = await axios.post(
        url,
        { ...updatedGithubExclude },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );
      if (response.status === 200) {
        await dispatch(fetchUserProfile());
        isConnected && getGithubData();
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleTextareaChange = (event, index) => {
    const updatedEvents = [...gitHubEvents];
    updatedEvents[index].commit = event.target.value;
    setgitHubEvents(updatedEvents);
  };

  // for refeshing the page this function will bring back data
  const getGithubData = async () => {
    setLoadingEvents(true);
    let api_call_completed = false;
    const githubGetData =
      process.env.REACT_APP_URL + `/github/repositories?date=${selectedDate}`;
    try {
      const response = await axios.get(githubGetData, {
        headers: {
          Authorization: `Bearer ${user["connect.sid"]}`,
        },
      });
      if (response.status === 200) {
        const data = response.data;
        let mainData = data?.data;
        setgitHubEvents(mainData);
      }
    } catch (error) {
      console.error("Error fetching Github Events :", error);
    } finally {
      setLoadingEvents(false); // Set loadingEvents to false after the request is completed
      api_call_completed = true;
      updateStatus({ isConnected, api_call_completed });
    }
  };

  useEffect(() => {
    if (isConnected) {
      updateStatus({ isConnected, "api_call_completed": false });
      getGithubData();
    }
    setSummary();
  }, [selectedDate, isConnected]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
    setShowConnectAlert(false);
  };

  const connect = async (isConnected) => {
    try {
      const url = process.env.REACT_APP_URL + "/github/githubconnect";
      const response = await axios.put(
        url,
        { isConnect: isConnected },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );

      if (response.status == 200 && isConnected) {
        setIsConnected(true);
        setSnackbarOpen(true);
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionSuccessEvent',
          'feature': 'github',
          'status': 'Connected',
        });
      } else if (response.status === 200 && !isConnected) {
        setIsConnected(false);
        setSnackbarOpen(true);
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionRemoveEvent',
          'feature': 'github',
          'status': 'Removed',
        });
      } else {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionFailedEvent',
          'feature': 'github',
          'status': 'Failed'
        });
      }
    } catch (error) {
      // console.error("Fetch error:", error);
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'toolConnectionFailedEvent',
        'feature': 'github',
        'status': 'Failed'
      });
    }
  };

  const handleGithubConnect = () => {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'toolConnectionRequestedEvent',
      'feature': 'github',
      'status': 'Requested-Connect'
    });

    const github = `https://github.com/login/oauth/authorize?client_id=${GITHUB_CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&scope=user%20repo%20read:org%20read:repo_hook&state=${userProfile?.data[0]?._id}`;
    window.location.href = github;
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const gitHubSuccess = params.get('redi');
    if (gitHubSuccess === "github_success") {
      navigate("/")
      connect(true);
    }
  }, [])

  const handleConnectDisconnect = async () => {
    if (isConnected) {
      setgitHubEvents([]);
      connect(false);
    } else {
      setgitHubEvents([]);
      handleGithubConnect();
    }
  };

  return (
    <>
      <div className="rounded-xl mt-10 min-h-300 shadow border-zinc-300  min-h-500 bg-lotion max-md:overflow-x-auto md:overflow-hidden scrollbar-width scrollable-container">
        <div className="mb-2 flex gap-2 justify-between items-center text-center">
          <div></div>
          <div className="flex items-center gap-x-2 ml-16 text-black text-opacity-80 text-xl font-bold font-['DM Sans']">
            <img src={github} alt="github" className="h-8 w-8" />
            Github
          </div>
          <FormControlLabel
            sx={{
              display: 'block',
            }}
            control={
              <SwitchBtn
                checked={isConnected}
                onChange={handleConnectDisconnect}
                name="connect"
                sx={{ m: 1 }}
              />
            }
          />
        </div>
        <div
          className={`flex border buttons h-12 shadow-md border-zinc-300 text-black text-opacity-80 text-xl font-['DM Sans'] bg-lotion rounded-full items-center justify-between mx-auto font-semibold mt-2`}
        >
          <button
            className={`${githubActiveTabs === "input"
              ? "border rounded-full shadow-xl"
              : ""
              } w-80 h-full`}
            onClick={() => setgithubActiveTabs("input")}
          >
            {" "}
            <span className="flex items-center justify-center gap-x-6">
              {" "}
              <span className="text-2xl font-extralight">
                <RiArrowUpDownFill />
              </span>
              Input{" "}
            </span>{" "}
          </button>
          <button
            className={`${githubActiveTabs === "summary"
              ? "border rounded-full shadow-xl"
              : ""
              } w-80 h-full`}
            onClick={() => {
              setgithubActiveTabs("summary");
            }}
          >
            {" "}
            <span className="flex items-center justify-center  gap-x-6">
              {" "}
              <span className="text-2xl font-extralight">
                <ImProfile />
              </span>
              Summary
            </span>
          </button>
          <button
            className={`${githubActiveTabs === "exclusions"
              ? "border rounded-full shadow-xl"
              : ""
              } w-80 h-full`}
            onClick={() => setgithubActiveTabs("exclusions")}
          >
            {" "}
            <span className="flex items-center justify-center gap-x-6">
              {" "}
              <VscExclude className="text-2xl" /> Exclusions{" "}
            </span>
          </button>
        </div>

        {githubActiveTabs === "exclusions" && (
          <div className="flex flex-col p-4 gap-2">
            <div className="flex gap-2">
              <input
                type="checkbox"
                name="limitLength"
                id="limitChar"
                checked={githubExclude?.limitLength === 500 ? true : false}
                onChange={updateCheckPoint}
                value={500}
              />
              <label htmlFor="limitChar">Limit Items to 500 Char</label>
            </div>
            <div className="flex gap-2">
              <input
                type="checkbox"
                name="limitLength"
                checked={githubExclude?.limitLength === 1000 ? true : false}
                onChange={updateCheckPoint}
                value={1000}
              />
              <label htmlFor="">Limit Items to 1000 Char</label>
            </div>
          </div>
        )}

        {isConnected &&
          githubActiveTabs === "input" &&
          gitHubEvents?.length >= 1 ? (
          loadingEvents ? (
            <div className="flex justify-center items-center min-h-300 gap-4">
              <img src={github} alt="github" className="h-8" />
              <p className="mt-3 font-medium">Loading Github events...</p>
            </div>
          ) : (
            <div className="table-container">
              <table className="min-w-full divide-y-2 overflow-auto h-20 rounded-xl divide-gray-200 bg-lotion text-md border-b border-gray-200 mt-2">
                <thead className="ltr:text-left rtl:text-right">
                  <tr>
                    <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                      Event Type
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                      Repo Name
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                      Action
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                      Branch / Object
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                      Message / Description
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                      Event Time
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900"></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 ml-20">
                  {gitHubEvents?.map((event, index) => (
                    <tr key={index} className="">
                      <td className="flex-wrapp pl-6 py-2 font-medium text-gray-900  ">
                        {event?.Events}
                      </td>
                      <td className="pl-4 py-2  w-1/2 text-gray-700">
                        <div className="w-[14rem] min-h-[5vh] h-20 max-h-[30vh] overflow-hidden max-w-full">
                          <textarea
                            name=""
                            rows="1"
                            cols="1"
                            id=""
                            value={event?.Repo}
                            className="w-[50vw] h-full bg-lotion p-2 min-h-[5vh] max-h-[30vh] resize-none "
                          >
                            {event?.Repo}
                          </textarea>
                        </div>
                      </td>
                      <td className="pl-4 py-2 text-gray-700">
                        <div className="w-[10rem] min-h-[5vh] h-20 max-h-[30vh] overflow-hidden max-w-full ">
                          <textarea
                            name=""
                            rows="1"
                            cols="1"
                            id=""
                            value={event?.field1}
                            onChange={(event) =>
                              handleTextareaChange(event, index)
                            }
                            className="w-[50vw] h-full bg-lotion p-2 min-h-[5vh] max-h-[30vh] resize-none "
                          >
                            {event?.field1}
                          </textarea>
                        </div>
                      </td>
                      <td className="pl-4 py-2 text-gray-700">
                        <div className="w-[10rem] min-h-[5vh] h-20 max-h-[30vh] overflow-hidden max-w-full ">
                          <textarea
                            name=""
                            rows="1"
                            cols="1"
                            id=""
                            value={event?.field2}
                            className="w-[50vw] h-full bg-lotion p-2 min-h-[5vh] max-h-[30vh] resize-none "
                          >
                            {event?.field2}
                          </textarea>
                        </div>
                      </td>
                      <td className="pl-4 py-2 text-gray-700">
                        <div className="w-[10rem] min-h-[5vh] h-20 max-h-[30vh] overflow-hidden max-w-full ">
                          <textarea
                            name=""
                            rows="1"
                            cols="1"
                            id=""
                            value={event?.field3}
                            className="w-[50vw] h-full bg-lotion p-2 min-h-[5vh] max-h-[30vh] resize-none "
                          >
                            {event?.field3}
                          </textarea>
                        </div>
                      </td>
                      <td className="flex-wrapp pl-6 py-2 font-medium text-gray-900 ">
                        {convertToIST(event?.time)}
                      </td>
                      <td>
                        <i
                          className="fa fa-trash"
                          onClick={() => handleDeleteEvents(index)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        ) : githubActiveTabs === "input" ? (
          <div className="flex justify-center items-center min-h-300 gap-4">
            <img src={github} alt="github" className="h-8" />

            {loadingEvents ? (
              <p className="mt-3 font-medium">Loading Github events...</p>
            ) : (
              <p className="mt-3 font-medium">Github events not found.</p>
            )}
          </div>
        ) : (
          ""
        )}
        {isConnected && githubActiveTabs === "summary" && (
          <p className="w-[70%] m-auto p-3 whitespace-pre-wrap overflow-auto h-[24rem]">
            {gitHubEvents?.length > 0 ? (
              <span>{summaryData}</span>
            ) : (
              <span></span>
            )}
          </p>
        )}
        {loadingSummary ? (
          <div className=" loader-outer z-99999">
            <div className=" loader z-999999"></div>
          </div>
        ) : (
          ""
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity={isConnected ? "success" : "error"}>
            {isConnected
              ? "Hurray! You are connected."
              : "Connect to summarize Github Events!"}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default GithubList;
