import { createSlice } from "@reduxjs/toolkit";
import { fetchUser } from "../actions/fetchUser";  

// Define your slice
const authSlice = createSlice({
  name: "profile",
  initialState: {
    user: null,
    isLoggedIn: false,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload; 
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.user = null;
      state.isLoggedIn = false;
    },
    // other reducers...
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      // handle the state update when fetchUser is fulfilled
      const { payload } = action;
      state.user = payload;
      state.isLoggedIn = true;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      // handle the state update when fetchUser is rejected
      state.user = null;
      state.isLoggedIn = false;
    });
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
