import React from "react";
import privacyPolicyPdf from "../../assets/PrivacyPolicy.pdf";

const Privacy = () => {
    return (
        <>
            <iframe id="privacyPolicy" title="Privacy Policy" name="Privacy Policy" className="h-screen w-full" src={privacyPolicyPdf}>Privacy Policy</iframe>
        </>
    );
};

export default Privacy;
