import axios from "axios";
import store from "../Store";
export const FETCH_TEMPLATES_REQUEST = "FETCH_TEMPLATES_REQUEST";
export const FETCH_TEMPLATES_SUCCESS = "FETCH_TEMPLATES_SUCCESS";
export const FETCH_TEMPLATES_FAILURE = "FETCH_TEMPLATES_FAILURE";

export const fetchTemplatesRequest = () => {
  return {
    type: FETCH_TEMPLATES_REQUEST,
  };
};

export const fetchTemplatesSuccess = (templates) => {
  return {
    type: FETCH_TEMPLATES_SUCCESS,
    payload: templates,
  };
};

export const fetchTemplatesFailure = (error) => {
  return {
    type: FETCH_TEMPLATES_FAILURE,
    payload: error,
  };
};

export const fetchTemplates = () => {
  return async (dispatch) => {
    dispatch(fetchTemplatesRequest());
    const url = process.env.REACT_APP_URL + "/templates/template";
    try {
      const res = await axios.get(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${store.getState().authLogin.user["connect.sid"]}`,
        },
      });
      const templates = res.data.data;
      dispatch(fetchTemplatesSuccess(templates));
    } catch (error) {
      const errorMsg = error.message;
      dispatch(fetchTemplatesFailure(errorMsg));
    }
  };
};
