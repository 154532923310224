import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

const Callback = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // console.log("github login useEffect is runninggg");
    const handleCallback = async () => {
      // console.log("this is inside handlecallback");
      const code = new URLSearchParams(location.search).get("code");

      if (code) {
        try {
          // Exchange code for access token
          const response = await axios.post(
            "https://github.com/login/oauth/access_token",
            {
              client_id: "Iv1.e4480c228e51edd3",
              client_secret: "bc0391314eb554375e031ae6ae7b824600b39721",
              code,
            },
            {
              headers: {
                Accept: "application/json",
              },
            }
          );

          // const accessToken = response.data.access_token;

          // Track the successful connection event
          // window.dataLayer.push({
          //   event: "toolConnectionSuccessEvent",
          //   feature: "Github",
          //   status: "Connected",
          // });

          // Store the access token or use it as needed
          // For simplicity, you can store it in localStorage or a state management solution
          // console.log("this is accessToken for github login", accessToken);
          // localStorage.setItem("ACESSTOKEN", accessToken);
          // Redirect to home or user dashboard
          navigate("/");
        } catch (error) {
          console.error("GitHub callback error:", error);
          // Handle error, redirect to an error page, etc.
          // Track the connection failure event
          // window.dataLayer.push({
          //   event: "toolConnectionFailedEvent",
          //   feature: "Github",
          //   status: "Failed",
          // });
        }
      } else {
        console.error("GitHub callback: Code not found in URL");
      }
    };

    handleCallback();
  }, [location.search, navigate]);

  return <div>Logging in...</div>;
};

export default Callback;
