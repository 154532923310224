import axios from "axios";
import store from "../Store";
export const ADD_SUMMARY_REQUEST = "ADD_SUMMARY_REQUEST";
export const ADD_SUMMARY_SUCCESS = "ADD_SUMMARY_SUCCESS";
export const ADD_SUMMARY_FAILURE = "ADD_SUMMARY_FAILURE";

export const addTemplateRequest = () => {
  return {
    type: ADD_SUMMARY_REQUEST,
  };
};

export const addSummarySuccess = (summary) => {
  return {
    type: ADD_SUMMARY_SUCCESS,
    payload: summary,
  };
};

export const addSummaryFailure = (error) => {
  return {
    type: ADD_SUMMARY_FAILURE,
    payload: error,
  };
};

export const addSummary = (data) => {
  return async (dispatch) => {
    dispatch(addTemplateRequest());
    const url = process.env.REACT_APP_URL + "/openai/addSummary".concat('/?',"date=",data.date,);
    try {
      const res = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${
            store.getState().authLogin.user["connect.sid"]
          }`,
        },
      });
      // console.log(res.data, "apoorv")
      const summary = res.data.data;
      // console.log('this is in storeseee',summary)
      dispatch(addSummarySuccess(summary));
    } catch (error) {
      const errorMsg = error.message;
      dispatch(addSummaryFailure(errorMsg));
    }
  };
};
