import {
    FETCH_TEMPLATES_REQUEST,
    FETCH_TEMPLATES_SUCCESS,
    FETCH_TEMPLATES_FAILURE,
  } from "../actions/fetchTemplates";
  
  const initialState = {
    loading: false,
    userTemplate: [],
    error: null,
  };
  
  const templatesReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TEMPLATES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_TEMPLATES_SUCCESS:
        return {
          ...state,
          loading: false,
          userTemplate: action.payload,
        };
      case FETCH_TEMPLATES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default templatesReducer;