import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const LoggedInProtect = ({ isLoggedIn }) => {
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default LoggedInProtect;
