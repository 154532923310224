import axios from "axios";
import store from "../Store";
export const FETCH_SAVED_SUMMARY_REQUEST = "FETCH_SAVED_SUMMARY_REQUEST";
export const FETCH_SAVED_SUMMARY_SUCCESS = "FETCH_SAVED_SUMMARY_SUCCESS";
export const FETCH_SAVED_SUMMARY_FAILURE = "FETCH_SAVED_SUMMARY_FAILURE";
export const SAVE_SUMMARY = "SAVE_SUMMARY";

export const fetchSavedSummaryRequest = () => {
  return {
    type: FETCH_SAVED_SUMMARY_REQUEST,
  };
};

export const fetchSavedSummarySuccess = (summary) => {
  return {
    type: FETCH_SAVED_SUMMARY_SUCCESS,
    payload: summary,
  };
};

export const fetchSavedSummaryFailure = (error) => {
  return {
    type: FETCH_SAVED_SUMMARY_FAILURE,
    payload: error,
  };
};

export const fetchSavedSummary = (payload) => {
  return async (dispatch) => {
    dispatch(fetchSavedSummaryRequest());
    const url = process.env.REACT_APP_URL + `/openai/getSummary/`;
    try {
      const res = await axios.get(url, {
        params: { date: payload.selectedDate, userId: payload.userId },
        headers: {
          Authorization: `Bearer ${
            store.getState().authLogin.user["connect.sid"]
          }`,
        },
      });
      
      const templates = res.data.data;
      
      dispatch(fetchSavedSummarySuccess(templates));
    } catch (error) {
      const errorMsg = error.message;
      dispatch(fetchSavedSummaryFailure(errorMsg));
    }
  };
};

export const fetchSummaryByID = (id) => {
  return async (dispatch) => {
    const url = process.env.REACT_APP_URL + "/openai/getSummaryDataById";
    try{
      const res = await axios.get(url, {
        params: { id: id },
        headers: {
          Authorization: `Bearer ${
            store.getState().authLogin.user["connect.sid"]
          }`,
        },
      });
      const summary = res.data.data;
      // dispatch(saveSummary(summary));
    }
    catch(error){
      const errorMsg = error.message;
      dispatch(fetchSavedSummaryFailure(errorMsg));

    } 
  };
};
