import React from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  button: {
    color: "white",
    backgroundColor: "#2463EB !important", // Blue-600
    border: "2px solid #0069d9 !important", // Blue-600
    borderRadius: "12px !important", // Rounded-xl
    width: "100%",
    maxWidth: "calc(700px / 12 * 7)", // mx-auto md:w-7/12
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)", // shadow-md
    padding: "8px", // py-1
  },
  br12: {
    borderRadius: "12px !important", // Rounded-xl
    backgroundColor: "#2463EB !important", // Blue-600
  },
  nap: {
    cursor: "not-allowed !important",
  },
  summary: {
    background: "#DBDFE2 !important",
    color: "black !important",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    fontWeight: 600,
  },
}));

const MuiButton = ({
  loading,
  onClick,
  className,
  disabled,
  style,
  buttonType,
  children,
}) => {
  const classes = useStyles();

  return (
    <Button
      className={`${className ? className : classes.button} ${classes.br12} ${
        disabled ? classes.nap : ""
      } ${buttonType ? classes.summary : ""}`}
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      style={style || {}}
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : children}
    </Button>
  );
};

export default MuiButton;
