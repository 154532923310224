import React, { useEffect, useState } from "react";
import { VscExclude } from "react-icons/vsc";
import { RiArrowUpDownFill } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import JiraAuth from "./JiraAuth";
import Jira from "../../../assets/coming/Jira.png";
import axios from "axios";
import { useSummary } from "../../../context/SummaryDataContext";
import { useSelector, useDispatch } from "react-redux";
import "./Jira.css"
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { fetchUserProfile } from "../../../Store/actions/fetchUserProfile";


const JiraList = ({ selectedDate, user, summaryData, updateStatus }) => {

  const dispatch = useDispatch();
  const [loadingEvents, setLoadingEvents] = useState(false);
  const { JiraEvents, setJiraEvents } = useSummary([]);
  const { userProfile } = useSelector((state) => state.userProfile);
  const [JiraExclude, setJiraExclude] = useState(userProfile?.data[0].jira);
  const [JiraActiveTabs, setJiraActiveTabs] = useState("input");
  const [summary, setSummary] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(user?.data[0]?.jira?.isConnect);

  var isMobileScreen = 0;
  if (window.innerWidth < 450) {
    isMobileScreen = 1;
  }


  useEffect(() => {

    if (userProfile) {
      setJiraExclude(userProfile?.data[0].jira);
    }
    if (userProfile?.data[0]?.slack?.isConnect !== undefined) {
      setIsConnected(userProfile.data[0].jira.isConnect);
    }
    setSummary();
  }, [userProfile, user]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };


  const handleJiraDataFetch = async () => {
    setLoadingEvents(true)
    let api_call_completed = false;
    const url =
      process.env.REACT_APP_URL + `/jira/projects?date=${selectedDate}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${user?.["connect.sid"]}`,
        },
      });
      if (response.status === 200) {
        setLoadingEvents(false)
        setJiraEvents(response?.data?.data); // Assuming the response contains the Jira events
      } else {
        setLoadingEvents(false)
      }
    } catch (error) {
      setLoadingEvents(false)
      console.error("Error fetching Jira messages:", error);
    } finally {
      setLoadingEvents(false)
      api_call_completed = true;
      updateStatus({ isConnected, api_call_completed });
    }
  };
  // console.log(JiraEvents, "events");
  useEffect(() => {
    if (isConnected) {
      updateStatus({ isConnected, "api_call_completed": false });
      handleJiraDataFetch();
    }
  }, [selectedDate, isConnected]);

  const handleDeleteEvents = (index) => {
    const updatedEvents = [...JiraEvents];
    updatedEvents.splice(index, 1);
    setJiraEvents(updatedEvents);
  };

  const stringUpperCase = (string) => {
    if (string)
      return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const convertToIST = (backendDate) => {
    const originalDate = new Date(backendDate);
    const istDate = new Date(originalDate);
    return istDate.toLocaleTimeString('en-IN', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };


  const updateCheckPoint = async (e) => {
    console.log(JiraExclude, "JiraExclude")
    if (!JiraExclude) {
      return;
    }
    let updatedJiraExclude;
    if (e.target.name === "limitLength") {
      const name = e.target.name;
      const value = e.target.checked ? Number(e.target.value) : 14000;
      updatedJiraExclude = { ...JiraExclude, [name]: value };
    } else {
      const name = e.target.name;
      const currentValue = JiraExclude[name];
      const updatedValue = !currentValue;
      updatedJiraExclude = { ...JiraExclude, [name]: updatedValue };
    }
    setJiraExclude(updatedJiraExclude);
    try {
      const url = process.env.REACT_APP_URL + "/auth/jira";
      const response = await axios.post(
        url,
        { ...updatedJiraExclude },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );
      if (response.status === 200) {
        await dispatch(fetchUserProfile());
        isConnected && handleJiraDataFetch();
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  return (
    <>
      <div className="rounded-xl mt-10 min-h-300 shadow pb-20 border-zinc-300 min-h-500 bg-lotion max-md:overflow-x-auto md:overflow-hidden scrollbar-width scrollable-container">
        <div className="mb-2 flex gap-2 justify-between items-center text-center">
          <div></div>
          <div className="flex items-center gap-x-2 ml-16 text-black text-opacity-80 text-xl font-bold font-['DM Sans']">
            <img src={Jira} alt="Jira" className="h-8 w-8" />
            Jira
          </div>
          <JiraAuth user={user} isConnected={isConnected} setIsConnected={setIsConnected} setSnackbarOpen={setSnackbarOpen} setJiraEvents={setJiraEvents} />
        </div>
        <div
          className={`flex border h-12 buttons shadow-md border-zinc-300 text-black text-opacity-80 text-xl font-['DM Sans'] bg-lotion rounded-full items-center justify-between mx-auto font-semibold mt-2`}
        >
          <button
            className={`${JiraActiveTabs === "input" ? "border rounded-full shadow-xl" : ""
              } w-80 h-full`}
            onClick={() => setJiraActiveTabs("input")}
          >
            {" "}
            <span className="flex items-center justify-center gap-x-6">
              {" "}
              <span className="text-2xl font-extralight">
                <RiArrowUpDownFill />
              </span>
              Input{" "}
            </span>{" "}
          </button>
          <button
            className={`${JiraActiveTabs === "summary"
              ? "border rounded-full shadow-xl"
              : ""
              } w-80 h-full`}
            onClick={() => setJiraActiveTabs("summary")}
          >
            {" "}
            <span className="flex items-center justify-center  gap-x-6">
              {" "}
              <span className="text-2xl font-extralight">
                <ImProfile />
              </span>
              Summary{" "}
            </span>
          </button>
          <button
            className={`${JiraActiveTabs === "exclusions"
              ? "border rounded-full shadow-xl"
              : ""
              } w-80 h-full`}
            onClick={() => setJiraActiveTabs("exclusions")}
          >
            {" "}
            <span className="flex items-center justify-center gap-x-6">
              {" "}
              <VscExclude className="text-2xl" /> Exclusions{" "}
            </span>
          </button>
        </div>


        {JiraActiveTabs === "exclusions" && (
          <div className="flex flex-col p-4 gap-2">
            <div className="flex gap-2">
              <input
                type="checkbox"
                name="limitLength"
                id="limitChar"
                checked={JiraExclude?.limitLength === 500 ? true : false}
                onChange={updateCheckPoint}
                value={500}
              />
              <label htmlFor="limitChar">Limit Items to 500 Char</label>
            </div>
            <div className="flex gap-2">
              <input
                type="checkbox"
                name="limitLength"
                checked={JiraExclude?.limitLength === 1000 ? true : false}
                onChange={updateCheckPoint}
                value={1000}
              />
              <label htmlFor="">Limit Items to 1000 Char</label>
            </div>
          </div>
        )}


        {JiraActiveTabs === "input" && (
          <>
            {JiraEvents?.length > 0 ? (
              loadingEvents ? (
                <div className="flex justify-center items-center min-h-300 gap-4">
                  <img src={Jira} alt="Jira" className="h-8" />
                  <p className="mt-3 font-medium">Loading Jira Tickets...</p>
                </div>
              ) : (
                <div className="table-container">
                  <div className="mt-2 border-b overflow-auto" style={{ height: '70vh', width: isMobileScreen ? '350px' : '100%' }}>
                    <div className="w-full border-b border-gray-200">
                      <div className={`flex ${isMobileScreen ? '' : ''} justify-around`}>
                        <div className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700 w-[20%]">
                          Project(Task)
                        </div>
                        <div className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700 w-[20%]">
                          Title
                        </div>
                        <div className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700 w-[10%]">
                          Field
                        </div>
                        <div className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700 w-[20%]">
                          Value
                        </div>
                        <div className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700 w-[20%]">
                          Modified Time
                        </div>
                        <div className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 w-[10%]"></div>
                      </div>
                    </div>
                    <div>
                      {JiraEvents?.map((event, index) => (
                        <div
                          className="flex px-2 mt-2 justify-around border-b border-gray-200"
                          key={index}
                        >
                          <div className="w-[20%] overflow-scroll px-4 py-2">
                            {event?.project}
                            <p>({event?.taskId})</p>
                          </div>
                          <div className="w-[20%] overflow-scroll px-4 py-2">
                            {event?.taskName}
                          </div>
                          <div className="w-[10%] overflow-scroll bg-lotion px-4 py-2">
                            {stringUpperCase(event?.field)}
                          </div>
                          <div className="w-[20%] overflow-scroll px-4 py-2">
                            {event?.field == "reporter" || event?.field == "status" || event?.field == "assignee" ? <p> {event?.from ? event?.from : "null"} {'-->'} {event?.to}</p> : event?.to}
                          </div>
                          <div className="w-[20%] overflow-scroll bg-lotion px-4 py-2 ">
                            {convertToIST(event?.date)}
                          </div>
                          <div className="w-[10%] px-4 py-2">
                            <i
                              className="fa fa-trash"
                              onClick={() => handleDeleteEvents(index)}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="flex justify-center items-center min-h-300 gap-4">
                <img src={Jira} alt="Jira" className="h-8" />
                {loadingEvents ? (
                  <p className="mt-3 font-medium">Loading Jira Tickets...</p>
                ) : (
                  <p className="mt-3 font-medium">Jira Tickets not found.</p>
                )}
              </div>
            )}
          </>
        )}
        {JiraActiveTabs === "summary" && (
          <p className="w-[70%] m-auto p-3 whitespace-pre-wrap overflow-auto h-[24rem]">
            {JiraEvents?.length > 0 ? (
              <span>{summaryData}</span>
            ) : (
              <span></span>
            )}
          </p>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity={isConnected ? "success" : "error"}>
            {isConnected
              ? "Hurray! You are connected."
              : "Connect to summarize Jira Tickets!"}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};


export default JiraList;



